import gql from 'graphql-tag';

import { CountryFragments } from './CountryType';
import { ImageFragments } from './ImageType';
import { RegionFragments } from './RegionType';
import { SpeciesFragments } from './SpeciesTypes';

export const FishingWaterFragments = {
  id: gql`
    fragment FishingWaterId on FishingWater {
      _id: externalId
    }
  `,
  locationFields: gql`
    fragment FishingWaterLocationFields on FishingWater {
      latitude
      longitude
      country {
        ...CountryId
        countryName
      }
      region {
        ...RegionId
        name
        licenseInfoUrl
      }
    }
    ${CountryFragments.id}
    ${RegionFragments.id}
  `,
  locationFieldsExtended: gql`
    fragment FishingWaterLocationFieldsExtended on FishingWater {
      latitude
      longitude
      country {
        ...CountryId
        countryName
        topFishingWaters(first: 20) {
          edges {
            node {
              _id: externalId
              displayName
            }
          }
        }
        topSpecies(first: 20) {
          edges {
            node {
              _id: externalId
              displayName
            }
          }
        }
      }
      region {
        ...RegionId
        name
        licenseInfoUrl
        topFishingWaters(first: 20) {
          edges {
            node {
              _id: externalId
              displayName
            }
          }
        }
      }
    }
    ${CountryFragments.id}
    ${RegionFragments.id}
  `,
  watersListItem: gql`
    fragment FishingWaterWatersListItem on FishingWater {
      _id: externalId
      latitude
      longitude
      displayName
      catchesCount
      followedByCurrentUser
      catches(last: 1, filters: { withPhotos: true }) {
        edges {
          node {
            _id: externalId
            post {
              _id: externalId
              images(first: 1, croppingStrategy: SMART, height: 100, width: 100) {
                edges {
                  node {
                    id
                    ...ImageFields
                  }
                }
              }
            }
          }
        }
      }
      reviews {
        totalCount
        totalAverage(attribute: RATING)
      }
      topSpecies(first: 3) {
        edges {
          node {
            ...SpeciesId
            displayName
          }
        }
      }
    }
    ${ImageFragments.imageFields}
    ${SpeciesFragments.id}
  `,
  carouselItem: gql`
    fragment FishingWaterCarouselItem on FishingWater {
      _id: externalId
      latitude
      longitude
      displayName
      reviews {
        totalCount
        totalAverage(attribute: RATING)
      }
      country {
        ...CountryId
        countryName
      }
      region {
        ...RegionId
        name
      }
      catchesCount
    }
    ${CountryFragments.id}
    ${RegionFragments.id}
  `,
};
