/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: associateProductUnitsToMe
// ====================================================

export interface associateProductUnitsToMe_associateProductUnitsToMe_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface associateProductUnitsToMe_associateProductUnitsToMe {
  __typename: "AssociateProductUnitsToMeMutationPayload";
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: associateProductUnitsToMe_associateProductUnitsToMe_userErrors[];
}

export interface associateProductUnitsToMe {
  associateProductUnitsToMe: associateProductUnitsToMe_associateProductUnitsToMe | null;
}

export interface associateProductUnitsToMeVariables {
  input: AssociateProductUnitsToMeMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePostMutation
// ====================================================

export interface CreatePostMutation_createPost_post {
  __typename: "Post";
  externalId: string;
}

export interface CreatePostMutation_createPost_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface CreatePostMutation_createPost {
  __typename: "CreatePostMutationPayload";
  post: CreatePostMutation_createPost_post | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: CreatePostMutation_createPost_userErrors[];
}

export interface CreatePostMutation {
  createPost: CreatePostMutation_createPost | null;
}

export interface CreatePostMutationVariables {
  input: CreatePostMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createPromotedCTAMutation
// ====================================================

export interface createPromotedCTAMutation_createPromotedCallToAction_promotedCallToAction_image {
  __typename: "Image";
  url: string;
}

export interface createPromotedCTAMutation_createPromotedCallToAction_promotedCallToAction {
  __typename: "PagesPromotedCallToAction";
  /**
   * ID
   */
  id: number;
  headline: string;
  subHeadline: string | null;
  targetUrl: string;
  buttonText: string;
  image: createPromotedCTAMutation_createPromotedCallToAction_promotedCallToAction_image;
}

export interface createPromotedCTAMutation_createPromotedCallToAction_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface createPromotedCTAMutation_createPromotedCallToAction {
  __typename: "CreatePromotedCallToActionMutationPayload";
  promotedCallToAction: createPromotedCTAMutation_createPromotedCallToAction_promotedCallToAction | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: createPromotedCTAMutation_createPromotedCallToAction_userErrors[];
}

export interface createPromotedCTAMutation {
  createPromotedCallToAction: createPromotedCTAMutation_createPromotedCallToAction | null;
}

export interface createPromotedCTAMutationVariables {
  input: CreatePromotedCallToActionMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserSuggestedChangeMutation
// ====================================================

export interface CreateUserSuggestedChangeMutation_createUserSuggestedChange_userSuggestedChanges {
  __typename: "UserSuggestedChange";
  externalId: string;
}

export interface CreateUserSuggestedChangeMutation_createUserSuggestedChange_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface CreateUserSuggestedChangeMutation_createUserSuggestedChange {
  __typename: "CreateUserSuggestedChangeMutationPayload";
  userSuggestedChanges: CreateUserSuggestedChangeMutation_createUserSuggestedChange_userSuggestedChanges[] | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: CreateUserSuggestedChangeMutation_createUserSuggestedChange_userErrors[];
}

export interface CreateUserSuggestedChangeMutation {
  createUserSuggestedChange: CreateUserSuggestedChangeMutation_createUserSuggestedChange | null;
}

export interface CreateUserSuggestedChangeMutationVariables {
  input: CreateUserSuggestedChangeMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deletePromotedCallToAction
// ====================================================

export interface deletePromotedCallToAction_deletePromotedCallToAction_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface deletePromotedCallToAction_deletePromotedCallToAction {
  __typename: "DeletePromotedCallToActionMutationPayload";
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: deletePromotedCallToAction_deletePromotedCallToAction_userErrors[];
}

export interface deletePromotedCallToAction {
  deletePromotedCallToAction: deletePromotedCallToAction_deletePromotedCallToAction | null;
}

export interface deletePromotedCallToActionVariables {
  input: DeletePromotedCallToActionMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateEquipmentReview
// ====================================================

export interface CreateEquipmentReview_createEquipmentReview_review_author_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface CreateEquipmentReview_createEquipmentReview_review_author {
  __typename: "User";
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  avatar: CreateEquipmentReview_createEquipmentReview_review_author_avatar | null;
}

export interface CreateEquipmentReview_createEquipmentReview_review_post_text {
  __typename: "Text";
  text: string | null;
}

export interface CreateEquipmentReview_createEquipmentReview_review_post {
  __typename: "Post";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Text content in this post
   */
  text: CreateEquipmentReview_createEquipmentReview_review_post_text;
  /**
   * The title of the post (`title` might be needed later to return the Title object)
   */
  titleString: string | null;
}

export interface CreateEquipmentReview_createEquipmentReview_review {
  __typename: "Review";
  externalId: string;
  rating: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  author: CreateEquipmentReview_createEquipmentReview_review_author;
  post: CreateEquipmentReview_createEquipmentReview_review_post;
}

export interface CreateEquipmentReview_createEquipmentReview_userErrors {
  __typename: "UserError";
  /**
   * A description of the error
   */
  message: string;
  /**
   * Which input value this error came from
   */
  path: string[] | null;
}

export interface CreateEquipmentReview_createEquipmentReview {
  __typename: "CreateReviewMutationPayload";
  review: CreateEquipmentReview_createEquipmentReview_review | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: CreateEquipmentReview_createEquipmentReview_userErrors[];
}

export interface CreateEquipmentReview {
  createEquipmentReview: CreateEquipmentReview_createEquipmentReview | null;
}

export interface CreateEquipmentReviewVariables {
  input: CreateReviewMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FollowRecords
// ====================================================

export interface FollowRecords_followRecords_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface FollowRecords_followRecords {
  __typename: "FollowRecordsMutationPayload";
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: FollowRecords_followRecords_userErrors[];
}

export interface FollowRecords {
  followRecords: FollowRecords_followRecords | null;
}

export interface FollowRecordsVariables {
  input: FollowRecordsMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LikePost
// ====================================================

export interface LikePost_likePost_post {
  __typename: "Post";
  _id: string;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
}

export interface LikePost_likePost {
  __typename: "LikePostMutationPayload";
  post: LikePost_likePost_post | null;
}

export interface LikePost {
  likePost: LikePost_likePost | null;
}

export interface LikePostVariables {
  input: LikePostMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: attributePromotion
// ====================================================

export interface attributePromotion_attributeTrialPromotion_userErrors {
  __typename: "UserError";
  /**
   * A string which can be used by a client to handle the user error
   * 
   * The only code currently is `needsReverification`.  This could be returned from any mutation.
   * If it is returned by any of the user errors, the client must immediately re-verify the user.
   */
  code: string | null;
  /**
   * A description of the error
   */
  message: string;
  /**
   * Which input value this error came from
   */
  path: string[] | null;
}

export interface attributePromotion_attributeTrialPromotion {
  __typename: "AttributeTrialPromotionMutationPayload";
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  /**
   * Meta params of the link
   */
  metaParams: any | null;
  /**
   * ID of partner of the promotion
   */
  partnerId: number | null;
  /**
   * Revenue cat offering reference/Paywall ID
   */
  offeringRef: string | null;
  /**
   * Reference of partner user who owns promotional link
   */
  partnerUserExternalRef: string | null;
  /**
   * Promotion key of the link
   */
  promotionKey: string | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: attributePromotion_attributeTrialPromotion_userErrors[];
}

export interface attributePromotion {
  attributeTrialPromotion: attributePromotion_attributeTrialPromotion | null;
}

export interface attributePromotionVariables {
  attributePromotionInput: AttributeTrialPromotionMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: disassociateProductUnitsFromMe
// ====================================================

export interface disassociateProductUnitsFromMe_disassociateProductUnitsFromMe_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface disassociateProductUnitsFromMe_disassociateProductUnitsFromMe {
  __typename: "DisassociateProductUnitsFromMeMutationPayload";
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: disassociateProductUnitsFromMe_disassociateProductUnitsFromMe_userErrors[];
}

export interface disassociateProductUnitsFromMe {
  disassociateProductUnitsFromMe: disassociateProductUnitsFromMe_disassociateProductUnitsFromMe | null;
}

export interface disassociateProductUnitsFromMeVariables {
  input: DisassociateProductUnitsFromMeMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnfollowRecords
// ====================================================

export interface UnfollowRecords_unfollowRecords_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface UnfollowRecords_unfollowRecords {
  __typename: "UnfollowRecordsMutationPayload";
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: UnfollowRecords_unfollowRecords_userErrors[];
}

export interface UnfollowRecords {
  unfollowRecords: UnfollowRecords_unfollowRecords | null;
}

export interface UnfollowRecordsVariables {
  input: UnfollowRecordsMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePageMutation
// ====================================================

export interface UpdatePageMutation_updatePage_page {
  __typename: "Page";
  _id: string;
  published: boolean;
  description: string | null;
}

export interface UpdatePageMutation_updatePage_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface UpdatePageMutation_updatePage {
  __typename: "UpdatePageMutationPayload";
  page: UpdatePageMutation_updatePage_page | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: UpdatePageMutation_updatePage_userErrors[];
}

export interface UpdatePageMutation {
  updatePage: UpdatePageMutation_updatePage | null;
}

export interface UpdatePageMutationVariables {
  pageInput: UpdatePageMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePageMutationWithBrand
// ====================================================

export interface UpdatePageMutationWithBrand_updatePage_page {
  __typename: "Page";
  _id: string;
  published: boolean;
  description: string | null;
}

export interface UpdatePageMutationWithBrand_updatePage_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface UpdatePageMutationWithBrand_updatePage {
  __typename: "UpdatePageMutationPayload";
  page: UpdatePageMutationWithBrand_updatePage_page | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: UpdatePageMutationWithBrand_updatePage_userErrors[];
}

export interface UpdatePageMutationWithBrand_updateBrand_brand {
  __typename: "Brand";
  _id: string;
  url: string | null;
}

export interface UpdatePageMutationWithBrand_updateBrand_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface UpdatePageMutationWithBrand_updateBrand {
  __typename: "UpdateBrandMutationPayload";
  brand: UpdatePageMutationWithBrand_updateBrand_brand | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: UpdatePageMutationWithBrand_updateBrand_userErrors[];
}

export interface UpdatePageMutationWithBrand {
  updatePage: UpdatePageMutationWithBrand_updatePage | null;
  updateBrand: UpdatePageMutationWithBrand_updateBrand | null;
}

export interface UpdatePageMutationWithBrandVariables {
  pageInput: UpdatePageMutationInput;
  brandInput: UpdateBrandMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePromotedCallToAction
// ====================================================

export interface updatePromotedCallToAction_updatePromotedCallToAction_promotedCallToAction_image {
  __typename: "Image";
  url: string;
}

export interface updatePromotedCallToAction_updatePromotedCallToAction_promotedCallToAction {
  __typename: "PagesPromotedCallToAction";
  /**
   * ID
   */
  id: number;
  headline: string;
  subHeadline: string | null;
  targetUrl: string;
  buttonText: string;
  image: updatePromotedCallToAction_updatePromotedCallToAction_promotedCallToAction_image;
}

export interface updatePromotedCallToAction_updatePromotedCallToAction_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface updatePromotedCallToAction_updatePromotedCallToAction {
  __typename: "UpdatePromotedCallToActionMutationPayload";
  promotedCallToAction: updatePromotedCallToAction_updatePromotedCallToAction_promotedCallToAction | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: updatePromotedCallToAction_updatePromotedCallToAction_userErrors[];
}

export interface updatePromotedCallToAction {
  updatePromotedCallToAction: updatePromotedCallToAction_updatePromotedCallToAction | null;
}

export interface updatePromotedCallToActionVariables {
  input: UpdatePromotedCallToActionMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDirectUploadUrl
// ====================================================

export interface CreateDirectUploadUrl_createDirectUploadUrl_directUploadUrl_headers {
  __typename: "DirectUploadRequestHeader";
  name: string;
  value: string;
}

export interface CreateDirectUploadUrl_createDirectUploadUrl_directUploadUrl {
  __typename: "DirectUploadURL";
  /**
   * ID with which content can be identified by backend. Use it to attach content to entities
   */
  signedId: string;
  /**
   * PUT content to the url
   */
  url: string;
  /**
   * Headers to be used in PUT request
   */
  headers: CreateDirectUploadUrl_createDirectUploadUrl_directUploadUrl_headers[];
}

export interface CreateDirectUploadUrl_createDirectUploadUrl_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface CreateDirectUploadUrl_createDirectUploadUrl {
  __typename: "CreateDirectUploadURLMutationPayload";
  directUploadUrl: CreateDirectUploadUrl_createDirectUploadUrl_directUploadUrl | null;
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: CreateDirectUploadUrl_createDirectUploadUrl_userErrors[];
}

export interface CreateDirectUploadUrl {
  createDirectUploadUrl: CreateDirectUploadUrl_createDirectUploadUrl | null;
}

export interface CreateDirectUploadUrlVariables {
  input: CreateDirectUploadURLMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertConsent
// ====================================================

export interface UpsertConsent_upsertConsent_userErrors {
  __typename: "UserError";
  /**
   * Which input value this error came from
   */
  path: string[] | null;
  /**
   * A description of the error
   */
  message: string;
}

export interface UpsertConsent_upsertConsent {
  __typename: "UpsertConsentMutationPayload";
  /**
   * Mutation logic errors which can be shown to the end-user to give them context
   */
  userErrors: UpsertConsent_upsertConsent_userErrors[];
}

export interface UpsertConsent {
  upsertConsent: UpsertConsent_upsertConsent | null;
}

export interface UpsertConsentVariables {
  input: UpsertConsentMutationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllBrands
// ====================================================

export interface GetAllBrands_entry {
  __typename: "Page";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  displayName: string;
}

export interface GetAllBrands {
  /**
   * An array containing all published brand Pages in alphabetical order
   */
  entry: GetAllBrands_entry[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FilterBrands
// ====================================================

export interface FilterBrands_brands_edges_node {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface FilterBrands_brands_edges {
  __typename: "BrandEdge";
  /**
   * The item at the end of the edge.
   */
  node: FilterBrands_brands_edges_node | null;
}

export interface FilterBrands_brands {
  __typename: "BrandRecordConnection";
  /**
   * A list of edges.
   */
  edges: (FilterBrands_brands_edges | null)[] | null;
}

export interface FilterBrands {
  /**
   * Companies which sell fishing products
   */
  brands: FilterBrands_brands;
}

export interface FilterBrandsVariables {
  first?: number | null;
  searchTerm?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDiscoveredBrands
// ====================================================

export interface GetDiscoveredBrands_entry_edges_node_followers {
  __typename: "UserRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetDiscoveredBrands_entry_edges_node_logo {
  __typename: "Image";
  url: string;
}

export interface GetDiscoveredBrands_entry_edges_node_coverImages_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetDiscoveredBrands_entry_edges_node_coverImages_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetDiscoveredBrands_entry_edges_node_coverImages_edges_node | null;
}

export interface GetDiscoveredBrands_entry_edges_node_coverImages {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetDiscoveredBrands_entry_edges_node_coverImages_edges | null)[] | null;
}

export interface GetDiscoveredBrands_entry_edges_node {
  __typename: "Page";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  displayName: string;
  followedByCurrentUser: boolean;
  followers: GetDiscoveredBrands_entry_edges_node_followers;
  logo: GetDiscoveredBrands_entry_edges_node_logo | null;
  coverImages: GetDiscoveredBrands_entry_edges_node_coverImages;
}

export interface GetDiscoveredBrands_entry_edges {
  __typename: "PageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetDiscoveredBrands_entry_edges_node | null;
}

export interface GetDiscoveredBrands_entry {
  __typename: "PageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetDiscoveredBrands_entry_edges | null)[] | null;
}

export interface GetDiscoveredBrands {
  entry: GetDiscoveredBrands_entry;
}

export interface GetDiscoveredBrandsVariables {
  filters?: PageFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatchPostComments
// ====================================================

export interface GetCatchPostComments_post_comments_edges_node_text {
  __typename: "Text";
  text: string | null;
}

export interface GetCatchPostComments_post_comments_edges_node_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetCatchPostComments_post_comments_edges_node_user {
  __typename: "User";
  avatar: GetCatchPostComments_post_comments_edges_node_user_avatar | null;
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetCatchPostComments_post_comments_edges_node_comments_edges_node_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetCatchPostComments_post_comments_edges_node_comments_edges_node_user {
  __typename: "User";
  avatar: GetCatchPostComments_post_comments_edges_node_comments_edges_node_user_avatar | null;
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetCatchPostComments_post_comments_edges_node_comments_edges_node_text {
  __typename: "Text";
  text: string | null;
}

export interface GetCatchPostComments_post_comments_edges_node_comments_edges_node {
  __typename: "Post";
  _id: string;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * The user that created this post
   */
  user: GetCatchPostComments_post_comments_edges_node_comments_edges_node_user;
  /**
   * Text content in this post
   */
  text: GetCatchPostComments_post_comments_edges_node_comments_edges_node_text;
}

export interface GetCatchPostComments_post_comments_edges_node_comments_edges {
  __typename: "PostEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetCatchPostComments_post_comments_edges_node_comments_edges_node | null;
}

export interface GetCatchPostComments_post_comments_edges_node_comments {
  __typename: "PostRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetCatchPostComments_post_comments_edges_node_comments_edges | null)[] | null;
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetCatchPostComments_post_comments_edges_node {
  __typename: "Post";
  _id: string;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetCatchPostComments_post_comments_edges_node_text;
  /**
   * The user that created this post
   */
  user: GetCatchPostComments_post_comments_edges_node_user;
  /**
   * Comments on this post
   */
  comments: GetCatchPostComments_post_comments_edges_node_comments;
}

export interface GetCatchPostComments_post_comments_edges {
  __typename: "PostEdge";
  /**
   * A cursor for use in pagination.
   */
  cursor: string;
  /**
   * The item at the end of the edge.
   */
  node: GetCatchPostComments_post_comments_edges_node | null;
}

export interface GetCatchPostComments_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetCatchPostComments_post_comments_edges | null)[] | null;
}

export interface GetCatchPostComments_post {
  __typename: "Post";
  _id: string;
  /**
   * Comments on this post
   */
  comments: GetCatchPostComments_post_comments;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
}

export interface GetCatchPostComments {
  /**
   * For fetching a single post
   */
  post: GetCatchPostComments_post;
}

export interface GetCatchPostCommentsVariables {
  externalId?: string | null;
  first?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatchDetails
// ====================================================

export interface GetCatchDetails_catchDetails_catchConditions_weatherAndMarineReading_weatherCondition {
  __typename: "WeatherCondition";
  localizedValue: string;
  /**
   * Use this identifier to retrieve the relevant weather symbol icon from World Weather Online
   */
  worldWeatherOnlineIdentifier: string;
}

export interface GetCatchDetails_catchDetails_catchConditions_weatherAndMarineReading_windDirection {
  __typename: "WindDirection";
  degrees: number | null;
  shortLocalizedValue: string;
}

export interface GetCatchDetails_catchDetails_catchConditions_weatherAndMarineReading {
  __typename: "WeatherAndMarineReading";
  /**
   * DB ID
   */
  id: number;
  /**
   * Air pressure [Pa]
   */
  airPressure: number | null;
  /**
   * Air temperature [K]
   */
  airTemperature: number | null;
  /**
   * Water temperature [K]
   */
  waterTemperature: number | null;
  weatherCondition: GetCatchDetails_catchDetails_catchConditions_weatherAndMarineReading_weatherCondition | null;
  windDirection: GetCatchDetails_catchDetails_catchConditions_weatherAndMarineReading_windDirection | null;
  /**
   * Wind speed [m/s]
   */
  windSpeed: number | null;
}

export interface GetCatchDetails_catchDetails_catchConditions {
  __typename: "Catch";
  _id: string;
  caughtAtLocalTimeZone: any | null;
  latitude: number | null;
  longitude: number | null;
  /**
   * The moon phase when the catch was made.
   */
  moonIllumination: MoonIlluminationTypes | null;
  /**
   * The sun position when the catch was made.
   */
  sunPosition: SunPositionTypes | null;
  weatherAndMarineReading: GetCatchDetails_catchDetails_catchConditions_weatherAndMarineReading | null;
}

export interface GetCatchDetails_catchDetails_catchPost_fishingMethod {
  __typename: "FishingMethod";
  _id: string;
  displayName: string;
}

export interface GetCatchDetails_catchDetails_catchPost_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetCatchDetails_catchDetails_catchPost_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetCatchDetails_catchDetails_catchPost_user_avatar {
  __typename: "Image";
  url: string;
}

export interface GetCatchDetails_catchDetails_catchPost_user {
  __typename: "User";
  _id: string;
  avatar: GetCatchDetails_catchDetails_catchPost_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetCatchDetails_catchDetails_catchPost {
  __typename: "Catch";
  _id: string;
  catchAndRelease: boolean | null;
  caughtAtGmt: any | null;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  fishingMethod: GetCatchDetails_catchDetails_catchPost_fishingMethod | null;
  fishingWater: GetCatchDetails_catchDetails_catchPost_fishingWater | null;
  hasExactPosition: boolean;
  /**
   * User-specified length of the catch in m
   */
  length: number | null;
  /**
   * Level of publicity the catch owner has given to the catch location
   */
  locationPrivacy: CatchLocationPrivacyTypes;
  species: GetCatchDetails_catchDetails_catchPost_species | null;
  /**
   * Owner of the catch
   */
  user: GetCatchDetails_catchDetails_catchPost_user;
  /**
   * User-specified weight of the catch in kg
   */
  weight: number | null;
}

export interface GetCatchDetails_catchDetails_trip_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetCatchDetails_catchDetails_trip {
  __typename: "Trip";
  id: string;
  catches: GetCatchDetails_catchDetails_trip_catches;
}

export interface GetCatchDetails_catchDetails_catchGear_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetCatchDetails_catchDetails_catchGear_edges_node_product_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetCatchDetails_catchDetails_catchGear_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetCatchDetails_catchDetails_catchGear_edges_node_product_brand;
  image: GetCatchDetails_catchDetails_catchGear_edges_node_product_image | null;
  displayName: string;
}

export interface GetCatchDetails_catchDetails_catchGear_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  product: GetCatchDetails_catchDetails_catchGear_edges_node_product;
}

export interface GetCatchDetails_catchDetails_catchGear_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetCatchDetails_catchDetails_catchGear_edges_node | null;
}

export interface GetCatchDetails_catchDetails_catchGear {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetCatchDetails_catchDetails_catchGear_edges | null)[] | null;
}

export interface GetCatchDetails_catchDetails {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catchConditions: GetCatchDetails_catchDetails_catchConditions | null;
  /**
   * Catch details on this post
   */
  catchPost: GetCatchDetails_catchDetails_catchPost | null;
  trip: GetCatchDetails_catchDetails_trip | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  catchGear: GetCatchDetails_catchDetails_catchGear;
}

export interface GetCatchDetails {
  /**
   * For fetching a single post
   */
  catchDetails: GetCatchDetails_catchDetails;
}

export interface GetCatchDetailsVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatchImages
// ====================================================

export interface GetCatchImages_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetCatchImages_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetCatchImages_post_images_edges_node | null;
}

export interface GetCatchImages_post_images {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetCatchImages_post_images_edges | null)[] | null;
}

export interface GetCatchImages_post {
  __typename: "Post";
  _id: string;
  images: GetCatchImages_post_images;
}

export interface GetCatchImages {
  /**
   * For fetching a single post
   */
  post: GetCatchImages_post;
}

export interface GetCatchImagesVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatchPostLikers
// ====================================================

export interface GetCatchPostLikers_post_likers_edges_node_Page {
  __typename: "Page" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
}

export interface GetCatchPostLikers_post_likers_edges_node_User_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetCatchPostLikers_post_likers_edges_node_User {
  __typename: "User";
  _id: string;
  firstName: string | null;
  lastName: string | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  avatar: GetCatchPostLikers_post_likers_edges_node_User_avatar | null;
}

export type GetCatchPostLikers_post_likers_edges_node = GetCatchPostLikers_post_likers_edges_node_Page | GetCatchPostLikers_post_likers_edges_node_User;

export interface GetCatchPostLikers_post_likers_edges {
  __typename: "PostsFeedDisplayEntityEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetCatchPostLikers_post_likers_edges_node | null;
}

export interface GetCatchPostLikers_post_likers {
  __typename: "PostsFeedDisplayEntityRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetCatchPostLikers_post_likers_edges | null)[] | null;
}

export interface GetCatchPostLikers_post {
  __typename: "Post";
  _id: string;
  /**
   * Users and pages that likes this post
   */
  likers: GetCatchPostLikers_post_likers;
}

export interface GetCatchPostLikers {
  /**
   * For fetching a single post
   */
  post: GetCatchPostLikers_post;
}

export interface GetCatchPostLikersVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatchPostLikes
// ====================================================

export interface GetCatchPostLikes_post {
  __typename: "Post";
  _id: string;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
}

export interface GetCatchPostLikes {
  /**
   * For fetching a single post
   */
  post: GetCatchPostLikes_post;
}

export interface GetCatchPostLikesVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOtherSuggestedCatches
// ====================================================

export interface GetOtherSuggestedCatches_entry_recommendedCatches_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetOtherSuggestedCatches_entry_recommendedCatches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetOtherSuggestedCatches_entry_recommendedCatches_edges_node_post_images_edges_node | null;
}

export interface GetOtherSuggestedCatches_entry_recommendedCatches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetOtherSuggestedCatches_entry_recommendedCatches_edges_node_post_images_edges | null)[] | null;
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetOtherSuggestedCatches_entry_recommendedCatches_edges_node_post {
  __typename: "Post";
  _id: string;
  images: GetOtherSuggestedCatches_entry_recommendedCatches_edges_node_post_images;
}

export interface GetOtherSuggestedCatches_entry_recommendedCatches_edges_node_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetOtherSuggestedCatches_entry_recommendedCatches_edges_node_user {
  __typename: "User";
  _id: string;
  avatar: GetOtherSuggestedCatches_entry_recommendedCatches_edges_node_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  isPremium: boolean | null;
}

export interface GetOtherSuggestedCatches_entry_recommendedCatches_edges_node {
  __typename: "Catch";
  _id: string;
  post: GetOtherSuggestedCatches_entry_recommendedCatches_edges_node_post;
  /**
   * Owner of the catch
   */
  user: GetOtherSuggestedCatches_entry_recommendedCatches_edges_node_user;
}

export interface GetOtherSuggestedCatches_entry_recommendedCatches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetOtherSuggestedCatches_entry_recommendedCatches_edges_node | null;
}

export interface GetOtherSuggestedCatches_entry_recommendedCatches {
  __typename: "CatchRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetOtherSuggestedCatches_entry_recommendedCatches_edges | null)[] | null;
}

export interface GetOtherSuggestedCatches_entry {
  __typename: "Catch";
  _id: string;
  /**
   * Uses catch location if possible to find recommendations for this catch
   */
  recommendedCatches: GetOtherSuggestedCatches_entry_recommendedCatches;
}

export interface GetOtherSuggestedCatches {
  /**
   * For fetching a single catch
   */
  entry: GetOtherSuggestedCatches_entry;
}

export interface GetOtherSuggestedCatchesVariables {
  externalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatchConditions
// ====================================================

export interface GetCatchConditions_catch_weatherAndMarineReading_weatherCondition {
  __typename: "WeatherCondition";
  localizedValue: string;
  /**
   * Use this identifier to retrieve the relevant weather symbol icon from World Weather Online
   */
  worldWeatherOnlineIdentifier: string;
}

export interface GetCatchConditions_catch_weatherAndMarineReading_windDirection {
  __typename: "WindDirection";
  /**
   * DB ID
   */
  id: number;
  localizedValue: string;
  shortLocalizedValue: string;
  degrees: number | null;
}

export interface GetCatchConditions_catch_weatherAndMarineReading {
  __typename: "WeatherAndMarineReading";
  /**
   * DB ID
   */
  id: number;
  /**
   * Air pressure [Pa]
   */
  airPressure: number | null;
  /**
   * Air humidity [Percent]
   */
  airHumidity: number | null;
  /**
   * Air temperature [K]
   */
  airTemperature: number | null;
  /**
   * Water temperature [K]
   */
  waterTemperature: number | null;
  weatherCondition: GetCatchConditions_catch_weatherAndMarineReading_weatherCondition | null;
  windDirection: GetCatchConditions_catch_weatherAndMarineReading_windDirection | null;
  /**
   * Wind speed [m/s]
   */
  windSpeed: number | null;
}

export interface GetCatchConditions_catch {
  __typename: "Catch";
  _id: string;
  caughtAtLocalTimeZone: any | null;
  /**
   * Flag to indicate that catch conditions are hidden behind the paywall for the current user
   */
  hasHiddenConditions: boolean;
  latitude: number | null;
  /**
   * Level of publicity the catch owner has given to the catch location
   */
  locationPrivacy: CatchLocationPrivacyTypes;
  longitude: number | null;
  /**
   * The moon phase when the catch was made.
   */
  moonIllumination: MoonIlluminationTypes | null;
  /**
   * The sun position when the catch was made.
   */
  sunPosition: SunPositionTypes | null;
  weatherAndMarineReading: GetCatchConditions_catch_weatherAndMarineReading | null;
}

export interface GetCatchConditions {
  /**
   * For fetching a single catch
   */
  catch: GetCatchConditions_catch;
}

export interface GetCatchConditionsVariables {
  externalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatchCoordinates
// ====================================================

export interface GetCatchCoordinates_catch {
  __typename: "Catch";
  _id: string;
  latitude: number | null;
  longitude: number | null;
}

export interface GetCatchCoordinates {
  /**
   * For fetching a single catch
   */
  catch: GetCatchCoordinates_catch;
}

export interface GetCatchCoordinatesVariables {
  externalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserCatchesExcludingCatch
// ====================================================

export interface GetUserCatchesExcludingCatch_entry_catches_edges_node_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetUserCatchesExcludingCatch_entry_catches_edges_node_user {
  __typename: "User";
  _id: string;
  avatar: GetUserCatchesExcludingCatch_entry_catches_edges_node_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  isPremium: boolean | null;
}

export interface GetUserCatchesExcludingCatch_entry_catches_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUserCatchesExcludingCatch_entry_catches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUserCatchesExcludingCatch_entry_catches_edges_node_post_images_edges_node | null;
}

export interface GetUserCatchesExcludingCatch_entry_catches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetUserCatchesExcludingCatch_entry_catches_edges_node_post_images_edges | null)[] | null;
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetUserCatchesExcludingCatch_entry_catches_edges_node_post {
  __typename: "Post";
  _id: string;
  images: GetUserCatchesExcludingCatch_entry_catches_edges_node_post_images;
}

export interface GetUserCatchesExcludingCatch_entry_catches_edges_node {
  __typename: "Catch";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Owner of the catch
   */
  user: GetUserCatchesExcludingCatch_entry_catches_edges_node_user;
  post: GetUserCatchesExcludingCatch_entry_catches_edges_node_post;
}

export interface GetUserCatchesExcludingCatch_entry_catches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUserCatchesExcludingCatch_entry_catches_edges_node | null;
}

export interface GetUserCatchesExcludingCatch_entry_catches {
  __typename: "CatchRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetUserCatchesExcludingCatch_entry_catches_edges | null)[] | null;
}

export interface GetUserCatchesExcludingCatch_entry {
  __typename: "User";
  catches: GetUserCatchesExcludingCatch_entry_catches;
}

export interface GetUserCatchesExcludingCatch {
  /**
   * A user in the app
   */
  entry: GetUserCatchesExcludingCatch_entry;
}

export interface GetUserCatchesExcludingCatchVariables {
  externalId: string;
  excludeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPostIdByCatchId
// ====================================================

export interface GetPostIdByCatchId_entry_post {
  __typename: "Post";
  _id: string;
  externalId: string;
}

export interface GetPostIdByCatchId_entry {
  __typename: "Catch";
  _id: string;
  post: GetPostIdByCatchId_entry_post;
}

export interface GetPostIdByCatchId {
  /**
   * For fetching a single catch
   */
  entry: GetPostIdByCatchId_entry;
}

export interface GetPostIdByCatchIdVariables {
  externalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatchesInMapBoundingBox
// ====================================================

export interface GetCatchesInMapBoundingBox_mapArea_catches_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_catch_fishingWater | null;
  species: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_catch_species | null;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_productUnits_edges_node_image | null;
  product: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_productUnits_edges_node_product;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_productUnits_edges_node | null;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_productUnits_edges | null)[] | null;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_video_screenshot;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_images_edges_node | null;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_images_edges | null)[] | null;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_user {
  __typename: "User";
  _id: string;
  avatar: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_displayProductUnits;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_productUnits;
  /**
   * Video in this post
   */
  video: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_video | null;
  images: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_text;
  /**
   * The user that created this post
   */
  user: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post_user;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges_node {
  __typename: "Catch";
  _id: string;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  caughtAtGmt: any | null;
  post: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_post;
  species: GetCatchesInMapBoundingBox_mapArea_catches_edges_node_species | null;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches_edges {
  __typename: "CatchSearchEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetCatchesInMapBoundingBox_mapArea_catches_edges_node | null;
}

export interface GetCatchesInMapBoundingBox_mapArea_catches {
  __typename: "CatchSearchConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   */
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetCatchesInMapBoundingBox_mapArea_catches_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetCatchesInMapBoundingBox_mapArea_catches_edges | null)[] | null;
}

export interface GetCatchesInMapBoundingBox_mapArea {
  __typename: "MapArea";
  catches: GetCatchesInMapBoundingBox_mapArea_catches;
}

export interface GetCatchesInMapBoundingBox {
  /**
   * Represents part of the map within bounding box or circle
   */
  mapArea: GetCatchesInMapBoundingBox_mapArea;
}

export interface GetCatchesInMapBoundingBoxVariables {
  boundingBox?: BoundingBoxInputObject | null;
  first?: number | null;
  after?: string | null;
  caughtInMonths?: MonthEnum[] | null;
  speciesIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCatchCountInMapBoundingBox
// ====================================================

export interface GetCatchCountInMapBoundingBox_mapArea_catches {
  __typename: "CatchSearchConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   */
  totalCount: number;
}

export interface GetCatchCountInMapBoundingBox_mapArea {
  __typename: "MapArea";
  catches: GetCatchCountInMapBoundingBox_mapArea_catches;
}

export interface GetCatchCountInMapBoundingBox {
  /**
   * Represents part of the map within bounding box or circle
   */
  mapArea: GetCatchCountInMapBoundingBox_mapArea;
}

export interface GetCatchCountInMapBoundingBoxVariables {
  boundingBox?: BoundingBoxInputObject | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSingleCatchForViewer
// ====================================================

export interface GetSingleCatchForViewer_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetSingleCatchForViewer_catch_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetSingleCatchForViewer_catch_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetSingleCatchForViewer_catch_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetSingleCatchForViewer_catch_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetSingleCatchForViewer_catch_post_catch_fishingWater | null;
  species: GetSingleCatchForViewer_catch_post_catch_species | null;
}

export interface GetSingleCatchForViewer_catch_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetSingleCatchForViewer_catch_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetSingleCatchForViewer_catch_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetSingleCatchForViewer_catch_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetSingleCatchForViewer_catch_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetSingleCatchForViewer_catch_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetSingleCatchForViewer_catch_post_productUnits_edges_node_image | null;
  product: GetSingleCatchForViewer_catch_post_productUnits_edges_node_product;
}

export interface GetSingleCatchForViewer_catch_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSingleCatchForViewer_catch_post_productUnits_edges_node | null;
}

export interface GetSingleCatchForViewer_catch_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetSingleCatchForViewer_catch_post_productUnits_edges | null)[] | null;
}

export interface GetSingleCatchForViewer_catch_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetSingleCatchForViewer_catch_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetSingleCatchForViewer_catch_post_video_screenshot;
}

export interface GetSingleCatchForViewer_catch_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetSingleCatchForViewer_catch_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSingleCatchForViewer_catch_post_images_edges_node | null;
}

export interface GetSingleCatchForViewer_catch_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetSingleCatchForViewer_catch_post_images_edges | null)[] | null;
}

export interface GetSingleCatchForViewer_catch_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetSingleCatchForViewer_catch_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetSingleCatchForViewer_catch_post_user {
  __typename: "User";
  avatar: GetSingleCatchForViewer_catch_post_user_avatar | null;
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetSingleCatchForViewer_catch_post {
  __typename: "Post";
  _id: string;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetSingleCatchForViewer_catch_post_displayProductUnits;
  /**
   * Catch details on this post
   */
  catch: GetSingleCatchForViewer_catch_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetSingleCatchForViewer_catch_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetSingleCatchForViewer_catch_post_productUnits;
  /**
   * Video in this post
   */
  video: GetSingleCatchForViewer_catch_post_video | null;
  images: GetSingleCatchForViewer_catch_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetSingleCatchForViewer_catch_post_text;
  /**
   * The user that created this post
   */
  user: GetSingleCatchForViewer_catch_post_user;
}

export interface GetSingleCatchForViewer_catch {
  __typename: "Catch";
  _id: string;
  caughtAtGmt: any | null;
  species: GetSingleCatchForViewer_catch_species | null;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  post: GetSingleCatchForViewer_catch_post;
}

export interface GetSingleCatchForViewer {
  /**
   * For fetching a single catch
   */
  catch: GetSingleCatchForViewer_catch;
}

export interface GetSingleCatchForViewerVariables {
  externalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCountry
// ====================================================

export interface GetCountry_entry_topFishingWaters_edges_node_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  totalAverage: number;
}

export interface GetCountry_entry_topFishingWaters_edges_node_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetCountry_entry_topFishingWaters_edges_node_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
}

export interface GetCountry_entry_topFishingWaters_edges_node {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  displayName: string;
  reviews: GetCountry_entry_topFishingWaters_edges_node_reviews | null;
  country: GetCountry_entry_topFishingWaters_edges_node_country | null;
  region: GetCountry_entry_topFishingWaters_edges_node_region | null;
  /**
   * The number of catches for this water
   */
  catchesCount: number;
}

export interface GetCountry_entry_topFishingWaters_edges {
  __typename: "FishingWaterEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetCountry_entry_topFishingWaters_edges_node | null;
}

export interface GetCountry_entry_topFishingWaters {
  __typename: "FishingWaterRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetCountry_entry_topFishingWaters_edges | null)[] | null;
}

export interface GetCountry_entry_places_edges_node_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetCountry_entry_places_edges_node_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  regionCode: string | null;
}

export interface GetCountry_entry_places_edges_node_unsplashImage {
  __typename: "UnsplashImage";
  /**
   * DB ID
   */
  id: number;
  /**
   * A URL for an image. If you use this make sure to read
   * the usage guidelines first -
   * https:                 // help.unsplash.com/en/articles/2511245-unsplash-api-guidelines
   * You can also apply transformations to the image by altering the URL. See here
   * for more info - https: // unsplash.com/documentation#dynamically-resizable-images
   */
  imageUrl: string | null;
}

export interface GetCountry_entry_places_edges_node {
  __typename: "Place";
  _id: string;
  name: string;
  slug: string | null;
  latitude: number;
  longitude: number;
  /**
   * The country this place belongs to
   */
  country: GetCountry_entry_places_edges_node_country;
  /**
   * The region this place belongs to
   */
  region: GetCountry_entry_places_edges_node_region;
  /**
   * An image for this place
   */
  unsplashImage: GetCountry_entry_places_edges_node_unsplashImage | null;
}

export interface GetCountry_entry_places_edges {
  __typename: "PlaceEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetCountry_entry_places_edges_node | null;
}

export interface GetCountry_entry_places {
  __typename: "PlaceRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetCountry_entry_places_edges | null)[] | null;
}

export interface GetCountry_entry {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
  /**
   * The top fishing waters for the country
   */
  topFishingWaters: GetCountry_entry_topFishingWaters;
  places: GetCountry_entry_places;
}

export interface GetCountry {
  /**
   * For fetching a single country
   */
  entry: GetCountry_entry;
}

export interface GetCountryVariables {
  id: string;
  limit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTopSpeciesInCountry
// ====================================================

export interface GetTopSpeciesInCountry_entry_topSpecies_edges_node {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetTopSpeciesInCountry_entry_topSpecies_edges {
  __typename: "TopSpeciesSummaryEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetTopSpeciesInCountry_entry_topSpecies_edges_node | null;
}

export interface GetTopSpeciesInCountry_entry_topSpecies {
  __typename: "TopSpeciesConnection";
  /**
   * A list of edges.
   */
  edges: (GetTopSpeciesInCountry_entry_topSpecies_edges | null)[] | null;
}

export interface GetTopSpeciesInCountry_entry {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  /**
   * The top species for the country
   */
  topSpecies: GetTopSpeciesInCountry_entry_topSpecies;
}

export interface GetTopSpeciesInCountry {
  /**
   * For fetching a single country
   */
  entry: GetTopSpeciesInCountry_entry;
}

export interface GetTopSpeciesInCountryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFishingReport
// ====================================================

export interface GetFishingReport_fishingWater_catches_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetFishingReport_fishingWater_catches_edges_node_post_catch_fishingWater | null;
  species: GetFishingReport_fishingWater_catches_edges_node_post_catch_species | null;
  caughtAtGmt: any | null;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetFishingReport_fishingWater_catches_edges_node_post_displayEntity_displayIcon;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetFishingReport_fishingWater_catches_edges_node_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetFishingReport_fishingWater_catches_edges_node_post_productUnits_edges_node_image | null;
  product: GetFishingReport_fishingWater_catches_edges_node_post_productUnits_edges_node_product;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingReport_fishingWater_catches_edges_node_post_productUnits_edges_node | null;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetFishingReport_fishingWater_catches_edges_node_post_productUnits_edges | null)[] | null;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetFishingReport_fishingWater_catches_edges_node_post_video_screenshot;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingReport_fishingWater_catches_edges_node_post_images_edges_node | null;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetFishingReport_fishingWater_catches_edges_node_post_images_edges | null)[] | null;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post_user {
  __typename: "User";
  _id: string;
  avatar: GetFishingReport_fishingWater_catches_edges_node_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetFishingReport_fishingWater_catches_edges_node_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: GetFishingReport_fishingWater_catches_edges_node_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetFishingReport_fishingWater_catches_edges_node_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetFishingReport_fishingWater_catches_edges_node_post_displayProductUnits;
  displayEntity: GetFishingReport_fishingWater_catches_edges_node_post_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetFishingReport_fishingWater_catches_edges_node_post_productUnits;
  /**
   * Video in this post
   */
  video: GetFishingReport_fishingWater_catches_edges_node_post_video | null;
  images: GetFishingReport_fishingWater_catches_edges_node_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetFishingReport_fishingWater_catches_edges_node_post_text;
  /**
   * The user that created this post
   */
  user: GetFishingReport_fishingWater_catches_edges_node_post_user;
}

export interface GetFishingReport_fishingWater_catches_edges_node {
  __typename: "Catch";
  _id: string;
  post: GetFishingReport_fishingWater_catches_edges_node_post;
}

export interface GetFishingReport_fishingWater_catches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingReport_fishingWater_catches_edges_node | null;
}

export interface GetFishingReport_fishingWater_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetFishingReport_fishingWater_catches_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetFishingReport_fishingWater_catches_edges | null)[] | null;
}

export interface GetFishingReport_fishingWater_topSpeciesSummary_edges_node {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetFishingReport_fishingWater_topSpeciesSummary_edges {
  __typename: "TopSpeciesSummaryEdge";
  /**
   * The number of catches for this species
   */
  catchesCount: number;
  /**
   * The item at the end of the edge.
   */
  node: GetFishingReport_fishingWater_topSpeciesSummary_edges_node | null;
}

export interface GetFishingReport_fishingWater_topSpeciesSummary {
  __typename: "TopSpeciesConnection";
  /**
   * Total number of top species for this water
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetFishingReport_fishingWater_topSpeciesSummary_edges | null)[] | null;
}

export interface GetFishingReport_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  catches: GetFishingReport_fishingWater_catches;
  /**
   * The most caught species in this water
   */
  topSpeciesSummary: GetFishingReport_fishingWater_topSpeciesSummary | null;
}

export interface GetFishingReport {
  /**
   * For fetching a single fishing water
   */
  fishingWater: GetFishingReport_fishingWater;
}

export interface GetFishingReportVariables {
  first?: number | null;
  after?: string | null;
  fishingWaterId?: string | null;
  startDate: any;
  endDate: any;
  speciesIds?: string[] | null;
  orderByRecent?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFishingReportAll
// ====================================================

export interface GetFishingReportAll_fishingWater_catches_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetFishingReportAll_fishingWater_catches_edges_node_post_catch_fishingWater | null;
  species: GetFishingReportAll_fishingWater_catches_edges_node_post_catch_species | null;
  caughtAtGmt: any | null;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetFishingReportAll_fishingWater_catches_edges_node_post_displayEntity_displayIcon;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetFishingReportAll_fishingWater_catches_edges_node_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetFishingReportAll_fishingWater_catches_edges_node_post_productUnits_edges_node_image | null;
  product: GetFishingReportAll_fishingWater_catches_edges_node_post_productUnits_edges_node_product;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingReportAll_fishingWater_catches_edges_node_post_productUnits_edges_node | null;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetFishingReportAll_fishingWater_catches_edges_node_post_productUnits_edges | null)[] | null;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetFishingReportAll_fishingWater_catches_edges_node_post_video_screenshot;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingReportAll_fishingWater_catches_edges_node_post_images_edges_node | null;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetFishingReportAll_fishingWater_catches_edges_node_post_images_edges | null)[] | null;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post_user {
  __typename: "User";
  _id: string;
  avatar: GetFishingReportAll_fishingWater_catches_edges_node_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: GetFishingReportAll_fishingWater_catches_edges_node_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetFishingReportAll_fishingWater_catches_edges_node_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetFishingReportAll_fishingWater_catches_edges_node_post_displayProductUnits;
  displayEntity: GetFishingReportAll_fishingWater_catches_edges_node_post_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetFishingReportAll_fishingWater_catches_edges_node_post_productUnits;
  /**
   * Video in this post
   */
  video: GetFishingReportAll_fishingWater_catches_edges_node_post_video | null;
  images: GetFishingReportAll_fishingWater_catches_edges_node_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetFishingReportAll_fishingWater_catches_edges_node_post_text;
  /**
   * The user that created this post
   */
  user: GetFishingReportAll_fishingWater_catches_edges_node_post_user;
}

export interface GetFishingReportAll_fishingWater_catches_edges_node {
  __typename: "Catch";
  _id: string;
  post: GetFishingReportAll_fishingWater_catches_edges_node_post;
}

export interface GetFishingReportAll_fishingWater_catches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingReportAll_fishingWater_catches_edges_node | null;
}

export interface GetFishingReportAll_fishingWater_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetFishingReportAll_fishingWater_catches_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetFishingReportAll_fishingWater_catches_edges | null)[] | null;
}

export interface GetFishingReportAll_fishingWater_topSpeciesSummary_edges_node {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetFishingReportAll_fishingWater_topSpeciesSummary_edges {
  __typename: "TopSpeciesSummaryEdge";
  /**
   * The number of catches for this species
   */
  catchesCount: number;
  /**
   * The item at the end of the edge.
   */
  node: GetFishingReportAll_fishingWater_topSpeciesSummary_edges_node | null;
}

export interface GetFishingReportAll_fishingWater_topSpeciesSummary {
  __typename: "TopSpeciesConnection";
  /**
   * Total number of top species for this water
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetFishingReportAll_fishingWater_topSpeciesSummary_edges | null)[] | null;
}

export interface GetFishingReportAll_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  catches: GetFishingReportAll_fishingWater_catches;
  /**
   * The most caught species in this water
   */
  topSpeciesSummary: GetFishingReportAll_fishingWater_topSpeciesSummary | null;
}

export interface GetFishingReportAll {
  /**
   * For fetching a single fishing water
   */
  fishingWater: GetFishingReportAll_fishingWater;
}

export interface GetFishingReportAllVariables {
  first?: number | null;
  after?: string | null;
  fishingWaterId?: string | null;
  speciesIds?: string[] | null;
  orderByRecent?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFishingWaterSummary
// ====================================================

export interface GetFishingWaterSummary_fishingWater_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetFishingWaterSummary_fishingWater_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
}

export interface GetFishingWaterSummary_fishingWater_metadata_group {
  __typename: "FishingWaterMetadataGroup";
  id: string;
}

export interface GetFishingWaterSummary_fishingWater_metadata {
  __typename: "FishingWaterMetadata";
  id: string;
  description: string;
  name: string;
  variant: FishingWaterMetadataVariants;
  group: GetFishingWaterSummary_fishingWater_metadata_group;
}

export interface GetFishingWaterSummary_fishingWater_allReviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  totalAverage: number;
}

export interface GetFishingWaterSummary_fishingWater_catches_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetFishingWaterSummary_fishingWater_catches_edges_node_post_catch_fishingWater | null;
  species: GetFishingWaterSummary_fishingWater_catches_edges_node_post_catch_species | null;
  caughtAtGmt: any | null;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetFishingWaterSummary_fishingWater_catches_edges_node_post_displayEntity_displayIcon;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetFishingWaterSummary_fishingWater_catches_edges_node_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetFishingWaterSummary_fishingWater_catches_edges_node_post_productUnits_edges_node_image | null;
  product: GetFishingWaterSummary_fishingWater_catches_edges_node_post_productUnits_edges_node_product;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWaterSummary_fishingWater_catches_edges_node_post_productUnits_edges_node | null;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetFishingWaterSummary_fishingWater_catches_edges_node_post_productUnits_edges | null)[] | null;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetFishingWaterSummary_fishingWater_catches_edges_node_post_video_screenshot;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWaterSummary_fishingWater_catches_edges_node_post_images_edges_node | null;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetFishingWaterSummary_fishingWater_catches_edges_node_post_images_edges | null)[] | null;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post_user {
  __typename: "User";
  _id: string;
  avatar: GetFishingWaterSummary_fishingWater_catches_edges_node_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: GetFishingWaterSummary_fishingWater_catches_edges_node_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetFishingWaterSummary_fishingWater_catches_edges_node_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetFishingWaterSummary_fishingWater_catches_edges_node_post_displayProductUnits;
  displayEntity: GetFishingWaterSummary_fishingWater_catches_edges_node_post_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetFishingWaterSummary_fishingWater_catches_edges_node_post_productUnits;
  /**
   * Video in this post
   */
  video: GetFishingWaterSummary_fishingWater_catches_edges_node_post_video | null;
  images: GetFishingWaterSummary_fishingWater_catches_edges_node_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetFishingWaterSummary_fishingWater_catches_edges_node_post_text;
  /**
   * The user that created this post
   */
  user: GetFishingWaterSummary_fishingWater_catches_edges_node_post_user;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges_node {
  __typename: "Catch";
  _id: string;
  post: GetFishingWaterSummary_fishingWater_catches_edges_node_post;
}

export interface GetFishingWaterSummary_fishingWater_catches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWaterSummary_fishingWater_catches_edges_node | null;
}

export interface GetFishingWaterSummary_fishingWater_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetFishingWaterSummary_fishingWater_catches_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetFishingWaterSummary_fishingWater_catches_edges | null)[] | null;
}

export interface GetFishingWaterSummary_fishingWater {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  country: GetFishingWaterSummary_fishingWater_country | null;
  region: GetFishingWaterSummary_fishingWater_region | null;
  displayName: string;
  /**
   * The metadata related to a fishing water
   */
  metadata: GetFishingWaterSummary_fishingWater_metadata[];
  /**
   * The number of catches for this water
   */
  catchesCount: number;
  allReviews: GetFishingWaterSummary_fishingWater_allReviews | null;
  catches: GetFishingWaterSummary_fishingWater_catches;
}

export interface GetFishingWaterSummary {
  /**
   * For fetching a single fishing water
   */
  fishingWater: GetFishingWaterSummary_fishingWater;
}

export interface GetFishingWaterSummaryVariables {
  externalId?: string | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFishingWaterForCatches
// ====================================================

export interface GetFishingWaterForCatches_entry_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetFishingWaterForCatches_entry_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
}

export interface GetFishingWaterForCatches_entry_suggestedWaters_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetFishingWaterForCatches_entry_suggestedWaters_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
}

export interface GetFishingWaterForCatches_entry_suggestedWaters_metadata_group {
  __typename: "FishingWaterMetadataGroup";
  id: string;
}

export interface GetFishingWaterForCatches_entry_suggestedWaters_metadata {
  __typename: "FishingWaterMetadata";
  id: string;
  description: string;
  group: GetFishingWaterForCatches_entry_suggestedWaters_metadata_group;
  name: string;
  variant: FishingWaterMetadataVariants;
}

export interface GetFishingWaterForCatches_entry_suggestedWaters {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  country: GetFishingWaterForCatches_entry_suggestedWaters_country | null;
  region: GetFishingWaterForCatches_entry_suggestedWaters_region | null;
  displayName: string;
  /**
   * The number of catches for this water
   */
  catchesCount: number;
  /**
   * The metadata related to a fishing water
   */
  metadata: GetFishingWaterForCatches_entry_suggestedWaters_metadata[];
}

export interface GetFishingWaterForCatches_entry {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  country: GetFishingWaterForCatches_entry_country | null;
  region: GetFishingWaterForCatches_entry_region | null;
  displayName: string;
  /**
   * Waters suggested for this water, ranked by proximity, followers and catches
   */
  suggestedWaters: GetFishingWaterForCatches_entry_suggestedWaters[];
}

export interface GetFishingWaterForCatches {
  /**
   * For fetching a single fishing water
   */
  entry: GetFishingWaterForCatches_entry;
}

export interface GetFishingWaterForCatchesVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNewFishingWaterReviews
// ====================================================

export interface GetNewFishingWaterReviews_fishingWater_oneStarReviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetNewFishingWaterReviews_fishingWater_twoStarReviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetNewFishingWaterReviews_fishingWater_threeStarReviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetNewFishingWaterReviews_fishingWater_fourStarReviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetNewFishingWaterReviews_fishingWater_fiveStarReviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetNewFishingWaterReviews_fishingWater_reviews_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetNewFishingWaterReviews_fishingWater_reviews_edges_node_Review_author {
  __typename: "User";
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  lastName: string | null;
  firstName: string | null;
}

export interface GetNewFishingWaterReviews_fishingWater_reviews_edges_node_Review_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetNewFishingWaterReviews_fishingWater_reviews_edges_node_Review_post {
  __typename: "Post";
  _id: string;
  helpfulDownvotesCount: number | null;
  helpfulUpvotesCount: number | null;
  /**
   * Did the logged in user find the this helpful?  Is null if the user has not voted
   */
  currentUserFoundHelpful: boolean | null;
  /**
   * The title of the post (`title` might be needed later to return the Title object)
   */
  titleString: string | null;
  /**
   * Text content in this post
   */
  text: GetNewFishingWaterReviews_fishingWater_reviews_edges_node_Review_post_text;
}

export interface GetNewFishingWaterReviews_fishingWater_reviews_edges_node_Review {
  __typename: "Review";
  externalId: string;
  rating: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  author: GetNewFishingWaterReviews_fishingWater_reviews_edges_node_Review_author;
  post: GetNewFishingWaterReviews_fishingWater_reviews_edges_node_Review_post;
}

export interface GetNewFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview_author {
  __typename: "User";
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  lastName: string | null;
  firstName: string | null;
}

export interface GetNewFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetNewFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview_post {
  __typename: "Post";
  _id: string;
  helpfulDownvotesCount: number | null;
  helpfulUpvotesCount: number | null;
  /**
   * Did the logged in user find the this helpful?  Is null if the user has not voted
   */
  currentUserFoundHelpful: boolean | null;
  /**
   * The title of the post (`title` might be needed later to return the Title object)
   */
  titleString: string | null;
  /**
   * Text content in this post
   */
  text: GetNewFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview_post_text;
}

export interface GetNewFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview {
  __typename: "FishingWaterReview";
  _id: string;
  externalId: string;
  rating: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  author: GetNewFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview_author;
  post: GetNewFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview_post;
}

export type GetNewFishingWaterReviews_fishingWater_reviews_edges_node = GetNewFishingWaterReviews_fishingWater_reviews_edges_node_Review | GetNewFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview;

export interface GetNewFishingWaterReviews_fishingWater_reviews_edges {
  __typename: "ReviewInterfaceEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetNewFishingWaterReviews_fishingWater_reviews_edges_node | null;
}

export interface GetNewFishingWaterReviews_fishingWater_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetNewFishingWaterReviews_fishingWater_reviews_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetNewFishingWaterReviews_fishingWater_reviews_edges | null)[] | null;
}

export interface GetNewFishingWaterReviews_fishingWater {
  __typename: "FishingWater";
  _id: string;
  oneStarReviews: GetNewFishingWaterReviews_fishingWater_oneStarReviews | null;
  twoStarReviews: GetNewFishingWaterReviews_fishingWater_twoStarReviews | null;
  threeStarReviews: GetNewFishingWaterReviews_fishingWater_threeStarReviews | null;
  fourStarReviews: GetNewFishingWaterReviews_fishingWater_fourStarReviews | null;
  fiveStarReviews: GetNewFishingWaterReviews_fishingWater_fiveStarReviews | null;
  reviews: GetNewFishingWaterReviews_fishingWater_reviews | null;
}

export interface GetNewFishingWaterReviews {
  /**
   * For fetching a single fishing water
   */
  fishingWater: GetNewFishingWaterReviews_fishingWater;
}

export interface GetNewFishingWaterReviewsVariables {
  first?: number | null;
  after?: string | null;
  externalId?: string | null;
  orderByHelpfulVotes?: boolean | null;
  orderByRecent?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFishingWaterReviews
// ====================================================

export interface GetFishingWaterReviews_fishingWater_reviews_edges_node_Review_author {
  __typename: "User";
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  lastName: string | null;
  firstName: string | null;
}

export interface GetFishingWaterReviews_fishingWater_reviews_edges_node_Review_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetFishingWaterReviews_fishingWater_reviews_edges_node_Review_post {
  __typename: "Post";
  _id: string;
  helpfulDownvotesCount: number | null;
  helpfulUpvotesCount: number | null;
  /**
   * Did the logged in user find the this helpful?  Is null if the user has not voted
   */
  currentUserFoundHelpful: boolean | null;
  /**
   * The title of the post (`title` might be needed later to return the Title object)
   */
  titleString: string | null;
  /**
   * Text content in this post
   */
  text: GetFishingWaterReviews_fishingWater_reviews_edges_node_Review_post_text;
}

export interface GetFishingWaterReviews_fishingWater_reviews_edges_node_Review {
  __typename: "Review";
  externalId: string;
  rating: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  author: GetFishingWaterReviews_fishingWater_reviews_edges_node_Review_author;
  post: GetFishingWaterReviews_fishingWater_reviews_edges_node_Review_post;
}

export interface GetFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview_author {
  __typename: "User";
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  lastName: string | null;
  firstName: string | null;
}

export interface GetFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview_post {
  __typename: "Post";
  _id: string;
  helpfulDownvotesCount: number | null;
  helpfulUpvotesCount: number | null;
  /**
   * Did the logged in user find the this helpful?  Is null if the user has not voted
   */
  currentUserFoundHelpful: boolean | null;
  /**
   * The title of the post (`title` might be needed later to return the Title object)
   */
  titleString: string | null;
  /**
   * Text content in this post
   */
  text: GetFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview_post_text;
}

export interface GetFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview {
  __typename: "FishingWaterReview";
  _id: string;
  externalId: string;
  rating: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  author: GetFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview_author;
  post: GetFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview_post;
}

export type GetFishingWaterReviews_fishingWater_reviews_edges_node = GetFishingWaterReviews_fishingWater_reviews_edges_node_Review | GetFishingWaterReviews_fishingWater_reviews_edges_node_FishingWaterReview;

export interface GetFishingWaterReviews_fishingWater_reviews_edges {
  __typename: "ReviewInterfaceEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWaterReviews_fishingWater_reviews_edges_node | null;
}

export interface GetFishingWaterReviews_fishingWater_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetFishingWaterReviews_fishingWater_reviews_edges | null)[] | null;
}

export interface GetFishingWaterReviews_fishingWater {
  __typename: "FishingWater";
  _id: string;
  reviews: GetFishingWaterReviews_fishingWater_reviews | null;
}

export interface GetFishingWaterReviews {
  /**
   * For fetching a single fishing water
   */
  fishingWater: GetFishingWaterReviews_fishingWater;
}

export interface GetFishingWaterReviewsVariables {
  externalId?: string | null;
  orderByHelpfulVotes?: boolean | null;
  orderByRecent?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFishingWater
// ====================================================

export interface GetFishingWater_fishingWater_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetFishingWater_fishingWater_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
}

export interface GetFishingWater_fishingWater_metadata_group {
  __typename: "FishingWaterMetadataGroup";
  id: string;
}

export interface GetFishingWater_fishingWater_metadata {
  __typename: "FishingWaterMetadata";
  id: string;
  description: string;
  group: GetFishingWater_fishingWater_metadata_group;
  name: string;
  variant: FishingWaterMetadataVariants;
}

export interface GetFishingWater_fishingWater_last30DaysCatches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetFishingWater_fishingWater_mostLikedCatches_post_catch_fishingWater | null;
  species: GetFishingWater_fishingWater_mostLikedCatches_post_catch_species | null;
  caughtAtGmt: any | null;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetFishingWater_fishingWater_mostLikedCatches_post_displayEntity_displayIcon;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetFishingWater_fishingWater_mostLikedCatches_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetFishingWater_fishingWater_mostLikedCatches_post_productUnits_edges_node_image | null;
  product: GetFishingWater_fishingWater_mostLikedCatches_post_productUnits_edges_node_product;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWater_fishingWater_mostLikedCatches_post_productUnits_edges_node | null;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetFishingWater_fishingWater_mostLikedCatches_post_productUnits_edges | null)[] | null;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetFishingWater_fishingWater_mostLikedCatches_post_video_screenshot;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWater_fishingWater_mostLikedCatches_post_images_edges_node | null;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetFishingWater_fishingWater_mostLikedCatches_post_images_edges | null)[] | null;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post_user {
  __typename: "User";
  _id: string;
  avatar: GetFishingWater_fishingWater_mostLikedCatches_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetFishingWater_fishingWater_mostLikedCatches_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: GetFishingWater_fishingWater_mostLikedCatches_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetFishingWater_fishingWater_mostLikedCatches_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetFishingWater_fishingWater_mostLikedCatches_post_displayProductUnits;
  displayEntity: GetFishingWater_fishingWater_mostLikedCatches_post_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetFishingWater_fishingWater_mostLikedCatches_post_productUnits;
  /**
   * Video in this post
   */
  video: GetFishingWater_fishingWater_mostLikedCatches_post_video | null;
  images: GetFishingWater_fishingWater_mostLikedCatches_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetFishingWater_fishingWater_mostLikedCatches_post_text;
  /**
   * The user that created this post
   */
  user: GetFishingWater_fishingWater_mostLikedCatches_post_user;
}

export interface GetFishingWater_fishingWater_mostLikedCatches {
  __typename: "Catch";
  _id: string;
  post: GetFishingWater_fishingWater_mostLikedCatches_post;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetFishingWater_fishingWater_catches_edges_node_post_catch_fishingWater | null;
  species: GetFishingWater_fishingWater_catches_edges_node_post_catch_species | null;
  caughtAtGmt: any | null;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetFishingWater_fishingWater_catches_edges_node_post_displayEntity_displayIcon;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node_image | null;
  product: GetFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node_product;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node | null;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetFishingWater_fishingWater_catches_edges_node_post_productUnits_edges | null)[] | null;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetFishingWater_fishingWater_catches_edges_node_post_video_screenshot;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWater_fishingWater_catches_edges_node_post_images_edges_node | null;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetFishingWater_fishingWater_catches_edges_node_post_images_edges | null)[] | null;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post_user {
  __typename: "User";
  _id: string;
  avatar: GetFishingWater_fishingWater_catches_edges_node_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetFishingWater_fishingWater_catches_edges_node_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: GetFishingWater_fishingWater_catches_edges_node_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetFishingWater_fishingWater_catches_edges_node_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetFishingWater_fishingWater_catches_edges_node_post_displayProductUnits;
  displayEntity: GetFishingWater_fishingWater_catches_edges_node_post_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetFishingWater_fishingWater_catches_edges_node_post_productUnits;
  /**
   * Video in this post
   */
  video: GetFishingWater_fishingWater_catches_edges_node_post_video | null;
  images: GetFishingWater_fishingWater_catches_edges_node_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetFishingWater_fishingWater_catches_edges_node_post_text;
  /**
   * The user that created this post
   */
  user: GetFishingWater_fishingWater_catches_edges_node_post_user;
}

export interface GetFishingWater_fishingWater_catches_edges_node {
  __typename: "Catch";
  _id: string;
  post: GetFishingWater_fishingWater_catches_edges_node_post;
}

export interface GetFishingWater_fishingWater_catches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWater_fishingWater_catches_edges_node | null;
}

export interface GetFishingWater_fishingWater_catches {
  __typename: "CatchRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetFishingWater_fishingWater_catches_edges | null)[] | null;
}

export interface GetFishingWater_fishingWater_suggestedWaters_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetFishingWater_fishingWater_suggestedWaters_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
}

export interface GetFishingWater_fishingWater_suggestedWaters {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  country: GetFishingWater_fishingWater_suggestedWaters_country | null;
  region: GetFishingWater_fishingWater_suggestedWaters_region | null;
  displayName: string;
  /**
   * The number of catches for this water
   */
  catchesCount: number;
}

export interface GetFishingWater_fishingWater_nearestPlace_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
}

export interface GetFishingWater_fishingWater_nearestPlace_region {
  __typename: "Region";
  id: string | null;
  regionCode: string | null;
}

export interface GetFishingWater_fishingWater_nearestPlace {
  __typename: "Place";
  _id: string;
  latitude: number;
  longitude: number;
  name: string;
  slug: string | null;
  /**
   * The country this place belongs to
   */
  country: GetFishingWater_fishingWater_nearestPlace_country;
  /**
   * The region this place belongs to
   */
  region: GetFishingWater_fishingWater_nearestPlace_region;
}

export interface GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage_post_images_edges_node {
  __typename: "Image";
  /**
   * Used to remove catch images using REST endpoint
   */
  id: number | null;
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage_post_images_edges_node | null;
}

export interface GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage_post_images {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage_post_images_edges | null)[] | null;
}

export interface GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage_post_webPImages_edges_node {
  __typename: "Image";
  /**
   * Used to remove catch images using REST endpoint
   */
  id: number | null;
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage_post_webPImages_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage_post_webPImages_edges_node | null;
}

export interface GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage_post_webPImages {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage_post_webPImages_edges | null)[] | null;
}

export interface GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage_post {
  __typename: "Post";
  _id: string;
  images: GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage_post_images;
  webPImages: GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage_post_webPImages;
}

export interface GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage {
  __typename: "Catch";
  _id: string;
  post: GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage_post;
}

export interface GetFishingWater_fishingWater_topSpeciesSummary_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetFishingWater_fishingWater_topSpeciesSummary_edges_node {
  __typename: "Species";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Default name for this species
   */
  displayName: string;
  /**
   * A curated catch or a popular one with an image from the last month
   */
  exampleCatchWithImage: GetFishingWater_fishingWater_topSpeciesSummary_edges_node_exampleCatchWithImage | null;
  image: GetFishingWater_fishingWater_topSpeciesSummary_edges_node_image;
}

export interface GetFishingWater_fishingWater_topSpeciesSummary_edges {
  __typename: "TopSpeciesSummaryEdge";
  /**
   * The number of catches for this species
   */
  catchesCount: number;
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWater_fishingWater_topSpeciesSummary_edges_node | null;
}

export interface GetFishingWater_fishingWater_topSpeciesSummary {
  __typename: "TopSpeciesConnection";
  /**
   * A list of edges.
   */
  edges: (GetFishingWater_fishingWater_topSpeciesSummary_edges | null)[] | null;
}

export interface GetFishingWater_fishingWater_allReviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  totalAverage: number;
}

export interface GetFishingWater_fishingWater_reviews_edges_node_Review_author {
  __typename: "User";
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  lastName: string | null;
  firstName: string | null;
}

export interface GetFishingWater_fishingWater_reviews_edges_node_Review_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetFishingWater_fishingWater_reviews_edges_node_Review_post {
  __typename: "Post";
  _id: string;
  helpfulDownvotesCount: number | null;
  helpfulUpvotesCount: number | null;
  /**
   * Did the logged in user find the this helpful?  Is null if the user has not voted
   */
  currentUserFoundHelpful: boolean | null;
  /**
   * The title of the post (`title` might be needed later to return the Title object)
   */
  titleString: string | null;
  /**
   * Text content in this post
   */
  text: GetFishingWater_fishingWater_reviews_edges_node_Review_post_text;
}

export interface GetFishingWater_fishingWater_reviews_edges_node_Review {
  __typename: "Review";
  externalId: string;
  rating: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  author: GetFishingWater_fishingWater_reviews_edges_node_Review_author;
  post: GetFishingWater_fishingWater_reviews_edges_node_Review_post;
}

export interface GetFishingWater_fishingWater_reviews_edges_node_FishingWaterReview_author {
  __typename: "User";
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  lastName: string | null;
  firstName: string | null;
}

export interface GetFishingWater_fishingWater_reviews_edges_node_FishingWaterReview_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetFishingWater_fishingWater_reviews_edges_node_FishingWaterReview_post {
  __typename: "Post";
  _id: string;
  helpfulDownvotesCount: number | null;
  helpfulUpvotesCount: number | null;
  /**
   * Did the logged in user find the this helpful?  Is null if the user has not voted
   */
  currentUserFoundHelpful: boolean | null;
  /**
   * The title of the post (`title` might be needed later to return the Title object)
   */
  titleString: string | null;
  /**
   * Text content in this post
   */
  text: GetFishingWater_fishingWater_reviews_edges_node_FishingWaterReview_post_text;
}

export interface GetFishingWater_fishingWater_reviews_edges_node_FishingWaterReview {
  __typename: "FishingWaterReview";
  _id: string;
  externalId: string;
  rating: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  author: GetFishingWater_fishingWater_reviews_edges_node_FishingWaterReview_author;
  post: GetFishingWater_fishingWater_reviews_edges_node_FishingWaterReview_post;
}

export type GetFishingWater_fishingWater_reviews_edges_node = GetFishingWater_fishingWater_reviews_edges_node_Review | GetFishingWater_fishingWater_reviews_edges_node_FishingWaterReview;

export interface GetFishingWater_fishingWater_reviews_edges {
  __typename: "ReviewInterfaceEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWater_fishingWater_reviews_edges_node | null;
}

export interface GetFishingWater_fishingWater_reviews_amenities_edges_node {
  __typename: "FishingWaterReviewPropertyQuestion" | "ReviewPropertyQuestion";
  summaryHeader: string | null;
}

export interface GetFishingWater_fishingWater_reviews_amenities_edges {
  __typename: "ReviewPropertyQuestionRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWater_fishingWater_reviews_amenities_edges_node | null;
}

export interface GetFishingWater_fishingWater_reviews_amenities {
  __typename: "ReviewPropertyQuestionInterfaceRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetFishingWater_fishingWater_reviews_amenities_edges | null)[] | null;
}

export interface GetFishingWater_fishingWater_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetFishingWater_fishingWater_reviews_edges | null)[] | null;
  /**
   * Fishing water property questions that were answered in reviews
   */
  amenities: GetFishingWater_fishingWater_reviews_amenities | null;
}

export interface GetFishingWater_fishingWater {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  country: GetFishingWater_fishingWater_country | null;
  region: GetFishingWater_fishingWater_region | null;
  /**
   * If the current user follows this water
   */
  followedByCurrentUser: boolean;
  /**
   * Boolean indicating if the water has local guide info
   */
  hasLocalGuideInfo: boolean | null;
  displayName: string;
  description: string | null;
  /**
   * The metadata related to a fishing water
   */
  metadata: GetFishingWater_fishingWater_metadata[];
  /**
   * The number of catches for this water
   */
  catchesCount: number;
  last30DaysCatches: GetFishingWater_fishingWater_last30DaysCatches;
  /**
   * Return the 10 most liked catches on a water. NOTE: This is cached weekly and is not realtime.
   */
  mostLikedCatches: GetFishingWater_fishingWater_mostLikedCatches[];
  catches: GetFishingWater_fishingWater_catches;
  /**
   * Waters suggested for this water, ranked by proximity, followers and catches
   */
  suggestedWaters: GetFishingWater_fishingWater_suggestedWaters[];
  /**
   * Human readable string of water type. Treat as a key for translation purposes
   */
  descriptionOfWaterType: WaterTypes;
  /**
   * The nearest Place to this water (within a max distance of 100km)
   */
  nearestPlace: GetFishingWater_fishingWater_nearestPlace | null;
  /**
   * The most caught species in this water
   */
  topSpeciesSummary: GetFishingWater_fishingWater_topSpeciesSummary | null;
  allReviews: GetFishingWater_fishingWater_allReviews | null;
  reviews: GetFishingWater_fishingWater_reviews | null;
  /**
   * The fishing water's official website
   */
  url: string | null;
}

export interface GetFishingWater {
  /**
   * For fetching a single fishing water
   */
  fishingWater: GetFishingWater_fishingWater;
}

export interface GetFishingWaterVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNewFishingWater
// ====================================================

export interface GetNewFishingWater_fishingWater_country_topFishingWaters_edges_node {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
}

export interface GetNewFishingWater_fishingWater_country_topFishingWaters_edges {
  __typename: "FishingWaterEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetNewFishingWater_fishingWater_country_topFishingWaters_edges_node | null;
}

export interface GetNewFishingWater_fishingWater_country_topFishingWaters {
  __typename: "FishingWaterRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetNewFishingWater_fishingWater_country_topFishingWaters_edges | null)[] | null;
}

export interface GetNewFishingWater_fishingWater_country_topSpecies_edges_node {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetNewFishingWater_fishingWater_country_topSpecies_edges {
  __typename: "TopSpeciesSummaryEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetNewFishingWater_fishingWater_country_topSpecies_edges_node | null;
}

export interface GetNewFishingWater_fishingWater_country_topSpecies {
  __typename: "TopSpeciesConnection";
  /**
   * A list of edges.
   */
  edges: (GetNewFishingWater_fishingWater_country_topSpecies_edges | null)[] | null;
}

export interface GetNewFishingWater_fishingWater_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
  /**
   * The top fishing waters for the country
   */
  topFishingWaters: GetNewFishingWater_fishingWater_country_topFishingWaters;
  /**
   * The top species for the country
   */
  topSpecies: GetNewFishingWater_fishingWater_country_topSpecies;
}

export interface GetNewFishingWater_fishingWater_region_topFishingWaters_edges_node {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
}

export interface GetNewFishingWater_fishingWater_region_topFishingWaters_edges {
  __typename: "FishingWaterEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetNewFishingWater_fishingWater_region_topFishingWaters_edges_node | null;
}

export interface GetNewFishingWater_fishingWater_region_topFishingWaters {
  __typename: "FishingWaterRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetNewFishingWater_fishingWater_region_topFishingWaters_edges | null)[] | null;
}

export interface GetNewFishingWater_fishingWater_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
  /**
   * The top fishing waters for the region
   */
  topFishingWaters: GetNewFishingWater_fishingWater_region_topFishingWaters;
}

export interface GetNewFishingWater_fishingWater_metadata_group {
  __typename: "FishingWaterMetadataGroup";
  id: string;
}

export interface GetNewFishingWater_fishingWater_metadata {
  __typename: "FishingWaterMetadata";
  id: string;
  description: string;
  group: GetNewFishingWater_fishingWater_metadata_group;
  name: string;
  variant: FishingWaterMetadataVariants;
}

export interface GetNewFishingWater_fishingWater_newCatchCount {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetNewFishingWater_fishingWater_topAnglers_anglers_edges_node_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetNewFishingWater_fishingWater_topAnglers_anglers_edges_node {
  __typename: "User";
  /**
   * DB ID
   */
  id: number;
  externalId: string;
  /**
   * Is this user followed by the current user?
   */
  followedByCurrentUser: boolean;
  displayIcon: GetNewFishingWater_fishingWater_topAnglers_anglers_edges_node_displayIcon;
  displayName: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetNewFishingWater_fishingWater_topAnglers_anglers_edges {
  __typename: "TopAnglersEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetNewFishingWater_fishingWater_topAnglers_anglers_edges_node | null;
}

export interface GetNewFishingWater_fishingWater_topAnglers_anglers {
  __typename: "TopAnglersConnection";
  /**
   * Total number of top anglers for a given set of catches
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetNewFishingWater_fishingWater_topAnglers_anglers_edges | null)[] | null;
}

export interface GetNewFishingWater_fishingWater_topAnglers {
  __typename: "CatchRecordConnection";
  /**
   * List of anglers for given set of catches
   */
  anglers: GetNewFishingWater_fishingWater_topAnglers_anglers | null;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetNewFishingWater_fishingWater_catches_edges_node_post_catch_fishingWater | null;
  species: GetNewFishingWater_fishingWater_catches_edges_node_post_catch_species | null;
  caughtAtGmt: any | null;
  /**
   * User-specified weight of the catch in kg
   */
  weight: number | null;
  /**
   * User-specified length of the catch in m
   */
  length: number | null;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetNewFishingWater_fishingWater_catches_edges_node_post_displayEntity_displayIcon;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetNewFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetNewFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node_image | null;
  product: GetNewFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node_product;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetNewFishingWater_fishingWater_catches_edges_node_post_productUnits_edges_node | null;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetNewFishingWater_fishingWater_catches_edges_node_post_productUnits_edges | null)[] | null;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetNewFishingWater_fishingWater_catches_edges_node_post_video_screenshot;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetNewFishingWater_fishingWater_catches_edges_node_post_images_edges_node | null;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetNewFishingWater_fishingWater_catches_edges_node_post_images_edges | null)[] | null;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post_user {
  __typename: "User";
  _id: string;
  avatar: GetNewFishingWater_fishingWater_catches_edges_node_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: GetNewFishingWater_fishingWater_catches_edges_node_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetNewFishingWater_fishingWater_catches_edges_node_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetNewFishingWater_fishingWater_catches_edges_node_post_displayProductUnits;
  displayEntity: GetNewFishingWater_fishingWater_catches_edges_node_post_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetNewFishingWater_fishingWater_catches_edges_node_post_productUnits;
  /**
   * Video in this post
   */
  video: GetNewFishingWater_fishingWater_catches_edges_node_post_video | null;
  images: GetNewFishingWater_fishingWater_catches_edges_node_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetNewFishingWater_fishingWater_catches_edges_node_post_text;
  /**
   * The user that created this post
   */
  user: GetNewFishingWater_fishingWater_catches_edges_node_post_user;
}

export interface GetNewFishingWater_fishingWater_catches_edges_node {
  __typename: "Catch";
  _id: string;
  post: GetNewFishingWater_fishingWater_catches_edges_node_post;
}

export interface GetNewFishingWater_fishingWater_catches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetNewFishingWater_fishingWater_catches_edges_node | null;
}

export interface GetNewFishingWater_fishingWater_catches {
  __typename: "CatchRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetNewFishingWater_fishingWater_catches_edges | null)[] | null;
}

export interface GetNewFishingWater_fishingWater_suggestedWaters_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetNewFishingWater_fishingWater_suggestedWaters_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
}

export interface GetNewFishingWater_fishingWater_suggestedWaters_newCatchCount {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetNewFishingWater_fishingWater_suggestedWaters_topSpeciesSummary_edges_node {
  __typename: "Species";
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetNewFishingWater_fishingWater_suggestedWaters_topSpeciesSummary_edges {
  __typename: "TopSpeciesSummaryEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetNewFishingWater_fishingWater_suggestedWaters_topSpeciesSummary_edges_node | null;
}

export interface GetNewFishingWater_fishingWater_suggestedWaters_topSpeciesSummary {
  __typename: "TopSpeciesConnection";
  /**
   * A list of edges.
   */
  edges: (GetNewFishingWater_fishingWater_suggestedWaters_topSpeciesSummary_edges | null)[] | null;
}

export interface GetNewFishingWater_fishingWater_suggestedWaters_metadata_group {
  __typename: "FishingWaterMetadataGroup";
  id: string;
}

export interface GetNewFishingWater_fishingWater_suggestedWaters_metadata {
  __typename: "FishingWaterMetadata";
  id: string;
  description: string;
  group: GetNewFishingWater_fishingWater_suggestedWaters_metadata_group;
  name: string;
  variant: FishingWaterMetadataVariants;
}

export interface GetNewFishingWater_fishingWater_suggestedWaters {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  country: GetNewFishingWater_fishingWater_suggestedWaters_country | null;
  region: GetNewFishingWater_fishingWater_suggestedWaters_region | null;
  displayName: string;
  /**
   * The number of catches for this water
   */
  catchesCount: number;
  newCatchCount: GetNewFishingWater_fishingWater_suggestedWaters_newCatchCount;
  /**
   * The most caught species in this water
   */
  topSpeciesSummary: GetNewFishingWater_fishingWater_suggestedWaters_topSpeciesSummary | null;
  /**
   * The metadata related to a fishing water
   */
  metadata: GetNewFishingWater_fishingWater_suggestedWaters_metadata[];
}

export interface GetNewFishingWater_fishingWater_nearestPlace_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
}

export interface GetNewFishingWater_fishingWater_nearestPlace_region {
  __typename: "Region";
  id: string | null;
  regionCode: string | null;
}

export interface GetNewFishingWater_fishingWater_nearestPlace_nearbyPlaces_edges_node_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
}

export interface GetNewFishingWater_fishingWater_nearestPlace_nearbyPlaces_edges_node_region {
  __typename: "Region";
  id: string | null;
}

export interface GetNewFishingWater_fishingWater_nearestPlace_nearbyPlaces_edges_node {
  __typename: "Place";
  _id: string;
  name: string;
  slug: string | null;
  latitude: number;
  longitude: number;
  /**
   * The country this place belongs to
   */
  country: GetNewFishingWater_fishingWater_nearestPlace_nearbyPlaces_edges_node_country;
  /**
   * The region this place belongs to
   */
  region: GetNewFishingWater_fishingWater_nearestPlace_nearbyPlaces_edges_node_region;
}

export interface GetNewFishingWater_fishingWater_nearestPlace_nearbyPlaces_edges {
  __typename: "NearbyPlacesEdge";
  /**
   * The unit in which the distance is returned (miles or km)
   */
  distanceUnit: string;
  /**
   * The item at the end of the edge.
   */
  node: GetNewFishingWater_fishingWater_nearestPlace_nearbyPlaces_edges_node | null;
}

export interface GetNewFishingWater_fishingWater_nearestPlace_nearbyPlaces {
  __typename: "NearbyPlacesConnection";
  /**
   * A list of edges.
   */
  edges: (GetNewFishingWater_fishingWater_nearestPlace_nearbyPlaces_edges | null)[] | null;
}

export interface GetNewFishingWater_fishingWater_nearestPlace {
  __typename: "Place";
  _id: string;
  latitude: number;
  longitude: number;
  name: string;
  slug: string | null;
  /**
   * The country this place belongs to
   */
  country: GetNewFishingWater_fishingWater_nearestPlace_country;
  /**
   * The region this place belongs to
   */
  region: GetNewFishingWater_fishingWater_nearestPlace_region;
  /**
   * A set of places in a radius of this place lat/lon
   */
  nearbyPlaces: GetNewFishingWater_fishingWater_nearestPlace_nearbyPlaces;
}

export interface GetNewFishingWater_fishingWater_topSpeciesSummary_edges_node_newCatches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetNewFishingWater_fishingWater_topSpeciesSummary_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetNewFishingWater_fishingWater_topSpeciesSummary_edges_node {
  __typename: "Species";
  _id: string;
  newCatches: GetNewFishingWater_fishingWater_topSpeciesSummary_edges_node_newCatches;
  /**
   * Default name for this species
   */
  displayName: string;
  image: GetNewFishingWater_fishingWater_topSpeciesSummary_edges_node_image;
}

export interface GetNewFishingWater_fishingWater_topSpeciesSummary_edges {
  __typename: "TopSpeciesSummaryEdge";
  /**
   * The number of catches for this species
   */
  catchesCount: number;
  /**
   * The item at the end of the edge.
   */
  node: GetNewFishingWater_fishingWater_topSpeciesSummary_edges_node | null;
}

export interface GetNewFishingWater_fishingWater_topSpeciesSummary {
  __typename: "TopSpeciesConnection";
  /**
   * A list of edges.
   */
  edges: (GetNewFishingWater_fishingWater_topSpeciesSummary_edges | null)[] | null;
}

export interface GetNewFishingWater_fishingWater_allReviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  totalAverage: number;
}

export interface GetNewFishingWater_fishingWater_reviews_edges_node_Review_author {
  __typename: "User";
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  lastName: string | null;
  firstName: string | null;
}

export interface GetNewFishingWater_fishingWater_reviews_edges_node_Review_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetNewFishingWater_fishingWater_reviews_edges_node_Review_post {
  __typename: "Post";
  _id: string;
  helpfulDownvotesCount: number | null;
  helpfulUpvotesCount: number | null;
  /**
   * Did the logged in user find the this helpful?  Is null if the user has not voted
   */
  currentUserFoundHelpful: boolean | null;
  /**
   * The title of the post (`title` might be needed later to return the Title object)
   */
  titleString: string | null;
  /**
   * Text content in this post
   */
  text: GetNewFishingWater_fishingWater_reviews_edges_node_Review_post_text;
}

export interface GetNewFishingWater_fishingWater_reviews_edges_node_Review {
  __typename: "Review";
  externalId: string;
  rating: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  author: GetNewFishingWater_fishingWater_reviews_edges_node_Review_author;
  post: GetNewFishingWater_fishingWater_reviews_edges_node_Review_post;
}

export interface GetNewFishingWater_fishingWater_reviews_edges_node_FishingWaterReview_author {
  __typename: "User";
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  lastName: string | null;
  firstName: string | null;
}

export interface GetNewFishingWater_fishingWater_reviews_edges_node_FishingWaterReview_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetNewFishingWater_fishingWater_reviews_edges_node_FishingWaterReview_post {
  __typename: "Post";
  _id: string;
  helpfulDownvotesCount: number | null;
  helpfulUpvotesCount: number | null;
  /**
   * Did the logged in user find the this helpful?  Is null if the user has not voted
   */
  currentUserFoundHelpful: boolean | null;
  /**
   * The title of the post (`title` might be needed later to return the Title object)
   */
  titleString: string | null;
  /**
   * Text content in this post
   */
  text: GetNewFishingWater_fishingWater_reviews_edges_node_FishingWaterReview_post_text;
}

export interface GetNewFishingWater_fishingWater_reviews_edges_node_FishingWaterReview {
  __typename: "FishingWaterReview";
  _id: string;
  externalId: string;
  rating: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  author: GetNewFishingWater_fishingWater_reviews_edges_node_FishingWaterReview_author;
  post: GetNewFishingWater_fishingWater_reviews_edges_node_FishingWaterReview_post;
}

export type GetNewFishingWater_fishingWater_reviews_edges_node = GetNewFishingWater_fishingWater_reviews_edges_node_Review | GetNewFishingWater_fishingWater_reviews_edges_node_FishingWaterReview;

export interface GetNewFishingWater_fishingWater_reviews_edges {
  __typename: "ReviewInterfaceEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetNewFishingWater_fishingWater_reviews_edges_node | null;
}

export interface GetNewFishingWater_fishingWater_reviews_amenities_edges_node {
  __typename: "FishingWaterReviewPropertyQuestion" | "ReviewPropertyQuestion";
  summaryHeader: string | null;
}

export interface GetNewFishingWater_fishingWater_reviews_amenities_edges {
  __typename: "ReviewPropertyQuestionRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetNewFishingWater_fishingWater_reviews_amenities_edges_node | null;
}

export interface GetNewFishingWater_fishingWater_reviews_amenities {
  __typename: "ReviewPropertyQuestionInterfaceRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetNewFishingWater_fishingWater_reviews_amenities_edges | null)[] | null;
}

export interface GetNewFishingWater_fishingWater_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetNewFishingWater_fishingWater_reviews_edges | null)[] | null;
  /**
   * Fishing water property questions that were answered in reviews
   */
  amenities: GetNewFishingWater_fishingWater_reviews_amenities | null;
}

export interface GetNewFishingWater_fishingWater {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  country: GetNewFishingWater_fishingWater_country | null;
  region: GetNewFishingWater_fishingWater_region | null;
  /**
   * If the current user follows this water
   */
  followedByCurrentUser: boolean;
  displayName: string;
  /**
   * The metadata related to a fishing water
   */
  metadata: GetNewFishingWater_fishingWater_metadata[];
  /**
   * The number of catches for this water
   */
  catchesCount: number;
  newCatchCount: GetNewFishingWater_fishingWater_newCatchCount;
  topAnglers: GetNewFishingWater_fishingWater_topAnglers;
  catches: GetNewFishingWater_fishingWater_catches;
  /**
   * Waters suggested for this water, ranked by proximity, followers and catches
   */
  suggestedWaters: GetNewFishingWater_fishingWater_suggestedWaters[];
  /**
   * Human readable string of water type. Treat as a key for translation purposes
   */
  descriptionOfWaterType: WaterTypes;
  /**
   * The nearest Place to this water (within a max distance of 100km)
   */
  nearestPlace: GetNewFishingWater_fishingWater_nearestPlace | null;
  /**
   * The most caught species in this water
   */
  topSpeciesSummary: GetNewFishingWater_fishingWater_topSpeciesSummary | null;
  allReviews: GetNewFishingWater_fishingWater_allReviews | null;
  reviews: GetNewFishingWater_fishingWater_reviews | null;
  /**
   * The fishing water's official website
   */
  url: string | null;
}

export interface GetNewFishingWater {
  /**
   * For fetching a single fishing water
   */
  fishingWater: GetNewFishingWater_fishingWater;
}

export interface GetNewFishingWaterVariables {
  externalId?: string | null;
  recentCatchLimitDays?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBiggestCatchesInFishingWater
// ====================================================

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_catch_fishingWater | null;
  species: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_catch_species | null;
  caughtAtGmt: any | null;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_displayEntity_displayIcon;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_productUnits_edges_node_image | null;
  product: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_productUnits_edges_node_product;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_productUnits_edges_node | null;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_productUnits_edges | null)[] | null;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_video_screenshot;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_images_edges_node | null;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_images_edges | null)[] | null;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_user {
  __typename: "User";
  _id: string;
  avatar: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_displayProductUnits;
  displayEntity: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_productUnits;
  /**
   * Video in this post
   */
  video: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_video | null;
  images: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_text;
  /**
   * The user that created this post
   */
  user: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post_user;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node {
  __typename: "Catch";
  _id: string;
  post: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node_post;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges_node | null;
}

export interface GetBiggestCatchesInFishingWater_fishingWater_biggestCatches {
  __typename: "CatchRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetBiggestCatchesInFishingWater_fishingWater_biggestCatches_edges | null)[] | null;
}

export interface GetBiggestCatchesInFishingWater_fishingWater {
  __typename: "FishingWater";
  _id: string;
  biggestCatches: GetBiggestCatchesInFishingWater_fishingWater_biggestCatches;
}

export interface GetBiggestCatchesInFishingWater {
  /**
   * For fetching a single fishing water
   */
  fishingWater: GetBiggestCatchesInFishingWater_fishingWater;
}

export interface GetBiggestCatchesInFishingWaterVariables {
  externalId?: string | null;
  speciesIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFishingWaterForCard
// ====================================================

export interface GetFishingWaterForCard_entry_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetFishingWaterForCard_entry_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
}

export interface GetFishingWaterForCard_entry {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  country: GetFishingWaterForCard_entry_country | null;
  region: GetFishingWaterForCard_entry_region | null;
  displayName: string;
}

export interface GetFishingWaterForCard {
  /**
   * For fetching a single fishing water
   */
  entry: GetFishingWaterForCard_entry;
}

export interface GetFishingWaterForCardVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFishingWaterName
// ====================================================

export interface GetFishingWaterName_entry {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
}

export interface GetFishingWaterName {
  /**
   * For fetching a single fishing water
   */
  entry: GetFishingWaterName_entry;
}

export interface GetFishingWaterNameVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNearbyFishingWaters
// ====================================================

export interface GetNearbyFishingWaters_suggestedFishingWaters_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetNearbyFishingWaters_suggestedFishingWaters_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
}

export interface GetNearbyFishingWaters_suggestedFishingWaters {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  country: GetNearbyFishingWaters_suggestedFishingWaters_country | null;
  region: GetNearbyFishingWaters_suggestedFishingWaters_region | null;
  displayName: string;
  /**
   * The number of catches for this water
   */
  catchesCount: number;
}

export interface GetNearbyFishingWaters {
  /**
   * Fishing waters supported by distance and popularity
   */
  suggestedFishingWaters: GetNearbyFishingWaters_suggestedFishingWaters[];
}

export interface GetNearbyFishingWatersVariables {
  latitude: number;
  longitude: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFishingWaterLocation
// ====================================================

export interface GetFishingWaterLocation_fishingWater_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
}

export interface GetFishingWaterLocation_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
  country: GetFishingWaterLocation_fishingWater_country | null;
}

export interface GetFishingWaterLocation {
  /**
   * For fetching a single fishing water
   */
  fishingWater: GetFishingWaterLocation_fishingWater;
}

export interface GetFishingWaterLocationVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFishingWaterMetadata
// ====================================================

export interface GetFishingWaterMetadata_fishingWaterMetadata_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
}

export interface GetFishingWaterMetadata_fishingWaterMetadata_edges_node_group {
  __typename: "FishingWaterMetadataGroup";
  id: string;
  name: string;
  choice: FishingWaterMetadataGroupChoiceTypes;
}

export interface GetFishingWaterMetadata_fishingWaterMetadata_edges_node {
  __typename: "FishingWaterMetadata";
  id: string;
  name: string;
  group: GetFishingWaterMetadata_fishingWaterMetadata_edges_node_group;
}

export interface GetFishingWaterMetadata_fishingWaterMetadata_edges {
  __typename: "FishingWaterMetadataEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetFishingWaterMetadata_fishingWaterMetadata_edges_node | null;
}

export interface GetFishingWaterMetadata_fishingWaterMetadata {
  __typename: "FishingWaterMetadataRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetFishingWaterMetadata_fishingWaterMetadata_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetFishingWaterMetadata_fishingWaterMetadata_edges | null)[] | null;
}

export interface GetFishingWaterMetadata {
  fishingWaterMetadata: GetFishingWaterMetadata_fishingWaterMetadata;
}

export interface GetFishingWaterMetadataVariables {
  cursor?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrentUser
// ====================================================

export interface GetCurrentUser_currentUser_avatar {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetCurrentUser_currentUser_privateFields {
  __typename: "UserPrivateFields";
  /**
   * Email of the current user
   */
  email: string | null;
}

export interface GetCurrentUser_currentUser {
  __typename: "CurrentUser";
  _id: string;
  avatar: GetCurrentUser_currentUser_avatar | null;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any;
  isPremium: boolean | null;
  /**
   * Is this user somehow verified?
   */
  isVerified: boolean | null;
  firstName: string | null;
  lastName: string | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  countryCode: string;
  /**
   * Length unit preference for this user
   */
  lengthUnit: string;
  /**
   * Pressure unit preference for this user
   */
  pressureUnit: string;
  /**
   * Speed unit preference for this user
   */
  speedUnit: string;
  /**
   * Temperature unit preference for this user
   */
  temperatureUnit: string;
  /**
   * Weight unit preference for this user
   */
  weightUnit: string;
  /**
   * Pro subscription end day for the current user
   */
  premiumEndDate: any | null;
  /**
   * This user private reference uuid
   */
  privateUuid: string | null;
  /**
   * Personally identifiable information (available only to the current user or administrators)
   */
  privateFields: GetCurrentUser_currentUser_privateFields;
}

export interface GetCurrentUser {
  /**
   * The currently logged in user
   */
  currentUser: GetCurrentUser_currentUser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetManageablePageStatuses
// ====================================================

export interface GetManageablePageStatuses_entry_manageablePages_edges_node {
  __typename: "Page";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  name: string | null;
  published: boolean;
}

export interface GetManageablePageStatuses_entry_manageablePages_edges {
  __typename: "PageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetManageablePageStatuses_entry_manageablePages_edges_node | null;
}

export interface GetManageablePageStatuses_entry_manageablePages {
  __typename: "PageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetManageablePageStatuses_entry_manageablePages_edges | null)[] | null;
}

export interface GetManageablePageStatuses_entry {
  __typename: "CurrentUser";
  _id: string;
  /**
   * The pages this user can manage
   */
  manageablePages: GetManageablePageStatuses_entry_manageablePages;
}

export interface GetManageablePageStatuses {
  /**
   * The currently logged in user
   */
  entry: GetManageablePageStatuses_entry | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetManageablePages
// ====================================================

export interface GetManageablePages_entry_manageablePages_edges_node_logo {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetManageablePages_entry_manageablePages_edges_node_coverImages_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetManageablePages_entry_manageablePages_edges_node_coverImages_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetManageablePages_entry_manageablePages_edges_node_coverImages_edges_node | null;
}

export interface GetManageablePages_entry_manageablePages_edges_node_coverImages {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetManageablePages_entry_manageablePages_edges_node_coverImages_edges | null)[] | null;
}

export interface GetManageablePages_entry_manageablePages_edges_node_promotedCallToActions_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetManageablePages_entry_manageablePages_edges_node_promotedCallToActions_edges_node {
  __typename: "PagesPromotedCallToAction";
  /**
   * ID
   */
  id: number;
  headline: string;
  subHeadline: string | null;
  targetUrl: string;
  buttonText: string;
  image: GetManageablePages_entry_manageablePages_edges_node_promotedCallToActions_edges_node_image;
}

export interface GetManageablePages_entry_manageablePages_edges_node_promotedCallToActions_edges {
  __typename: "PagesPromotedCallToActionEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetManageablePages_entry_manageablePages_edges_node_promotedCallToActions_edges_node | null;
}

export interface GetManageablePages_entry_manageablePages_edges_node_promotedCallToActions {
  __typename: "PagesPromotedCallToActionRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetManageablePages_entry_manageablePages_edges_node_promotedCallToActions_edges | null)[] | null;
}

export interface GetManageablePages_entry_manageablePages_edges_node_pageable_State {
  __typename: "State";
}

export interface GetManageablePages_entry_manageablePages_edges_node_pageable_Brand {
  __typename: "Brand";
  id: number;
  externalId: string;
  url: string | null;
}

export type GetManageablePages_entry_manageablePages_edges_node_pageable = GetManageablePages_entry_manageablePages_edges_node_pageable_State | GetManageablePages_entry_manageablePages_edges_node_pageable_Brand;

export interface GetManageablePages_entry_manageablePages_edges_node {
  __typename: "Page";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  logo: GetManageablePages_entry_manageablePages_edges_node_logo | null;
  coverImages: GetManageablePages_entry_manageablePages_edges_node_coverImages;
  name: string | null;
  published: boolean;
  description: string | null;
  promotedCallToActions: GetManageablePages_entry_manageablePages_edges_node_promotedCallToActions;
  pageable: GetManageablePages_entry_manageablePages_edges_node_pageable | null;
}

export interface GetManageablePages_entry_manageablePages_edges {
  __typename: "PageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetManageablePages_entry_manageablePages_edges_node | null;
}

export interface GetManageablePages_entry_manageablePages {
  __typename: "PageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetManageablePages_entry_manageablePages_edges | null)[] | null;
}

export interface GetManageablePages_entry {
  __typename: "CurrentUser";
  _id: string;
  /**
   * The pages this user can manage
   */
  manageablePages: GetManageablePages_entry_manageablePages;
}

export interface GetManageablePages {
  /**
   * The currently logged in user
   */
  entry: GetManageablePages_entry | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserPosts
// ====================================================

export interface GetUserPosts_entry_posts_edges_node_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUserPosts_entry_posts_edges_node_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetUserPosts_entry_posts_edges_node_displayEntity_displayIcon;
}

export interface GetUserPosts_entry_posts_edges_node_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
}

export interface GetUserPosts_entry_posts_edges_node_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUserPosts_entry_posts_edges_node_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetUserPosts_entry_posts_edges_node_video_screenshot;
}

export interface GetUserPosts_entry_posts_edges_node_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetUserPosts_entry_posts_edges_node_likers {
  __typename: "PostsFeedDisplayEntityRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetUserPosts_entry_posts_edges_node_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetUserPosts_entry_posts_edges_node_user {
  __typename: "User";
  _id: string;
  avatar: GetUserPosts_entry_posts_edges_node_user_avatar | null;
  isPremium: boolean | null;
  firstName: string | null;
  lastName: string | null;
  /**
   * Date record was added to the database
   */
  createdAt: any;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetUserPosts_entry_posts_edges_node_trip_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetUserPosts_entry_posts_edges_node_trip {
  __typename: "Trip";
  id: string;
  /**
   * Date record was added to the database
   */
  createdAt: any;
  catches: GetUserPosts_entry_posts_edges_node_trip_catches;
  startedAt: any;
  endedAt: any;
}

export interface GetUserPosts_entry_posts_edges_node_catch_fishingMethod {
  __typename: "FishingMethod";
  _id: string;
  displayName: string;
}

export interface GetUserPosts_entry_posts_edges_node_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetUserPosts_entry_posts_edges_node_catch_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUserPosts_entry_posts_edges_node_catch_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetUserPosts_entry_posts_edges_node_catch_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetUserPosts_entry_posts_edges_node_catch_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetUserPosts_entry_posts_edges_node_catch_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetUserPosts_entry_posts_edges_node_catch_productUnits_edges_node_image | null;
  product: GetUserPosts_entry_posts_edges_node_catch_productUnits_edges_node_product;
}

export interface GetUserPosts_entry_posts_edges_node_catch_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUserPosts_entry_posts_edges_node_catch_productUnits_edges_node | null;
}

export interface GetUserPosts_entry_posts_edges_node_catch_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetUserPosts_entry_posts_edges_node_catch_productUnits_edges | null)[] | null;
}

export interface GetUserPosts_entry_posts_edges_node_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
  /**
   * DB ID
   */
  legacyId: number;
}

export interface GetUserPosts_entry_posts_edges_node_catch_trip_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetUserPosts_entry_posts_edges_node_catch_trip {
  __typename: "Trip";
  id: string;
  catches: GetUserPosts_entry_posts_edges_node_catch_trip_catches;
}

export interface GetUserPosts_entry_posts_edges_node_catch {
  __typename: "Catch";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  fishingMethod: GetUserPosts_entry_posts_edges_node_catch_fishingMethod | null;
  fishingWater: GetUserPosts_entry_posts_edges_node_catch_fishingWater | null;
  productUnits: GetUserPosts_entry_posts_edges_node_catch_productUnits;
  isPersonalBest: boolean;
  /**
   * User-specified weight of the catch in kg
   */
  weight: number | null;
  /**
   * User-specified length of the catch in m
   */
  length: number | null;
  catchAndRelease: boolean | null;
  caughtAtLocalTimeZone: any | null;
  caughtAtGmt: any | null;
  hasExactPosition: boolean;
  /**
   * Level of publicity the catch owner has given to the catch location
   */
  locationPrivacy: CatchLocationPrivacyTypes;
  species: GetUserPosts_entry_posts_edges_node_catch_species | null;
  /**
   * Trip this catch is part of
   */
  trip: GetUserPosts_entry_posts_edges_node_catch_trip | null;
}

export interface GetUserPosts_entry_posts_edges_node_text {
  __typename: "Text";
  text: string | null;
}

export interface GetUserPosts_entry_posts_edges_node_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUserPosts_entry_posts_edges_node_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUserPosts_entry_posts_edges_node_images_edges_node | null;
}

export interface GetUserPosts_entry_posts_edges_node_images {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetUserPosts_entry_posts_edges_node_images_edges | null)[] | null;
}

export interface GetUserPosts_entry_posts_edges_node {
  __typename: "Post";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Date the post was published
   */
  publishedAt: any | null;
  displayEntity: GetUserPosts_entry_posts_edges_node_displayEntity;
  /**
   * A fishing water associated with this post
   */
  fishingWater: GetUserPosts_entry_posts_edges_node_fishingWater | null;
  /**
   * Video in this post
   */
  video: GetUserPosts_entry_posts_edges_node_video | null;
  /**
   * Comments on this post
   */
  comments: GetUserPosts_entry_posts_edges_node_comments;
  /**
   * Is this post sharing another post
   */
  sharingPost: boolean;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Users and pages that likes this post
   */
  likers: GetUserPosts_entry_posts_edges_node_likers;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * The user that created this post
   */
  user: GetUserPosts_entry_posts_edges_node_user;
  trip: GetUserPosts_entry_posts_edges_node_trip | null;
  /**
   * Catch details on this post
   */
  catch: GetUserPosts_entry_posts_edges_node_catch | null;
  /**
   * Text content in this post
   */
  text: GetUserPosts_entry_posts_edges_node_text;
  images: GetUserPosts_entry_posts_edges_node_images;
}

export interface GetUserPosts_entry_posts_edges {
  __typename: "PostEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUserPosts_entry_posts_edges_node | null;
}

export interface GetUserPosts_entry_posts {
  __typename: "PostRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetUserPosts_entry_posts_edges | null)[] | null;
}

export interface GetUserPosts_entry {
  __typename: "CurrentUser";
  _id: string;
  posts: GetUserPosts_entry_posts;
}

export interface GetUserPosts {
  /**
   * The currently logged in user
   */
  entry: GetUserPosts_entry | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPublishablePages
// ====================================================

export interface GetPublishablePages_entry_pagesCanPublishAs_edges_node {
  __typename: "Page";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  name: string | null;
  externalId: string;
}

export interface GetPublishablePages_entry_pagesCanPublishAs_edges {
  __typename: "PageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPublishablePages_entry_pagesCanPublishAs_edges_node | null;
}

export interface GetPublishablePages_entry_pagesCanPublishAs {
  __typename: "PageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPublishablePages_entry_pagesCanPublishAs_edges | null)[] | null;
}

export interface GetPublishablePages_entry {
  __typename: "CurrentUser";
  _id: string;
  /**
   * The pages user can publish as
   */
  pagesCanPublishAs: GetPublishablePages_entry_pagesCanPublishAs;
}

export interface GetPublishablePages {
  /**
   * The currently logged in user
   */
  entry: GetPublishablePages_entry | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserProductUnits
// ====================================================

export interface GetUserProductUnits_entry_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUserProductUnits_entry_productUnits_edges_node_attributeValues_edges_node_value {
  __typename: "AttributeValue";
  name: string;
}

export interface GetUserProductUnits_entry_productUnits_edges_node_attributeValues_edges_node {
  __typename: "UnitAttributeValue";
  value: GetUserProductUnits_entry_productUnits_edges_node_attributeValues_edges_node_value | null;
}

export interface GetUserProductUnits_entry_productUnits_edges_node_attributeValues_edges {
  __typename: "UnitAttributeValueEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUserProductUnits_entry_productUnits_edges_node_attributeValues_edges_node | null;
}

export interface GetUserProductUnits_entry_productUnits_edges_node_attributeValues {
  __typename: "UnitAttributeValueRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetUserProductUnits_entry_productUnits_edges_node_attributeValues_edges | null)[] | null;
}

export interface GetUserProductUnits_entry_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetUserProductUnits_entry_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetUserProductUnits_entry_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetUserProductUnits_entry_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  image: GetUserProductUnits_entry_productUnits_edges_node_image | null;
  attributeValues: GetUserProductUnits_entry_productUnits_edges_node_attributeValues;
  product: GetUserProductUnits_entry_productUnits_edges_node_product;
}

export interface GetUserProductUnits_entry_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUserProductUnits_entry_productUnits_edges_node | null;
}

export interface GetUserProductUnits_entry_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetUserProductUnits_entry_productUnits_edges | null)[] | null;
}

export interface GetUserProductUnits_entry {
  __typename: "CurrentUser";
  _id: string;
  /**
   * Product Units this user has saved to their tacklebox
   */
  productUnits: GetUserProductUnits_entry_productUnits;
}

export interface GetUserProductUnits {
  /**
   * The currently logged in user
   */
  entry: GetUserProductUnits_entry | null;
}

export interface GetUserProductUnitsVariables {
  categoryId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetConsents
// ====================================================

export interface GetConsents_me_consents_edges_node {
  __typename: "Consent";
  /**
   * DB ID
   */
  id: number;
  accepted: boolean;
  agreementUid: string | null;
}

export interface GetConsents_me_consents_edges {
  __typename: "ConsentEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetConsents_me_consents_edges_node | null;
}

export interface GetConsents_me_consents {
  __typename: "ConsentRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetConsents_me_consents_edges | null)[] | null;
}

export interface GetConsents_me {
  __typename: "CurrentUser";
  _id: string;
  /**
   * The consents of this user
   */
  consents: GetConsents_me_consents;
}

export interface GetConsents {
  /**
   * The currently logged in user
   */
  me: GetConsents_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSinglePageName
// ====================================================

export interface GetSinglePageName_entry {
  __typename: "Page";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  displayName: string;
  externalId: string;
}

export interface GetSinglePageName {
  /**
   * For fetching a single page
   */
  entry: GetSinglePageName_entry;
}

export interface GetSinglePageNameVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPage
// ====================================================

export interface GetPage_entry_logo {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPage_entry_coverImages_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPage_entry_coverImages_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPage_entry_coverImages_edges_node | null;
}

export interface GetPage_entry_coverImages {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPage_entry_coverImages_edges | null)[] | null;
}

export interface GetPage_entry_posts_edges_node_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPage_entry_posts_edges_node_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetPage_entry_posts_edges_node_displayEntity_displayIcon;
}

export interface GetPage_entry_posts_edges_node_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
}

export interface GetPage_entry_posts_edges_node_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPage_entry_posts_edges_node_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetPage_entry_posts_edges_node_video_screenshot;
}

export interface GetPage_entry_posts_edges_node_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPage_entry_posts_edges_node_likers {
  __typename: "PostsFeedDisplayEntityRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPage_entry_posts_edges_node_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetPage_entry_posts_edges_node_user {
  __typename: "User";
  _id: string;
  avatar: GetPage_entry_posts_edges_node_user_avatar | null;
  isPremium: boolean | null;
  firstName: string | null;
  lastName: string | null;
  /**
   * Date record was added to the database
   */
  createdAt: any;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetPage_entry_posts_edges_node_trip_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPage_entry_posts_edges_node_trip {
  __typename: "Trip";
  id: string;
  /**
   * Date record was added to the database
   */
  createdAt: any;
  catches: GetPage_entry_posts_edges_node_trip_catches;
  startedAt: any;
  endedAt: any;
}

export interface GetPage_entry_posts_edges_node_catch_fishingMethod {
  __typename: "FishingMethod";
  _id: string;
  displayName: string;
}

export interface GetPage_entry_posts_edges_node_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetPage_entry_posts_edges_node_catch_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPage_entry_posts_edges_node_catch_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetPage_entry_posts_edges_node_catch_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetPage_entry_posts_edges_node_catch_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetPage_entry_posts_edges_node_catch_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetPage_entry_posts_edges_node_catch_productUnits_edges_node_image | null;
  product: GetPage_entry_posts_edges_node_catch_productUnits_edges_node_product;
}

export interface GetPage_entry_posts_edges_node_catch_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPage_entry_posts_edges_node_catch_productUnits_edges_node | null;
}

export interface GetPage_entry_posts_edges_node_catch_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPage_entry_posts_edges_node_catch_productUnits_edges | null)[] | null;
}

export interface GetPage_entry_posts_edges_node_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
  /**
   * DB ID
   */
  legacyId: number;
}

export interface GetPage_entry_posts_edges_node_catch_trip_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPage_entry_posts_edges_node_catch_trip {
  __typename: "Trip";
  id: string;
  catches: GetPage_entry_posts_edges_node_catch_trip_catches;
}

export interface GetPage_entry_posts_edges_node_catch {
  __typename: "Catch";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  fishingMethod: GetPage_entry_posts_edges_node_catch_fishingMethod | null;
  fishingWater: GetPage_entry_posts_edges_node_catch_fishingWater | null;
  productUnits: GetPage_entry_posts_edges_node_catch_productUnits;
  isPersonalBest: boolean;
  /**
   * User-specified weight of the catch in kg
   */
  weight: number | null;
  /**
   * User-specified length of the catch in m
   */
  length: number | null;
  catchAndRelease: boolean | null;
  caughtAtLocalTimeZone: any | null;
  caughtAtGmt: any | null;
  hasExactPosition: boolean;
  /**
   * Level of publicity the catch owner has given to the catch location
   */
  locationPrivacy: CatchLocationPrivacyTypes;
  species: GetPage_entry_posts_edges_node_catch_species | null;
  /**
   * Trip this catch is part of
   */
  trip: GetPage_entry_posts_edges_node_catch_trip | null;
}

export interface GetPage_entry_posts_edges_node_text {
  __typename: "Text";
  text: string | null;
}

export interface GetPage_entry_posts_edges_node {
  __typename: "Post";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Date the post was published
   */
  publishedAt: any | null;
  displayEntity: GetPage_entry_posts_edges_node_displayEntity;
  /**
   * A fishing water associated with this post
   */
  fishingWater: GetPage_entry_posts_edges_node_fishingWater | null;
  /**
   * Video in this post
   */
  video: GetPage_entry_posts_edges_node_video | null;
  /**
   * Comments on this post
   */
  comments: GetPage_entry_posts_edges_node_comments;
  /**
   * Is this post sharing another post
   */
  sharingPost: boolean;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Users and pages that likes this post
   */
  likers: GetPage_entry_posts_edges_node_likers;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * The user that created this post
   */
  user: GetPage_entry_posts_edges_node_user;
  trip: GetPage_entry_posts_edges_node_trip | null;
  /**
   * Catch details on this post
   */
  catch: GetPage_entry_posts_edges_node_catch | null;
  /**
   * Text content in this post
   */
  text: GetPage_entry_posts_edges_node_text;
}

export interface GetPage_entry_posts_edges {
  __typename: "PostEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPage_entry_posts_edges_node | null;
}

export interface GetPage_entry_posts {
  __typename: "PostRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPage_entry_posts_edges | null)[] | null;
}

export interface GetPage_entry_followers {
  __typename: "UserRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPage_entry_promotedCallToActions_edges_node_image {
  __typename: "Image";
  url: string;
  width: number;
  height: number;
}

export interface GetPage_entry_promotedCallToActions_edges_node {
  __typename: "PagesPromotedCallToAction";
  /**
   * ID
   */
  id: number;
  headline: string;
  subHeadline: string | null;
  buttonText: string;
  targetUrl: string;
  image: GetPage_entry_promotedCallToActions_edges_node_image;
}

export interface GetPage_entry_promotedCallToActions_edges {
  __typename: "PagesPromotedCallToActionEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPage_entry_promotedCallToActions_edges_node | null;
}

export interface GetPage_entry_promotedCallToActions {
  __typename: "PagesPromotedCallToActionRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPage_entry_promotedCallToActions_edges | null)[] | null;
}

export interface GetPage_entry_pageable_State {
  __typename: "State";
}

export interface GetPage_entry_pageable_Brand {
  __typename: "Brand";
  url: string | null;
}

export type GetPage_entry_pageable = GetPage_entry_pageable_State | GetPage_entry_pageable_Brand;

export interface GetPage_entry_resources_edges_node {
  __typename: "About" | "Catches" | "Feed" | "Gear" | "Home" | "Videos";
  label: string;
  position: number;
}

export interface GetPage_entry_resources_edges {
  __typename: "ResourceLikeEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPage_entry_resources_edges_node | null;
}

export interface GetPage_entry_resources {
  __typename: "ResourceLikeRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPage_entry_resources_edges | null)[] | null;
}

export interface GetPage_entry {
  __typename: "Page";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  displayName: string;
  published: boolean;
  logo: GetPage_entry_logo | null;
  coverImages: GetPage_entry_coverImages;
  posts: GetPage_entry_posts;
  followedByCurrentUser: boolean;
  followers: GetPage_entry_followers;
  promotedCallToActions: GetPage_entry_promotedCallToActions;
  description: string | null;
  pageable: GetPage_entry_pageable | null;
  resources: GetPage_entry_resources;
}

export interface GetPage {
  /**
   * For fetching a single page
   */
  entry: GetPage_entry;
}

export interface GetPageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPageCatches
// ====================================================

export interface GetPageCatches_entry_catches_edges_node_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetPageCatches_entry_catches_edges_node_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetPageCatches_entry_catches_edges_node_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetPageCatches_entry_catches_edges_node_post_catch_fishingWater | null;
  species: GetPageCatches_entry_catches_edges_node_post_catch_species | null;
  caughtAtGmt: any | null;
}

export interface GetPageCatches_entry_catches_edges_node_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPageCatches_entry_catches_edges_node_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPageCatches_entry_catches_edges_node_post_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPageCatches_entry_catches_edges_node_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetPageCatches_entry_catches_edges_node_post_displayEntity_displayIcon;
}

export interface GetPageCatches_entry_catches_edges_node_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPageCatches_entry_catches_edges_node_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetPageCatches_entry_catches_edges_node_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetPageCatches_entry_catches_edges_node_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetPageCatches_entry_catches_edges_node_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetPageCatches_entry_catches_edges_node_post_productUnits_edges_node_image | null;
  product: GetPageCatches_entry_catches_edges_node_post_productUnits_edges_node_product;
}

export interface GetPageCatches_entry_catches_edges_node_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPageCatches_entry_catches_edges_node_post_productUnits_edges_node | null;
}

export interface GetPageCatches_entry_catches_edges_node_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPageCatches_entry_catches_edges_node_post_productUnits_edges | null)[] | null;
}

export interface GetPageCatches_entry_catches_edges_node_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPageCatches_entry_catches_edges_node_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetPageCatches_entry_catches_edges_node_post_video_screenshot;
}

export interface GetPageCatches_entry_catches_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPageCatches_entry_catches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPageCatches_entry_catches_edges_node_post_images_edges_node | null;
}

export interface GetPageCatches_entry_catches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetPageCatches_entry_catches_edges_node_post_images_edges | null)[] | null;
}

export interface GetPageCatches_entry_catches_edges_node_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetPageCatches_entry_catches_edges_node_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetPageCatches_entry_catches_edges_node_post_user {
  __typename: "User";
  _id: string;
  avatar: GetPageCatches_entry_catches_edges_node_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetPageCatches_entry_catches_edges_node_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: GetPageCatches_entry_catches_edges_node_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetPageCatches_entry_catches_edges_node_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetPageCatches_entry_catches_edges_node_post_displayProductUnits;
  displayEntity: GetPageCatches_entry_catches_edges_node_post_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetPageCatches_entry_catches_edges_node_post_productUnits;
  /**
   * Video in this post
   */
  video: GetPageCatches_entry_catches_edges_node_post_video | null;
  images: GetPageCatches_entry_catches_edges_node_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetPageCatches_entry_catches_edges_node_post_text;
  /**
   * The user that created this post
   */
  user: GetPageCatches_entry_catches_edges_node_post_user;
}

export interface GetPageCatches_entry_catches_edges_node {
  __typename: "Catch";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  post: GetPageCatches_entry_catches_edges_node_post;
}

export interface GetPageCatches_entry_catches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPageCatches_entry_catches_edges_node | null;
}

export interface GetPageCatches_entry_catches {
  __typename: "CatchRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPageCatches_entry_catches_edges | null)[] | null;
}

export interface GetPageCatches_entry {
  __typename: "Page";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  catches: GetPageCatches_entry_catches;
}

export interface GetPageCatches {
  /**
   * For fetching a single page
   */
  entry: GetPageCatches_entry;
}

export interface GetPageCatchesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPagePosts
// ====================================================

export interface GetPagePosts_entry_posts_edges_node_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetPagePosts_entry_posts_edges_node_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetPagePosts_entry_posts_edges_node_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetPagePosts_entry_posts_edges_node_catch_fishingWater | null;
  species: GetPagePosts_entry_posts_edges_node_catch_species | null;
}

export interface GetPagePosts_entry_posts_edges_node_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPagePosts_entry_posts_edges_node_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPagePosts_entry_posts_edges_node_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPagePosts_entry_posts_edges_node_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetPagePosts_entry_posts_edges_node_displayEntity_displayIcon;
}

export interface GetPagePosts_entry_posts_edges_node_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPagePosts_entry_posts_edges_node_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetPagePosts_entry_posts_edges_node_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetPagePosts_entry_posts_edges_node_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetPagePosts_entry_posts_edges_node_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetPagePosts_entry_posts_edges_node_productUnits_edges_node_image | null;
  product: GetPagePosts_entry_posts_edges_node_productUnits_edges_node_product;
}

export interface GetPagePosts_entry_posts_edges_node_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPagePosts_entry_posts_edges_node_productUnits_edges_node | null;
}

export interface GetPagePosts_entry_posts_edges_node_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPagePosts_entry_posts_edges_node_productUnits_edges | null)[] | null;
}

export interface GetPagePosts_entry_posts_edges_node_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPagePosts_entry_posts_edges_node_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetPagePosts_entry_posts_edges_node_video_screenshot;
}

export interface GetPagePosts_entry_posts_edges_node_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPagePosts_entry_posts_edges_node_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPagePosts_entry_posts_edges_node_images_edges_node | null;
}

export interface GetPagePosts_entry_posts_edges_node_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetPagePosts_entry_posts_edges_node_images_edges | null)[] | null;
}

export interface GetPagePosts_entry_posts_edges_node_text {
  __typename: "Text";
  text: string | null;
}

export interface GetPagePosts_entry_posts_edges_node_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetPagePosts_entry_posts_edges_node_user {
  __typename: "User";
  _id: string;
  avatar: GetPagePosts_entry_posts_edges_node_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetPagePosts_entry_posts_edges_node {
  __typename: "Post";
  _id: string;
  /**
   * Date the post was published
   */
  publishedAt: any | null;
  /**
   * Is this post sharing another post
   */
  sharingPost: boolean;
  /**
   * Catch details on this post
   */
  catch: GetPagePosts_entry_posts_edges_node_catch | null;
  /**
   * Comments on this post
   */
  comments: GetPagePosts_entry_posts_edges_node_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetPagePosts_entry_posts_edges_node_displayProductUnits;
  displayEntity: GetPagePosts_entry_posts_edges_node_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetPagePosts_entry_posts_edges_node_productUnits;
  /**
   * Video in this post
   */
  video: GetPagePosts_entry_posts_edges_node_video | null;
  images: GetPagePosts_entry_posts_edges_node_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetPagePosts_entry_posts_edges_node_text;
  /**
   * The user that created this post
   */
  user: GetPagePosts_entry_posts_edges_node_user;
}

export interface GetPagePosts_entry_posts_edges {
  __typename: "PostEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPagePosts_entry_posts_edges_node | null;
}

export interface GetPagePosts_entry_posts_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
}

export interface GetPagePosts_entry_posts {
  __typename: "PostRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPagePosts_entry_posts_edges | null)[] | null;
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetPagePosts_entry_posts_pageInfo;
}

export interface GetPagePosts_entry {
  __typename: "Page";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  posts: GetPagePosts_entry_posts;
}

export interface GetPagePosts {
  /**
   * For fetching a single page
   */
  entry: GetPagePosts_entry;
}

export interface GetPagePostsVariables {
  id: string;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBrandPosts
// ====================================================

export interface GetBrandPosts_posts_edges_node_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetBrandPosts_posts_edges_node_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetBrandPosts_posts_edges_node_displayEntity_displayIcon;
}

export interface GetBrandPosts_posts_edges_node_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetBrandPosts_posts_edges_node_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetBrandPosts_posts_edges_node_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetBrandPosts_posts_edges_node_catch_fishingWater | null;
  species: GetBrandPosts_posts_edges_node_catch_species | null;
}

export interface GetBrandPosts_posts_edges_node_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetBrandPosts_posts_edges_node_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetBrandPosts_posts_edges_node_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetBrandPosts_posts_edges_node_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetBrandPosts_posts_edges_node_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetBrandPosts_posts_edges_node_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetBrandPosts_posts_edges_node_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetBrandPosts_posts_edges_node_productUnits_edges_node_image | null;
  product: GetBrandPosts_posts_edges_node_productUnits_edges_node_product;
}

export interface GetBrandPosts_posts_edges_node_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetBrandPosts_posts_edges_node_productUnits_edges_node | null;
}

export interface GetBrandPosts_posts_edges_node_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetBrandPosts_posts_edges_node_productUnits_edges | null)[] | null;
}

export interface GetBrandPosts_posts_edges_node_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetBrandPosts_posts_edges_node_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetBrandPosts_posts_edges_node_video_screenshot;
}

export interface GetBrandPosts_posts_edges_node_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetBrandPosts_posts_edges_node_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetBrandPosts_posts_edges_node_images_edges_node | null;
}

export interface GetBrandPosts_posts_edges_node_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetBrandPosts_posts_edges_node_images_edges | null)[] | null;
}

export interface GetBrandPosts_posts_edges_node_text {
  __typename: "Text";
  text: string | null;
}

export interface GetBrandPosts_posts_edges_node_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetBrandPosts_posts_edges_node_user {
  __typename: "User";
  _id: string;
  avatar: GetBrandPosts_posts_edges_node_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetBrandPosts_posts_edges_node {
  __typename: "Post";
  _id: string;
  displayEntity: GetBrandPosts_posts_edges_node_displayEntity;
  /**
   * Catch details on this post
   */
  catch: GetBrandPosts_posts_edges_node_catch | null;
  /**
   * Comments on this post
   */
  comments: GetBrandPosts_posts_edges_node_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetBrandPosts_posts_edges_node_displayProductUnits;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetBrandPosts_posts_edges_node_productUnits;
  /**
   * Video in this post
   */
  video: GetBrandPosts_posts_edges_node_video | null;
  images: GetBrandPosts_posts_edges_node_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetBrandPosts_posts_edges_node_text;
  /**
   * The user that created this post
   */
  user: GetBrandPosts_posts_edges_node_user;
}

export interface GetBrandPosts_posts_edges {
  __typename: "PublicPostRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetBrandPosts_posts_edges_node | null;
}

export interface GetBrandPosts_posts {
  __typename: "PublicPostRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetBrandPosts_posts_edges | null)[] | null;
}

export interface GetBrandPosts {
  /**
   * Posts!!!
   */
  posts: GetBrandPosts_posts;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPlace
// ====================================================

export interface GetPlace_entry_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetPlace_entry_region {
  __typename: "Region";
  id: string | null;
  regionCode: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
}

export interface GetPlace_entry_topSpeciesSummary_edges_node {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetPlace_entry_topSpeciesSummary_edges {
  __typename: "TopSpeciesSummaryEdge";
  /**
   * The number of catches for this species
   */
  catchesCount: number;
  /**
   * The item at the end of the edge.
   */
  node: GetPlace_entry_topSpeciesSummary_edges_node | null;
}

export interface GetPlace_entry_topSpeciesSummary {
  __typename: "TopSpeciesConnection";
  /**
   * A list of edges.
   */
  edges: (GetPlace_entry_topSpeciesSummary_edges | null)[] | null;
}

export interface GetPlace_entry_nearbyPlaces_edges_node_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetPlace_entry_nearbyPlaces_edges_node_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  regionCode: string | null;
}

export interface GetPlace_entry_nearbyPlaces_edges_node_unsplashImage {
  __typename: "UnsplashImage";
  /**
   * DB ID
   */
  id: number;
  /**
   * A URL for an image. If you use this make sure to read
   * the usage guidelines first -
   * https:                 // help.unsplash.com/en/articles/2511245-unsplash-api-guidelines
   * You can also apply transformations to the image by altering the URL. See here
   * for more info - https: // unsplash.com/documentation#dynamically-resizable-images
   */
  imageUrl: string | null;
}

export interface GetPlace_entry_nearbyPlaces_edges_node {
  __typename: "Place";
  _id: string;
  name: string;
  slug: string | null;
  latitude: number;
  longitude: number;
  /**
   * The country this place belongs to
   */
  country: GetPlace_entry_nearbyPlaces_edges_node_country;
  /**
   * The region this place belongs to
   */
  region: GetPlace_entry_nearbyPlaces_edges_node_region;
  /**
   * An image for this place
   */
  unsplashImage: GetPlace_entry_nearbyPlaces_edges_node_unsplashImage | null;
}

export interface GetPlace_entry_nearbyPlaces_edges {
  __typename: "NearbyPlacesEdge";
  /**
   * The distance to this nearby place
   */
  distance: number;
  /**
   * The unit in which the distance is returned (miles or km)
   */
  distanceUnit: string;
  /**
   * The item at the end of the edge.
   */
  node: GetPlace_entry_nearbyPlaces_edges_node | null;
}

export interface GetPlace_entry_nearbyPlaces {
  __typename: "NearbyPlacesConnection";
  /**
   * A list of edges.
   */
  edges: (GetPlace_entry_nearbyPlaces_edges | null)[] | null;
}

export interface GetPlace_entry_topWaterReviews_edges_node_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetPlace_entry_topWaterReviews_edges_node_post {
  __typename: "Post";
  _id: string;
  /**
   * Text content in this post
   */
  text: GetPlace_entry_topWaterReviews_edges_node_post_text;
  /**
   * The title of the post (`title` might be needed later to return the Title object)
   */
  titleString: string | null;
}

export interface GetPlace_entry_topWaterReviews_edges_node_author_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetPlace_entry_topWaterReviews_edges_node_author {
  __typename: "User";
  _id: string;
  avatar: GetPlace_entry_topWaterReviews_edges_node_author_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetPlace_entry_topWaterReviews_edges_node_fishingWater_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  totalAverage: number;
}

export interface GetPlace_entry_topWaterReviews_edges_node_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
  reviews: GetPlace_entry_topWaterReviews_edges_node_fishingWater_reviews | null;
}

export interface GetPlace_entry_topWaterReviews_edges_node {
  __typename: "FishingWaterReview";
  _id: string;
  post: GetPlace_entry_topWaterReviews_edges_node_post;
  author: GetPlace_entry_topWaterReviews_edges_node_author;
  /**
   * The fishing water the review refers to (FishingWaterReview only)
   */
  fishingWater: GetPlace_entry_topWaterReviews_edges_node_fishingWater | null;
}

export interface GetPlace_entry_topWaterReviews_edges {
  __typename: "FishingWaterReviewEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPlace_entry_topWaterReviews_edges_node | null;
}

export interface GetPlace_entry_topWaterReviews {
  __typename: "FishingWaterReviewRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPlace_entry_topWaterReviews_edges | null)[] | null;
}

export interface GetPlace_entry_unsplashImage {
  __typename: "UnsplashImage";
  /**
   * DB ID
   */
  id: number;
  /**
   * A URL for an image. If you use this make sure to read
   * the usage guidelines first -
   * https:                 // help.unsplash.com/en/articles/2511245-unsplash-api-guidelines
   * You can also apply transformations to the image by altering the URL. See here
   * for more info - https: // unsplash.com/documentation#dynamically-resizable-images
   */
  imageUrl: string | null;
}

export interface GetPlace_entry {
  __typename: "Place";
  _id: string;
  name: string;
  slug: string | null;
  latitude: number;
  longitude: number;
  description: string | null;
  /**
   * The country this place belongs to
   */
  country: GetPlace_entry_country;
  /**
   * The region this place belongs to
   */
  region: GetPlace_entry_region;
  /**
   * The most caught species in a radius of 50km for this place
   */
  topSpeciesSummary: GetPlace_entry_topSpeciesSummary;
  /**
   * A set of places in a radius of this place lat/lon
   */
  nearbyPlaces: GetPlace_entry_nearbyPlaces;
  /**
   * Top water reviews in 50km radius, ordered by helpful votes
   */
  topWaterReviews: GetPlace_entry_topWaterReviews;
  /**
   * An image for this place
   */
  unsplashImage: GetPlace_entry_unsplashImage | null;
}

export interface GetPlace {
  entry: GetPlace_entry;
}

export interface GetPlaceVariables {
  countryCode: string;
  regionCode: string;
  placeSlug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSpeciesWatersRecomendations
// ====================================================

export interface GetSpeciesWatersRecomendations_singleSpecies_suggestedWaters_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetSpeciesWatersRecomendations_singleSpecies_suggestedWaters_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
}

export interface GetSpeciesWatersRecomendations_singleSpecies_suggestedWaters_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetSpeciesWatersRecomendations_singleSpecies_suggestedWaters {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  country: GetSpeciesWatersRecomendations_singleSpecies_suggestedWaters_country | null;
  region: GetSpeciesWatersRecomendations_singleSpecies_suggestedWaters_region | null;
  displayName: string;
  catches: GetSpeciesWatersRecomendations_singleSpecies_suggestedWaters_catches;
}

export interface GetSpeciesWatersRecomendations_singleSpecies {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
  /**
   * Suggested waters for the species, based on distance and catch count
   */
  suggestedWaters: GetSpeciesWatersRecomendations_singleSpecies_suggestedWaters[];
}

export interface GetSpeciesWatersRecomendations {
  /**
   * For fetching a single single species
   */
  singleSpecies: GetSpeciesWatersRecomendations_singleSpecies;
}

export interface GetSpeciesWatersRecomendationsVariables {
  speciesId: string;
  latitude: number;
  longitude: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInitialPlaceData
// ====================================================

export interface GetInitialPlaceData_place_topSpeciesSummary_edges_node {
  __typename: "Species";
  _id: string;
}

export interface GetInitialPlaceData_place_topSpeciesSummary_edges {
  __typename: "TopSpeciesSummaryEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetInitialPlaceData_place_topSpeciesSummary_edges_node | null;
}

export interface GetInitialPlaceData_place_topSpeciesSummary {
  __typename: "TopSpeciesConnection";
  /**
   * A list of edges.
   */
  edges: (GetInitialPlaceData_place_topSpeciesSummary_edges | null)[] | null;
}

export interface GetInitialPlaceData_place {
  __typename: "Place";
  _id: string;
  latitude: number;
  longitude: number;
  /**
   * The most caught species in a radius of 50km for this place
   */
  topSpeciesSummary: GetInitialPlaceData_place_topSpeciesSummary;
}

export interface GetInitialPlaceData {
  place: GetInitialPlaceData_place;
}

export interface GetInitialPlaceDataVariables {
  countryCode: string;
  regionCode: string;
  placeSlug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPlaceCatches
// ====================================================

export interface GetPlaceCatches_place_catches_edges_node_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetPlaceCatches_place_catches_edges_node_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetPlaceCatches_place_catches_edges_node_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetPlaceCatches_place_catches_edges_node_post_catch_fishingWater | null;
  species: GetPlaceCatches_place_catches_edges_node_post_catch_species | null;
  caughtAtGmt: any | null;
}

export interface GetPlaceCatches_place_catches_edges_node_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPlaceCatches_place_catches_edges_node_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPlaceCatches_place_catches_edges_node_post_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPlaceCatches_place_catches_edges_node_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetPlaceCatches_place_catches_edges_node_post_displayEntity_displayIcon;
}

export interface GetPlaceCatches_place_catches_edges_node_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPlaceCatches_place_catches_edges_node_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetPlaceCatches_place_catches_edges_node_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetPlaceCatches_place_catches_edges_node_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetPlaceCatches_place_catches_edges_node_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetPlaceCatches_place_catches_edges_node_post_productUnits_edges_node_image | null;
  product: GetPlaceCatches_place_catches_edges_node_post_productUnits_edges_node_product;
}

export interface GetPlaceCatches_place_catches_edges_node_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPlaceCatches_place_catches_edges_node_post_productUnits_edges_node | null;
}

export interface GetPlaceCatches_place_catches_edges_node_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPlaceCatches_place_catches_edges_node_post_productUnits_edges | null)[] | null;
}

export interface GetPlaceCatches_place_catches_edges_node_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPlaceCatches_place_catches_edges_node_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetPlaceCatches_place_catches_edges_node_post_video_screenshot;
}

export interface GetPlaceCatches_place_catches_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPlaceCatches_place_catches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPlaceCatches_place_catches_edges_node_post_images_edges_node | null;
}

export interface GetPlaceCatches_place_catches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetPlaceCatches_place_catches_edges_node_post_images_edges | null)[] | null;
}

export interface GetPlaceCatches_place_catches_edges_node_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetPlaceCatches_place_catches_edges_node_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetPlaceCatches_place_catches_edges_node_post_user {
  __typename: "User";
  _id: string;
  avatar: GetPlaceCatches_place_catches_edges_node_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetPlaceCatches_place_catches_edges_node_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: GetPlaceCatches_place_catches_edges_node_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetPlaceCatches_place_catches_edges_node_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetPlaceCatches_place_catches_edges_node_post_displayProductUnits;
  displayEntity: GetPlaceCatches_place_catches_edges_node_post_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetPlaceCatches_place_catches_edges_node_post_productUnits;
  /**
   * Video in this post
   */
  video: GetPlaceCatches_place_catches_edges_node_post_video | null;
  images: GetPlaceCatches_place_catches_edges_node_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetPlaceCatches_place_catches_edges_node_post_text;
  /**
   * The user that created this post
   */
  user: GetPlaceCatches_place_catches_edges_node_post_user;
}

export interface GetPlaceCatches_place_catches_edges_node {
  __typename: "Catch";
  _id: string;
  post: GetPlaceCatches_place_catches_edges_node_post;
}

export interface GetPlaceCatches_place_catches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPlaceCatches_place_catches_edges_node | null;
}

export interface GetPlaceCatches_place_catches {
  __typename: "CatchRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPlaceCatches_place_catches_edges | null)[] | null;
}

export interface GetPlaceCatches_place {
  __typename: "Place";
  _id: string;
  /**
   * A set of catches in a radius of 50km for this place
   */
  catches: GetPlaceCatches_place_catches;
}

export interface GetPlaceCatches {
  place: GetPlaceCatches_place;
}

export interface GetPlaceCatchesVariables {
  countryCode: string;
  regionCode: string;
  placeSlug: string;
  filters?: CatchFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPlaceActiveWaters
// ====================================================

export interface GetPlaceActiveWaters_place_activeWaters_topWaters_fishingWater_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetPlaceActiveWaters_place_activeWaters_topWaters_fishingWater_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
}

export interface GetPlaceActiveWaters_place_activeWaters_topWaters_fishingWater {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  country: GetPlaceActiveWaters_place_activeWaters_topWaters_fishingWater_country | null;
  region: GetPlaceActiveWaters_place_activeWaters_topWaters_fishingWater_region | null;
  displayName: string;
}

export interface GetPlaceActiveWaters_place_activeWaters_topWaters {
  __typename: "TopWaters";
  /**
   * The number of catches within this set for this fishing water
   */
  catchesCount: number;
  /**
   * For fetching a single fishing water
   */
  fishingWater: GetPlaceActiveWaters_place_activeWaters_topWaters_fishingWater;
}

export interface GetPlaceActiveWaters_place_activeWaters {
  __typename: "CatchRecordConnection";
  /**
   * The top 10 waters for this set of catches
   */
  topWaters: GetPlaceActiveWaters_place_activeWaters_topWaters[];
}

export interface GetPlaceActiveWaters_place {
  __typename: "Place";
  _id: string;
  /**
   * A set of catches in a radius of 50km for this place
   */
  activeWaters: GetPlaceActiveWaters_place_activeWaters;
}

export interface GetPlaceActiveWaters {
  place: GetPlaceActiveWaters_place;
}

export interface GetPlaceActiveWatersVariables {
  countryCode: string;
  regionCode: string;
  placeSlug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNearbyPlaces
// ====================================================

export interface GetNearbyPlaces_entry_nearbyPlaces_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetNearbyPlaces_entry_nearbyPlaces_edges_node_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetNearbyPlaces_entry_nearbyPlaces_edges_node_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  regionCode: string | null;
}

export interface GetNearbyPlaces_entry_nearbyPlaces_edges_node {
  __typename: "Place";
  _id: string;
  name: string;
  slug: string | null;
  latitude: number;
  longitude: number;
  /**
   * The country this place belongs to
   */
  country: GetNearbyPlaces_entry_nearbyPlaces_edges_node_country;
  /**
   * The region this place belongs to
   */
  region: GetNearbyPlaces_entry_nearbyPlaces_edges_node_region;
}

export interface GetNearbyPlaces_entry_nearbyPlaces_edges {
  __typename: "NearbyPlacesEdge";
  /**
   * The distance to this nearby place
   */
  distance: number;
  /**
   * The unit in which the distance is returned (miles or km)
   */
  distanceUnit: string;
  /**
   * The item at the end of the edge.
   */
  node: GetNearbyPlaces_entry_nearbyPlaces_edges_node | null;
}

export interface GetNearbyPlaces_entry_nearbyPlaces {
  __typename: "NearbyPlacesConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetNearbyPlaces_entry_nearbyPlaces_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetNearbyPlaces_entry_nearbyPlaces_edges | null)[] | null;
}

export interface GetNearbyPlaces_entry {
  __typename: "Place";
  _id: string;
  /**
   * A set of places in a radius of this place lat/lon
   */
  nearbyPlaces: GetNearbyPlaces_entry_nearbyPlaces;
}

export interface GetNearbyPlaces {
  entry: GetNearbyPlaces_entry;
}

export interface GetNearbyPlacesVariables {
  countryCode: string;
  regionCode: string;
  placeSlug: string;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPointOfInterest
// ====================================================

export interface GetPointOfInterest_entry {
  __typename: "PointOfInterest";
  _id: string;
  address: string | null;
  city: string | null;
  country: string | null;
  county: string | null;
  description: string | null;
  email: string | null;
  phone: string | null;
  primaryName: string | null;
  rampSurface: string | null;
  rampType: string | null;
  state: string | null;
  website: string | null;
  zipcode: string | null;
  primaryType: number | null;
}

export interface GetPointOfInterest {
  /**
   * For fetching a single point of interest
   */
  entry: GetPointOfInterest_entry;
}

export interface GetPointOfInterestVariables {
  id?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPost
// ====================================================

export interface GetPost_entry_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPost_entry_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetPost_entry_displayEntity_displayIcon;
}

export interface GetPost_entry_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
}

export interface GetPost_entry_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPost_entry_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetPost_entry_video_screenshot;
}

export interface GetPost_entry_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPost_entry_likers {
  __typename: "PostsFeedDisplayEntityRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPost_entry_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetPost_entry_user {
  __typename: "User";
  _id: string;
  avatar: GetPost_entry_user_avatar | null;
  isPremium: boolean | null;
  firstName: string | null;
  lastName: string | null;
  /**
   * Date record was added to the database
   */
  createdAt: any;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetPost_entry_trip_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPost_entry_trip {
  __typename: "Trip";
  id: string;
  /**
   * Date record was added to the database
   */
  createdAt: any;
  catches: GetPost_entry_trip_catches;
  startedAt: any;
  endedAt: any;
}

export interface GetPost_entry_catch_fishingMethod {
  __typename: "FishingMethod";
  _id: string;
  displayName: string;
}

export interface GetPost_entry_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetPost_entry_catch_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPost_entry_catch_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetPost_entry_catch_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetPost_entry_catch_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetPost_entry_catch_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetPost_entry_catch_productUnits_edges_node_image | null;
  product: GetPost_entry_catch_productUnits_edges_node_product;
}

export interface GetPost_entry_catch_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPost_entry_catch_productUnits_edges_node | null;
}

export interface GetPost_entry_catch_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPost_entry_catch_productUnits_edges | null)[] | null;
}

export interface GetPost_entry_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
  /**
   * DB ID
   */
  legacyId: number;
}

export interface GetPost_entry_catch_trip_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPost_entry_catch_trip {
  __typename: "Trip";
  id: string;
  catches: GetPost_entry_catch_trip_catches;
}

export interface GetPost_entry_catch {
  __typename: "Catch";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  fishingMethod: GetPost_entry_catch_fishingMethod | null;
  fishingWater: GetPost_entry_catch_fishingWater | null;
  productUnits: GetPost_entry_catch_productUnits;
  isPersonalBest: boolean;
  /**
   * User-specified weight of the catch in kg
   */
  weight: number | null;
  /**
   * User-specified length of the catch in m
   */
  length: number | null;
  catchAndRelease: boolean | null;
  caughtAtLocalTimeZone: any | null;
  caughtAtGmt: any | null;
  hasExactPosition: boolean;
  /**
   * Level of publicity the catch owner has given to the catch location
   */
  locationPrivacy: CatchLocationPrivacyTypes;
  species: GetPost_entry_catch_species | null;
  /**
   * Trip this catch is part of
   */
  trip: GetPost_entry_catch_trip | null;
}

export interface GetPost_entry_text {
  __typename: "Text";
  text: string | null;
}

export interface GetPost_entry_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPost_entry_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetPost_entry_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetPost_entry_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetPost_entry_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetPost_entry_productUnits_edges_node_image | null;
  product: GetPost_entry_productUnits_edges_node_product;
}

export interface GetPost_entry_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPost_entry_productUnits_edges_node | null;
}

export interface GetPost_entry_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPost_entry_productUnits_edges | null)[] | null;
}

export interface GetPost_entry_displayProductUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetPost_entry_displayProductUnits_edges_node_product_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  averageRating: number;
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPost_entry_displayProductUnits_edges_node_product_image {
  __typename: "Image";
  /**
   * Used to remove catch images using REST endpoint
   */
  id: number | null;
  width: number;
  height: number;
  url: string;
}

export interface GetPost_entry_displayProductUnits_edges_node_product {
  __typename: "Product";
  brand: GetPost_entry_displayProductUnits_edges_node_product_brand;
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  externalId: string;
  displayName: string;
  reviews: GetPost_entry_displayProductUnits_edges_node_product_reviews | null;
  image: GetPost_entry_displayProductUnits_edges_node_product_image | null;
}

export interface GetPost_entry_displayProductUnits_edges_node {
  __typename: "ProductUnit";
  product: GetPost_entry_displayProductUnits_edges_node_product;
}

export interface GetPost_entry_displayProductUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPost_entry_displayProductUnits_edges_node | null;
}

export interface GetPost_entry_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetPost_entry_displayProductUnits_edges | null)[] | null;
}

export interface GetPost_entry_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPost_entry_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPost_entry_images_edges_node | null;
}

export interface GetPost_entry_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetPost_entry_images_edges | null)[] | null;
}

export interface GetPost_entry {
  __typename: "Post";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Date the post was published
   */
  publishedAt: any | null;
  displayEntity: GetPost_entry_displayEntity;
  /**
   * A fishing water associated with this post
   */
  fishingWater: GetPost_entry_fishingWater | null;
  /**
   * Video in this post
   */
  video: GetPost_entry_video | null;
  /**
   * Comments on this post
   */
  comments: GetPost_entry_comments;
  /**
   * Is this post sharing another post
   */
  sharingPost: boolean;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Users and pages that likes this post
   */
  likers: GetPost_entry_likers;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * The user that created this post
   */
  user: GetPost_entry_user;
  trip: GetPost_entry_trip | null;
  /**
   * Catch details on this post
   */
  catch: GetPost_entry_catch | null;
  /**
   * Text content in this post
   */
  text: GetPost_entry_text;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetPost_entry_productUnits;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetPost_entry_displayProductUnits;
  images: GetPost_entry_images;
}

export interface GetPost {
  /**
   * For fetching a single post
   */
  entry: GetPost_entry;
}

export interface GetPostVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPostForCatchDetailsList
// ====================================================

export interface GetPostForCatchDetailsList_post_displayProductUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetPostForCatchDetailsList_post_displayProductUnits_edges_node_product_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  averageRating: number;
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPostForCatchDetailsList_post_displayProductUnits_edges_node_product_image {
  __typename: "Image";
  /**
   * Used to remove catch images using REST endpoint
   */
  id: number | null;
  width: number;
  height: number;
  url: string;
}

export interface GetPostForCatchDetailsList_post_displayProductUnits_edges_node_product {
  __typename: "Product";
  brand: GetPostForCatchDetailsList_post_displayProductUnits_edges_node_product_brand;
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  externalId: string;
  displayName: string;
  reviews: GetPostForCatchDetailsList_post_displayProductUnits_edges_node_product_reviews | null;
  image: GetPostForCatchDetailsList_post_displayProductUnits_edges_node_product_image | null;
}

export interface GetPostForCatchDetailsList_post_displayProductUnits_edges_node {
  __typename: "ProductUnit";
  product: GetPostForCatchDetailsList_post_displayProductUnits_edges_node_product;
}

export interface GetPostForCatchDetailsList_post_displayProductUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPostForCatchDetailsList_post_displayProductUnits_edges_node | null;
}

export interface GetPostForCatchDetailsList_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetPostForCatchDetailsList_post_displayProductUnits_edges | null)[] | null;
}

export interface GetPostForCatchDetailsList_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetPostForCatchDetailsList_post_user {
  __typename: "User";
  _id: string;
  avatar: GetPostForCatchDetailsList_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetPostForCatchDetailsList_post_catch_trip_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetPostForCatchDetailsList_post_catch_trip {
  __typename: "Trip";
  id: string;
  catches: GetPostForCatchDetailsList_post_catch_trip_catches;
}

export interface GetPostForCatchDetailsList_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetPostForCatchDetailsList_post_catch_fishingMethod {
  __typename: "FishingMethod";
  _id: string;
  displayName: string;
}

export interface GetPostForCatchDetailsList_post_catch {
  __typename: "Catch";
  _id: string;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  caughtAtGmt: any | null;
  hasExactPosition: boolean;
  /**
   * Level of publicity the catch owner has given to the catch location
   */
  locationPrivacy: CatchLocationPrivacyTypes;
  catchAndRelease: boolean | null;
  /**
   * User-specified weight of the catch in kg
   */
  weight: number | null;
  /**
   * User-specified length of the catch in m
   */
  length: number | null;
  /**
   * Trip this catch is part of
   */
  trip: GetPostForCatchDetailsList_post_catch_trip | null;
  species: GetPostForCatchDetailsList_post_catch_species | null;
  fishingMethod: GetPostForCatchDetailsList_post_catch_fishingMethod | null;
}

export interface GetPostForCatchDetailsList_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetPostForCatchDetailsList_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPostForCatchDetailsList_post_images_edges_node | null;
}

export interface GetPostForCatchDetailsList_post_images {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPostForCatchDetailsList_post_images_edges | null)[] | null;
}

export interface GetPostForCatchDetailsList_post {
  __typename: "Post";
  _id: string;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetPostForCatchDetailsList_post_displayProductUnits;
  /**
   * The user that created this post
   */
  user: GetPostForCatchDetailsList_post_user;
  /**
   * Catch details on this post
   */
  catch: GetPostForCatchDetailsList_post_catch | null;
  images: GetPostForCatchDetailsList_post_images;
}

export interface GetPostForCatchDetailsList {
  /**
   * For fetching a single post
   */
  post: GetPostForCatchDetailsList_post;
}

export interface GetPostForCatchDetailsListVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPostExternalId
// ====================================================

export interface GetPostExternalId_entry {
  __typename: "Post";
  _id: string;
}

export interface GetPostExternalId {
  /**
   * For fetching a single post
   */
  entry: GetPostExternalId_entry;
}

export interface GetPostExternalIdVariables {
  id?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPostByExternalId
// ====================================================

export interface GetPostByExternalId_entry {
  __typename: "Post";
  externalId: string;
}

export interface GetPostByExternalId {
  /**
   * For fetching a single post
   */
  entry: GetPostByExternalId_entry;
}

export interface GetPostByExternalIdVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPostComments
// ====================================================

export interface GetPostComments_entry_comments_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
}

export interface GetPostComments_entry_comments_edges_node_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetPostComments_entry_comments_edges_node_user {
  __typename: "User";
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  avatar: GetPostComments_entry_comments_edges_node_user_avatar | null;
}

export interface GetPostComments_entry_comments_edges_node_text {
  __typename: "Text";
  text: string | null;
}

export interface GetPostComments_entry_comments_edges_node_comments_edges_node_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetPostComments_entry_comments_edges_node_comments_edges_node_user {
  __typename: "User";
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  avatar: GetPostComments_entry_comments_edges_node_comments_edges_node_user_avatar | null;
}

export interface GetPostComments_entry_comments_edges_node_comments_edges_node_text {
  __typename: "Text";
  text: string | null;
}

export interface GetPostComments_entry_comments_edges_node_comments_edges_node {
  __typename: "Post";
  _id: string;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * The user that created this post
   */
  user: GetPostComments_entry_comments_edges_node_comments_edges_node_user;
  /**
   * Text content in this post
   */
  text: GetPostComments_entry_comments_edges_node_comments_edges_node_text;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
}

export interface GetPostComments_entry_comments_edges_node_comments_edges {
  __typename: "PostEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPostComments_entry_comments_edges_node_comments_edges_node | null;
}

export interface GetPostComments_entry_comments_edges_node_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetPostComments_entry_comments_edges_node_comments_edges | null)[] | null;
}

export interface GetPostComments_entry_comments_edges_node {
  __typename: "Post";
  _id: string;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * The user that created this post
   */
  user: GetPostComments_entry_comments_edges_node_user;
  /**
   * Text content in this post
   */
  text: GetPostComments_entry_comments_edges_node_text;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Comments on this post
   */
  comments: GetPostComments_entry_comments_edges_node_comments;
}

export interface GetPostComments_entry_comments_edges {
  __typename: "PostEdge";
  /**
   * A cursor for use in pagination.
   */
  cursor: string;
  /**
   * The item at the end of the edge.
   */
  node: GetPostComments_entry_comments_edges_node | null;
}

export interface GetPostComments_entry_comments {
  __typename: "PostRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetPostComments_entry_comments_pageInfo;
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetPostComments_entry_comments_edges | null)[] | null;
}

export interface GetPostComments_entry {
  __typename: "Post";
  _id: string;
  /**
   * Comments on this post
   */
  comments: GetPostComments_entry_comments;
}

export interface GetPostComments {
  /**
   * For fetching a single post
   */
  entry: GetPostComments_entry;
}

export interface GetPostCommentsVariables {
  externalId?: string | null;
  first?: number | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPostLikers
// ====================================================

export interface GetPostLikers_post_likers_edges_node_Page {
  __typename: "Page" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
}

export interface GetPostLikers_post_likers_edges_node_User_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetPostLikers_post_likers_edges_node_User {
  __typename: "User";
  _id: string;
  firstName: string | null;
  lastName: string | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  avatar: GetPostLikers_post_likers_edges_node_User_avatar | null;
}

export type GetPostLikers_post_likers_edges_node = GetPostLikers_post_likers_edges_node_Page | GetPostLikers_post_likers_edges_node_User;

export interface GetPostLikers_post_likers_edges {
  __typename: "PostsFeedDisplayEntityEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetPostLikers_post_likers_edges_node | null;
}

export interface GetPostLikers_post_likers {
  __typename: "PostsFeedDisplayEntityRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetPostLikers_post_likers_edges | null)[] | null;
}

export interface GetPostLikers_post {
  __typename: "Post";
  _id: string;
  /**
   * Users and pages that likes this post
   */
  likers: GetPostLikers_post_likers;
}

export interface GetPostLikers {
  /**
   * For fetching a single post
   */
  post: GetPostLikers_post;
}

export interface GetPostLikersVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPostLikes
// ====================================================

export interface GetPostLikes_post {
  __typename: "Post";
  _id: string;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
}

export interface GetPostLikes {
  /**
   * For fetching a single post
   */
  post: GetPostLikes_post;
}

export interface GetPostLikesVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllCategories
// ====================================================

export interface GetAllCategories_categories_edges_node_ancestors {
  __typename: "Category";
  /**
   * Category ID
   */
  id: number;
  title: string;
}

export interface GetAllCategories_categories_edges_node {
  __typename: "Category";
  /**
   * Category ID
   */
  id: number;
  title: string;
  ancestors: GetAllCategories_categories_edges_node_ancestors[];
}

export interface GetAllCategories_categories_edges {
  __typename: "CategoryEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetAllCategories_categories_edges_node | null;
}

export interface GetAllCategories_categories {
  __typename: "CategoryRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetAllCategories_categories_edges | null)[] | null;
}

export interface GetAllCategories {
  /**
   * Equipment categories
   */
  categories: GetAllCategories_categories;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTopLevelCategories
// ====================================================

export interface GetTopLevelCategories_categories_edges_node {
  __typename: "Category";
  /**
   * Category ID
   */
  id: number;
  title: string;
}

export interface GetTopLevelCategories_categories_edges {
  __typename: "CategoryEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetTopLevelCategories_categories_edges_node | null;
}

export interface GetTopLevelCategories_categories {
  __typename: "CategoryRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetTopLevelCategories_categories_edges | null)[] | null;
}

export interface GetTopLevelCategories {
  /**
   * Equipment categories
   */
  categories: GetTopLevelCategories_categories;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProductCategoryName
// ====================================================

export interface GetProductCategoryName_category {
  __typename: "Category";
  /**
   * Category ID
   */
  id: number;
  title: string;
}

export interface GetProductCategoryName {
  /**
   * For fetching a single category (Equipment Category)
   */
  category: GetProductCategoryName_category;
}

export interface GetProductCategoryNameVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FilterProducts
// ====================================================

export interface FilterProducts_products_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface FilterProducts_products_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface FilterProducts_products_edges_node_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface FilterProducts_products_edges_node_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  averageRating: number;
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface FilterProducts_products_edges_node {
  __typename: "Product";
  _id: string;
  displayName: string;
  image: FilterProducts_products_edges_node_image | null;
  brand: FilterProducts_products_edges_node_brand;
  reviews: FilterProducts_products_edges_node_reviews | null;
}

export interface FilterProducts_products_edges {
  __typename: "ProductEdge";
  /**
   * The item at the end of the edge.
   */
  node: FilterProducts_products_edges_node | null;
}

export interface FilterProducts_products {
  __typename: "ProductRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: FilterProducts_products_pageInfo;
  /**
   * A list of edges.
   */
  edges: (FilterProducts_products_edges | null)[] | null;
}

export interface FilterProducts {
  /**
   * Equipment products, excluding legacy baits
   */
  products: FilterProducts_products;
}

export interface FilterProductsVariables {
  searchTerm?: string | null;
  filters?: ProductFilters | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProductUnits
// ====================================================

export interface GetProductUnits_entry_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetProductUnits_entry_units_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetProductUnits_entry_units_edges_node_attributeValues_edges_node_attribute {
  __typename: "Attribute";
  name: string;
}

export interface GetProductUnits_entry_units_edges_node_attributeValues_edges_node_value {
  __typename: "AttributeValue";
  name: string;
}

export interface GetProductUnits_entry_units_edges_node_attributeValues_edges_node {
  __typename: "UnitAttributeValue";
  attribute: GetProductUnits_entry_units_edges_node_attributeValues_edges_node_attribute | null;
  value: GetProductUnits_entry_units_edges_node_attributeValues_edges_node_value | null;
}

export interface GetProductUnits_entry_units_edges_node_attributeValues_edges {
  __typename: "UnitAttributeValueEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetProductUnits_entry_units_edges_node_attributeValues_edges_node | null;
}

export interface GetProductUnits_entry_units_edges_node_attributeValues {
  __typename: "UnitAttributeValueRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetProductUnits_entry_units_edges_node_attributeValues_edges | null)[] | null;
}

export interface GetProductUnits_entry_units_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Is this unit in the current user's tacklebox?
   */
  usedByCurrentUser: boolean;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetProductUnits_entry_units_edges_node_image | null;
  attributeValues: GetProductUnits_entry_units_edges_node_attributeValues;
}

export interface GetProductUnits_entry_units_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetProductUnits_entry_units_edges_node | null;
}

export interface GetProductUnits_entry_units {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetProductUnits_entry_units_edges | null)[] | null;
}

export interface GetProductUnits_entry {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetProductUnits_entry_brand;
  displayName: string;
  units: GetProductUnits_entry_units;
}

export interface GetProductUnits {
  /**
   * For fetching a single product
   */
  entry: GetProductUnits_entry;
}

export interface GetProductUnitsVariables {
  externalId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRegion
// ====================================================

export interface GetRegion_entry_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetRegion_entry_topFishingWaters_edges_node_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  totalAverage: number;
}

export interface GetRegion_entry_topFishingWaters_edges_node_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetRegion_entry_topFishingWaters_edges_node_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
}

export interface GetRegion_entry_topFishingWaters_edges_node {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  displayName: string;
  reviews: GetRegion_entry_topFishingWaters_edges_node_reviews | null;
  country: GetRegion_entry_topFishingWaters_edges_node_country | null;
  region: GetRegion_entry_topFishingWaters_edges_node_region | null;
  /**
   * The number of catches for this water
   */
  catchesCount: number;
}

export interface GetRegion_entry_topFishingWaters_edges {
  __typename: "FishingWaterEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetRegion_entry_topFishingWaters_edges_node | null;
}

export interface GetRegion_entry_topFishingWaters {
  __typename: "FishingWaterRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetRegion_entry_topFishingWaters_edges | null)[] | null;
}

export interface GetRegion_entry_places_edges_node_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetRegion_entry_places_edges_node_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  regionCode: string | null;
}

export interface GetRegion_entry_places_edges_node_unsplashImage {
  __typename: "UnsplashImage";
  /**
   * DB ID
   */
  id: number;
  /**
   * A URL for an image. If you use this make sure to read
   * the usage guidelines first -
   * https:                 // help.unsplash.com/en/articles/2511245-unsplash-api-guidelines
   * You can also apply transformations to the image by altering the URL. See here
   * for more info - https: // unsplash.com/documentation#dynamically-resizable-images
   */
  imageUrl: string | null;
}

export interface GetRegion_entry_places_edges_node {
  __typename: "Place";
  _id: string;
  name: string;
  slug: string | null;
  latitude: number;
  longitude: number;
  /**
   * The country this place belongs to
   */
  country: GetRegion_entry_places_edges_node_country;
  /**
   * The region this place belongs to
   */
  region: GetRegion_entry_places_edges_node_region;
  /**
   * An image for this place
   */
  unsplashImage: GetRegion_entry_places_edges_node_unsplashImage | null;
}

export interface GetRegion_entry_places_edges {
  __typename: "PlaceEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetRegion_entry_places_edges_node | null;
}

export interface GetRegion_entry_places {
  __typename: "PlaceRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetRegion_entry_places_edges | null)[] | null;
}

export interface GetRegion_entry {
  __typename: "Region";
  id: string | null;
  localName: string | null;
  country: GetRegion_entry_country;
  /**
   * The top fishing waters for the region
   */
  topFishingWaters: GetRegion_entry_topFishingWaters;
  places: GetRegion_entry_places;
}

export interface GetRegion {
  /**
   * For fetching a single region
   */
  entry: GetRegion_entry;
}

export interface GetRegionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWatersInRegionBySpecies
// ====================================================

export interface GetWatersInRegionBySpecies_entry_topFishingWaters_edges_node {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  /**
   * The number of catches for this water
   */
  catchesCount: number;
}

export interface GetWatersInRegionBySpecies_entry_topFishingWaters_edges {
  __typename: "FishingWaterEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetWatersInRegionBySpecies_entry_topFishingWaters_edges_node | null;
}

export interface GetWatersInRegionBySpecies_entry_topFishingWaters {
  __typename: "FishingWaterRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetWatersInRegionBySpecies_entry_topFishingWaters_edges | null)[] | null;
}

export interface GetWatersInRegionBySpecies_entry {
  __typename: "Region";
  id: string | null;
  /**
   * The top fishing waters for the region
   */
  topFishingWaters: GetWatersInRegionBySpecies_entry_topFishingWaters;
}

export interface GetWatersInRegionBySpecies {
  /**
   * For fetching a single region
   */
  entry: GetWatersInRegionBySpecies_entry;
}

export interface GetWatersInRegionBySpeciesVariables {
  id: string;
  speciesId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRegionName
// ====================================================

export interface GetRegionName_region_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetRegionName_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  country: GetRegionName_region_country;
}

export interface GetRegionName {
  /**
   * For fetching a single region
   */
  region: GetRegionName_region;
}

export interface GetRegionNameVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTopSpeciesNearLocation
// ====================================================

export interface GetTopSpeciesNearLocation_topSpeciesNearLocation_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage_post_images_edges_node {
  __typename: "Image";
  /**
   * Used to remove catch images using REST endpoint
   */
  id: number | null;
  width: number;
  height: number;
  url: string;
}

export interface GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage_post_images_edges_node | null;
}

export interface GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage_post_images {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage_post_images_edges | null)[] | null;
}

export interface GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage_post_webPImages_edges_node {
  __typename: "Image";
  /**
   * Used to remove catch images using REST endpoint
   */
  id: number | null;
  width: number;
  height: number;
  url: string;
}

export interface GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage_post_webPImages_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage_post_webPImages_edges_node | null;
}

export interface GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage_post_webPImages {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage_post_webPImages_edges | null)[] | null;
}

export interface GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage_post {
  __typename: "Post";
  _id: string;
  images: GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage_post_images;
  webPImages: GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage_post_webPImages;
}

export interface GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage {
  __typename: "Catch";
  _id: string;
  post: GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage_post;
}

export interface GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node {
  __typename: "Species";
  _id: string;
  externalId: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Default name for this species
   */
  displayName: string;
  /**
   * A curated catch or a popular one with an image from the last month
   */
  exampleCatchWithImage: GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node_exampleCatchWithImage | null;
}

export interface GetTopSpeciesNearLocation_topSpeciesNearLocation_edges {
  __typename: "TopSpeciesSummaryEdge";
  /**
   * The number of catches for this species
   */
  catchesCount: number;
  /**
   * The item at the end of the edge.
   */
  node: GetTopSpeciesNearLocation_topSpeciesNearLocation_edges_node | null;
}

export interface GetTopSpeciesNearLocation_topSpeciesNearLocation {
  __typename: "TopSpeciesConnection";
  /**
   * Total number of top species for this water
   */
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetTopSpeciesNearLocation_topSpeciesNearLocation_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetTopSpeciesNearLocation_topSpeciesNearLocation_edges | null)[] | null;
}

export interface GetTopSpeciesNearLocation {
  /**
   * The most caught species within a bounding box or radius from lat/long
   */
  topSpeciesNearLocation: GetTopSpeciesNearLocation_topSpeciesNearLocation;
}

export interface GetTopSpeciesNearLocationVariables {
  boundingBox?: BoundingBoxInputObject | null;
  boundingCircle?: BoundingCircleInputObject | null;
  excludingSpeciesIds?: string[] | null;
  first: number;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSpeciesTopBaits
// ====================================================

export interface GetSpeciesTopBaits_topProductUnits_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
}

export interface GetSpeciesTopBaits_topProductUnits_edges_node_productUnit_image {
  __typename: "Image";
  url: string;
}

export interface GetSpeciesTopBaits_topProductUnits_edges_node_productUnit_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetSpeciesTopBaits_topProductUnits_edges_node_productUnit_product_category {
  __typename: "Category";
  /**
   * Category ID
   */
  id: number;
  title: string;
}

export interface GetSpeciesTopBaits_topProductUnits_edges_node_productUnit_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetSpeciesTopBaits_topProductUnits_edges_node_productUnit_product_brand;
  displayName: string;
  category: GetSpeciesTopBaits_topProductUnits_edges_node_productUnit_product_category;
}

export interface GetSpeciesTopBaits_topProductUnits_edges_node_productUnit {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  image: GetSpeciesTopBaits_topProductUnits_edges_node_productUnit_image | null;
  product: GetSpeciesTopBaits_topProductUnits_edges_node_productUnit_product;
}

export interface GetSpeciesTopBaits_topProductUnits_edges_node {
  __typename: "topEquipmentProductUnit";
  /**
   * Number of catches with product unit
   */
  numberOfCatches: number;
  /**
   * Position of the product unit in the top listing
   */
  position: number;
  productUnit: GetSpeciesTopBaits_topProductUnits_edges_node_productUnit;
}

export interface GetSpeciesTopBaits_topProductUnits_edges {
  __typename: "topEquipmentProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSpeciesTopBaits_topProductUnits_edges_node | null;
}

export interface GetSpeciesTopBaits_topProductUnits {
  __typename: "topEquipmentProductUnitConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetSpeciesTopBaits_topProductUnits_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetSpeciesTopBaits_topProductUnits_edges | null)[] | null;
}

export interface GetSpeciesTopBaits {
  topProductUnits: GetSpeciesTopBaits_topProductUnits | null;
}

export interface GetSpeciesTopBaitsVariables {
  speciesId?: string | null;
  first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSingleSpecies
// ====================================================

export interface GetSingleSpecies_entry_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetSingleSpecies_entry_exampleCatchWithImage_post_catch_fishingWater | null;
  species: GetSingleSpecies_entry_exampleCatchWithImage_post_catch_species | null;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetSingleSpecies_entry_exampleCatchWithImage_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetSingleSpecies_entry_exampleCatchWithImage_post_productUnits_edges_node_image | null;
  product: GetSingleSpecies_entry_exampleCatchWithImage_post_productUnits_edges_node_product;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSingleSpecies_entry_exampleCatchWithImage_post_productUnits_edges_node | null;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetSingleSpecies_entry_exampleCatchWithImage_post_productUnits_edges | null)[] | null;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetSingleSpecies_entry_exampleCatchWithImage_post_video_screenshot;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSingleSpecies_entry_exampleCatchWithImage_post_images_edges_node | null;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetSingleSpecies_entry_exampleCatchWithImage_post_images_edges | null)[] | null;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_webPImages_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_webPImages_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSingleSpecies_entry_exampleCatchWithImage_post_webPImages_edges_node | null;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_webPImages {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetSingleSpecies_entry_exampleCatchWithImage_post_webPImages_edges | null)[] | null;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post_user {
  __typename: "User";
  avatar: GetSingleSpecies_entry_exampleCatchWithImage_post_user_avatar | null;
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  isPremium: boolean | null;
  /**
   * Date record was added to the database
   */
  createdAt: any;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage_post {
  __typename: "Post";
  _id: string;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetSingleSpecies_entry_exampleCatchWithImage_post_displayProductUnits;
  /**
   * Catch details on this post
   */
  catch: GetSingleSpecies_entry_exampleCatchWithImage_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetSingleSpecies_entry_exampleCatchWithImage_post_comments;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetSingleSpecies_entry_exampleCatchWithImage_post_productUnits;
  /**
   * Video in this post
   */
  video: GetSingleSpecies_entry_exampleCatchWithImage_post_video | null;
  images: GetSingleSpecies_entry_exampleCatchWithImage_post_images;
  webPImages: GetSingleSpecies_entry_exampleCatchWithImage_post_webPImages;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetSingleSpecies_entry_exampleCatchWithImage_post_text;
  /**
   * The user that created this post
   */
  user: GetSingleSpecies_entry_exampleCatchWithImage_post_user;
}

export interface GetSingleSpecies_entry_exampleCatchWithImage {
  __typename: "Catch";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  caughtAtGmt: any | null;
  species: GetSingleSpecies_entry_exampleCatchWithImage_species | null;
  post: GetSingleSpecies_entry_exampleCatchWithImage_post;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetSingleSpecies_entry_catchesWithImages_edges_node_post_catch_fishingWater | null;
  species: GetSingleSpecies_entry_catchesWithImages_edges_node_post_catch_species | null;
  caughtAtGmt: any | null;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetSingleSpecies_entry_catchesWithImages_edges_node_post_displayEntity_displayIcon;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetSingleSpecies_entry_catchesWithImages_edges_node_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetSingleSpecies_entry_catchesWithImages_edges_node_post_productUnits_edges_node_image | null;
  product: GetSingleSpecies_entry_catchesWithImages_edges_node_post_productUnits_edges_node_product;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSingleSpecies_entry_catchesWithImages_edges_node_post_productUnits_edges_node | null;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetSingleSpecies_entry_catchesWithImages_edges_node_post_productUnits_edges | null)[] | null;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetSingleSpecies_entry_catchesWithImages_edges_node_post_video_screenshot;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSingleSpecies_entry_catchesWithImages_edges_node_post_images_edges_node | null;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetSingleSpecies_entry_catchesWithImages_edges_node_post_images_edges | null)[] | null;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post_user {
  __typename: "User";
  _id: string;
  avatar: GetSingleSpecies_entry_catchesWithImages_edges_node_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: GetSingleSpecies_entry_catchesWithImages_edges_node_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetSingleSpecies_entry_catchesWithImages_edges_node_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetSingleSpecies_entry_catchesWithImages_edges_node_post_displayProductUnits;
  displayEntity: GetSingleSpecies_entry_catchesWithImages_edges_node_post_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetSingleSpecies_entry_catchesWithImages_edges_node_post_productUnits;
  /**
   * Video in this post
   */
  video: GetSingleSpecies_entry_catchesWithImages_edges_node_post_video | null;
  images: GetSingleSpecies_entry_catchesWithImages_edges_node_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetSingleSpecies_entry_catchesWithImages_edges_node_post_text;
  /**
   * The user that created this post
   */
  user: GetSingleSpecies_entry_catchesWithImages_edges_node_post_user;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges_node {
  __typename: "Catch";
  _id: string;
  post: GetSingleSpecies_entry_catchesWithImages_edges_node_post;
}

export interface GetSingleSpecies_entry_catchesWithImages_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSingleSpecies_entry_catchesWithImages_edges_node | null;
}

export interface GetSingleSpecies_entry_catchesWithImages {
  __typename: "CatchRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetSingleSpecies_entry_catchesWithImages_edges | null)[] | null;
}

export interface GetSingleSpecies_entry_followers {
  __typename: "UserFollowersConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetSingleSpecies_entry_speciesTips_edges_node_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetSingleSpecies_entry_speciesTips_edges_node_user {
  __typename: "User";
  _id: string;
  avatar: GetSingleSpecies_entry_speciesTips_edges_node_user_avatar | null;
}

export interface GetSingleSpecies_entry_speciesTips_edges_node {
  __typename: "SpeciesTip";
  externalId: string;
  tip: string;
  userDescription: string | null;
  /**
   * If set this will use the custom display name for the user, if not the user's full name will be returned.
   */
  userDisplayName: string;
  /**
   * Creator of the tip
   */
  user: GetSingleSpecies_entry_speciesTips_edges_node_user;
}

export interface GetSingleSpecies_entry_speciesTips_edges {
  __typename: "SpeciesTipEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSingleSpecies_entry_speciesTips_edges_node | null;
}

export interface GetSingleSpecies_entry_speciesTips {
  __typename: "SpeciesTipRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetSingleSpecies_entry_speciesTips_edges | null)[] | null;
}

export interface GetSingleSpecies_entry {
  __typename: "Species";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * If the current user follows this species
   */
  followedByCurrentUser: boolean;
  latinName: string;
  /**
   * Default name for this species
   */
  displayName: string;
  /**
   * A short description of the species
   */
  description: string | null;
  /**
   * The maximum length of this species (in metres)
   */
  maxLength: number;
  maxWeight: number;
  /**
   * IUCN category code (https: // en.wikipedia.org/wiki/IUCN_Red_List#Categories)
   */
  iucnStatus: string | null;
  /**
   * A list of threats to humans that this species poses
   */
  threatToHumans: string[] | null;
  /**
   * The types of water (saltwater, freshwater, mixed) this species can be found in
   */
  waterTypes: string[];
  /**
   * The min common depth of this species (in metres)
   */
  minCommonDepth: number | null;
  /**
   * The max common depth of this species (in metres)
   */
  maxCommonDepth: number | null;
  catches: GetSingleSpecies_entry_catches;
  /**
   * A curated catch or a popular one with an image from the last month
   */
  exampleCatchWithImage: GetSingleSpecies_entry_exampleCatchWithImage | null;
  catchesWithImages: GetSingleSpecies_entry_catchesWithImages;
  /**
   * Users who follow this species
   */
  followers: GetSingleSpecies_entry_followers;
  speciesTips: GetSingleSpecies_entry_speciesTips;
}

export interface GetSingleSpecies {
  /**
   * For fetching a single single species
   */
  entry: GetSingleSpecies_entry;
}

export interface GetSingleSpeciesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSingleSpeciesName
// ====================================================

export interface GetSingleSpeciesName_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetSingleSpeciesName {
  /**
   * For fetching a single single species
   */
  species: GetSingleSpeciesName_species;
}

export interface GetSingleSpeciesNameVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSpeciesSuggestedWater
// ====================================================

export interface GetSpeciesSuggestedWater_entry_suggestedWaters_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetSpeciesSuggestedWater_entry_suggestedWaters_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
}

export interface GetSpeciesSuggestedWater_entry_suggestedWaters_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetSpeciesSuggestedWater_entry_suggestedWaters {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  country: GetSpeciesSuggestedWater_entry_suggestedWaters_country | null;
  region: GetSpeciesSuggestedWater_entry_suggestedWaters_region | null;
  displayName: string;
  catches: GetSpeciesSuggestedWater_entry_suggestedWaters_catches;
}

export interface GetSpeciesSuggestedWater_entry {
  __typename: "Species";
  _id: string;
  /**
   * Suggested waters for the species, based on distance and catch count
   */
  suggestedWaters: GetSpeciesSuggestedWater_entry_suggestedWaters[];
}

export interface GetSpeciesSuggestedWater {
  /**
   * For fetching a single single species
   */
  entry: GetSpeciesSuggestedWater_entry;
}

export interface GetSpeciesSuggestedWaterVariables {
  id: string;
  latitude: number;
  longitude: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBiteTimePreview
// ====================================================

export interface GetBiteTimePreview_speciesForecastPreview {
  __typename: "Reading";
  /**
   * The rating based on the value of this reading (e.g. poor < 0.25 value)
   */
  rating: string;
  /**
   * The date of this reading
   */
  readingAt: any;
}

export interface GetBiteTimePreview {
  /**
   * A preview of the best condition to catch this single species. Null means not enough data
   */
  speciesForecastPreview: GetBiteTimePreview_speciesForecastPreview | null;
}

export interface GetBiteTimePreviewVariables {
  id: string;
  latitude: number;
  longitude: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSpeciesImages
// ====================================================

export interface GetSpeciesImages_species_edges_node_image {
  __typename: "Image";
  url: string;
}

export interface GetSpeciesImages_species_edges_node_webPImage {
  __typename: "Image";
  url: string;
}

export interface GetSpeciesImages_species_edges_node {
  __typename: "Species";
  _id: string;
  imageCopyrightStatus: string | null;
  imageCopyrightExpiryDate: any | null;
  image: GetSpeciesImages_species_edges_node_image;
  webPImage: GetSpeciesImages_species_edges_node_webPImage;
}

export interface GetSpeciesImages_species_edges {
  __typename: "SpeciesEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSpeciesImages_species_edges_node | null;
}

export interface GetSpeciesImages_species {
  __typename: "SpeciesRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetSpeciesImages_species_edges | null)[] | null;
}

export interface GetSpeciesImages {
  species: GetSpeciesImages_species;
}

export interface GetSpeciesImagesVariables {
  ids?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSpeciesFromList
// ====================================================

export interface GetSpeciesFromList_species_edges_node_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetSpeciesFromList_species_edges_node_exampleCatchWithImage_post_images_edges_node {
  __typename: "Image";
  /**
   * Used to remove catch images using REST endpoint
   */
  id: number | null;
  width: number;
  height: number;
  url: string;
}

export interface GetSpeciesFromList_species_edges_node_exampleCatchWithImage_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSpeciesFromList_species_edges_node_exampleCatchWithImage_post_images_edges_node | null;
}

export interface GetSpeciesFromList_species_edges_node_exampleCatchWithImage_post_images {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetSpeciesFromList_species_edges_node_exampleCatchWithImage_post_images_edges | null)[] | null;
}

export interface GetSpeciesFromList_species_edges_node_exampleCatchWithImage_post_webPImages_edges_node {
  __typename: "Image";
  /**
   * Used to remove catch images using REST endpoint
   */
  id: number | null;
  width: number;
  height: number;
  url: string;
}

export interface GetSpeciesFromList_species_edges_node_exampleCatchWithImage_post_webPImages_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSpeciesFromList_species_edges_node_exampleCatchWithImage_post_webPImages_edges_node | null;
}

export interface GetSpeciesFromList_species_edges_node_exampleCatchWithImage_post_webPImages {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetSpeciesFromList_species_edges_node_exampleCatchWithImage_post_webPImages_edges | null)[] | null;
}

export interface GetSpeciesFromList_species_edges_node_exampleCatchWithImage_post {
  __typename: "Post";
  _id: string;
  images: GetSpeciesFromList_species_edges_node_exampleCatchWithImage_post_images;
  webPImages: GetSpeciesFromList_species_edges_node_exampleCatchWithImage_post_webPImages;
}

export interface GetSpeciesFromList_species_edges_node_exampleCatchWithImage {
  __typename: "Catch";
  _id: string;
  post: GetSpeciesFromList_species_edges_node_exampleCatchWithImage_post;
}

export interface GetSpeciesFromList_species_edges_node {
  __typename: "Species";
  _id: string;
  externalId: string;
  /**
   * Default name for this species
   */
  displayName: string;
  catches: GetSpeciesFromList_species_edges_node_catches;
  /**
   * A curated catch or a popular one with an image from the last month
   */
  exampleCatchWithImage: GetSpeciesFromList_species_edges_node_exampleCatchWithImage | null;
}

export interface GetSpeciesFromList_species_edges {
  __typename: "SpeciesEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetSpeciesFromList_species_edges_node | null;
}

export interface GetSpeciesFromList_species {
  __typename: "SpeciesRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetSpeciesFromList_species_edges | null)[] | null;
}

export interface GetSpeciesFromList {
  species: GetSpeciesFromList_species;
}

export interface GetSpeciesFromListVariables {
  externalIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMostActiveWaters
// ====================================================

export interface GetMostActiveWaters_statistics_mostActiveWatersLastWeek_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  totalAverage: number;
}

export interface GetMostActiveWaters_statistics_mostActiveWatersLastWeek_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetMostActiveWaters_statistics_mostActiveWatersLastWeek_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
}

export interface GetMostActiveWaters_statistics_mostActiveWatersLastWeek {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  displayName: string;
  reviews: GetMostActiveWaters_statistics_mostActiveWatersLastWeek_reviews | null;
  country: GetMostActiveWaters_statistics_mostActiveWatersLastWeek_country | null;
  region: GetMostActiveWaters_statistics_mostActiveWatersLastWeek_region | null;
  /**
   * The number of catches for this water
   */
  catchesCount: number;
}

export interface GetMostActiveWaters_statistics_mostActiveWaters_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  totalAverage: number;
}

export interface GetMostActiveWaters_statistics_mostActiveWaters_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface GetMostActiveWaters_statistics_mostActiveWaters_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
}

export interface GetMostActiveWaters_statistics_mostActiveWaters {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  displayName: string;
  reviews: GetMostActiveWaters_statistics_mostActiveWaters_reviews | null;
  country: GetMostActiveWaters_statistics_mostActiveWaters_country | null;
  region: GetMostActiveWaters_statistics_mostActiveWaters_region | null;
  /**
   * The number of catches for this water
   */
  catchesCount: number;
}

export interface GetMostActiveWaters_statistics {
  __typename: "Statistics";
  /**
   * The fishing waters with the most catches. Cached once a week.
   */
  mostActiveWatersLastWeek: GetMostActiveWaters_statistics_mostActiveWatersLastWeek[];
  /**
   * The fishing waters with the most catches. Cached once a week.
   */
  mostActiveWaters: GetMostActiveWaters_statistics_mostActiveWaters[];
}

export interface GetMostActiveWaters {
  statistics: GetMostActiveWaters_statistics;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTag
// ====================================================

export interface GetTag_entry_posts_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetTag_entry_posts_edges_node_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetTag_entry_posts_edges_node_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetTag_entry_posts_edges_node_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetTag_entry_posts_edges_node_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetTag_entry_posts_edges_node_catch_fishingWater | null;
  species: GetTag_entry_posts_edges_node_catch_species | null;
}

export interface GetTag_entry_posts_edges_node_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetTag_entry_posts_edges_node_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetTag_entry_posts_edges_node_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetTag_entry_posts_edges_node_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetTag_entry_posts_edges_node_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetTag_entry_posts_edges_node_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetTag_entry_posts_edges_node_productUnits_edges_node_image | null;
  product: GetTag_entry_posts_edges_node_productUnits_edges_node_product;
}

export interface GetTag_entry_posts_edges_node_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetTag_entry_posts_edges_node_productUnits_edges_node | null;
}

export interface GetTag_entry_posts_edges_node_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetTag_entry_posts_edges_node_productUnits_edges | null)[] | null;
}

export interface GetTag_entry_posts_edges_node_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetTag_entry_posts_edges_node_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetTag_entry_posts_edges_node_video_screenshot;
}

export interface GetTag_entry_posts_edges_node_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetTag_entry_posts_edges_node_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetTag_entry_posts_edges_node_images_edges_node | null;
}

export interface GetTag_entry_posts_edges_node_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetTag_entry_posts_edges_node_images_edges | null)[] | null;
}

export interface GetTag_entry_posts_edges_node_text {
  __typename: "Text";
  text: string | null;
}

export interface GetTag_entry_posts_edges_node_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetTag_entry_posts_edges_node_user {
  __typename: "User";
  _id: string;
  avatar: GetTag_entry_posts_edges_node_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetTag_entry_posts_edges_node {
  __typename: "Post";
  _id: string;
  /**
   * Comments on this post
   */
  comments: GetTag_entry_posts_edges_node_comments;
  /**
   * Catch details on this post
   */
  catch: GetTag_entry_posts_edges_node_catch | null;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetTag_entry_posts_edges_node_displayProductUnits;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetTag_entry_posts_edges_node_productUnits;
  /**
   * Video in this post
   */
  video: GetTag_entry_posts_edges_node_video | null;
  images: GetTag_entry_posts_edges_node_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetTag_entry_posts_edges_node_text;
  /**
   * The user that created this post
   */
  user: GetTag_entry_posts_edges_node_user;
}

export interface GetTag_entry_posts_edges {
  __typename: "PostEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetTag_entry_posts_edges_node | null;
}

export interface GetTag_entry_posts {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetTag_entry_posts_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetTag_entry_posts_edges | null)[] | null;
}

export interface GetTag_entry {
  __typename: "Tag";
  id: string;
  posts: GetTag_entry_posts;
}

export interface GetTag {
  entry: GetTag_entry | null;
}

export interface GetTagVariables {
  label: string;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTrip
// ====================================================

export interface GetTrip_trip_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetTrip_trip_post_comments_edges_node_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetTrip_trip_post_comments_edges_node_user {
  __typename: "User";
  _id: string;
  avatar: GetTrip_trip_post_comments_edges_node_user_avatar | null;
  isPremium: boolean | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetTrip_trip_post_comments_edges_node_text {
  __typename: "Text";
  text: string | null;
}

export interface GetTrip_trip_post_comments_edges_node {
  __typename: "Post";
  _id: string;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * The user that created this post
   */
  user: GetTrip_trip_post_comments_edges_node_user;
  /**
   * The title of the post (`title` might be needed later to return the Title object)
   */
  titleString: string | null;
  /**
   * Text content in this post
   */
  text: GetTrip_trip_post_comments_edges_node_text;
}

export interface GetTrip_trip_post_comments_edges {
  __typename: "PostEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetTrip_trip_post_comments_edges_node | null;
}

export interface GetTrip_trip_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetTrip_trip_post_comments_edges | null)[] | null;
}

export interface GetTrip_trip_post {
  __typename: "Post";
  _id: string;
  /**
   * Text content in this post
   */
  text: GetTrip_trip_post_text;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Comments on this post
   */
  comments: GetTrip_trip_post_comments;
}

export interface GetTrip_trip_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetTrip_trip_user_catches_edges_node_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetTrip_trip_user_catches_edges_node_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetTrip_trip_user_catches_edges_node_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetTrip_trip_user_catches_edges_node_post_catch_fishingWater | null;
  species: GetTrip_trip_user_catches_edges_node_post_catch_species | null;
  caughtAtGmt: any | null;
}

export interface GetTrip_trip_user_catches_edges_node_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetTrip_trip_user_catches_edges_node_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetTrip_trip_user_catches_edges_node_post_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetTrip_trip_user_catches_edges_node_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetTrip_trip_user_catches_edges_node_post_displayEntity_displayIcon;
}

export interface GetTrip_trip_user_catches_edges_node_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetTrip_trip_user_catches_edges_node_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetTrip_trip_user_catches_edges_node_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetTrip_trip_user_catches_edges_node_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetTrip_trip_user_catches_edges_node_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetTrip_trip_user_catches_edges_node_post_productUnits_edges_node_image | null;
  product: GetTrip_trip_user_catches_edges_node_post_productUnits_edges_node_product;
}

export interface GetTrip_trip_user_catches_edges_node_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetTrip_trip_user_catches_edges_node_post_productUnits_edges_node | null;
}

export interface GetTrip_trip_user_catches_edges_node_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetTrip_trip_user_catches_edges_node_post_productUnits_edges | null)[] | null;
}

export interface GetTrip_trip_user_catches_edges_node_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetTrip_trip_user_catches_edges_node_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetTrip_trip_user_catches_edges_node_post_video_screenshot;
}

export interface GetTrip_trip_user_catches_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetTrip_trip_user_catches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetTrip_trip_user_catches_edges_node_post_images_edges_node | null;
}

export interface GetTrip_trip_user_catches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetTrip_trip_user_catches_edges_node_post_images_edges | null)[] | null;
}

export interface GetTrip_trip_user_catches_edges_node_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetTrip_trip_user_catches_edges_node_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetTrip_trip_user_catches_edges_node_post_user {
  __typename: "User";
  _id: string;
  avatar: GetTrip_trip_user_catches_edges_node_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetTrip_trip_user_catches_edges_node_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: GetTrip_trip_user_catches_edges_node_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetTrip_trip_user_catches_edges_node_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetTrip_trip_user_catches_edges_node_post_displayProductUnits;
  displayEntity: GetTrip_trip_user_catches_edges_node_post_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetTrip_trip_user_catches_edges_node_post_productUnits;
  /**
   * Video in this post
   */
  video: GetTrip_trip_user_catches_edges_node_post_video | null;
  images: GetTrip_trip_user_catches_edges_node_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetTrip_trip_user_catches_edges_node_post_text;
  /**
   * The user that created this post
   */
  user: GetTrip_trip_user_catches_edges_node_post_user;
}

export interface GetTrip_trip_user_catches_edges_node {
  __typename: "Catch";
  _id: string;
  post: GetTrip_trip_user_catches_edges_node_post;
}

export interface GetTrip_trip_user_catches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetTrip_trip_user_catches_edges_node | null;
}

export interface GetTrip_trip_user_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetTrip_trip_user_catches_edges | null)[] | null;
}

export interface GetTrip_trip_user {
  __typename: "User";
  _id: string;
  avatar: GetTrip_trip_user_avatar | null;
  isPremium: boolean | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  firstName: string | null;
  lastName: string | null;
  /**
   * Date record was added to the database
   */
  createdAt: any;
  catches: GetTrip_trip_user_catches;
}

export interface GetTrip_trip_catches_edges_node_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetTrip_trip_catches_edges_node_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetTrip_trip_catches_edges_node_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetTrip_trip_catches_edges_node_post_catch_fishingWater | null;
  species: GetTrip_trip_catches_edges_node_post_catch_species | null;
  caughtAtGmt: any | null;
}

export interface GetTrip_trip_catches_edges_node_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetTrip_trip_catches_edges_node_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetTrip_trip_catches_edges_node_post_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetTrip_trip_catches_edges_node_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetTrip_trip_catches_edges_node_post_displayEntity_displayIcon;
}

export interface GetTrip_trip_catches_edges_node_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetTrip_trip_catches_edges_node_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetTrip_trip_catches_edges_node_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetTrip_trip_catches_edges_node_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetTrip_trip_catches_edges_node_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetTrip_trip_catches_edges_node_post_productUnits_edges_node_image | null;
  product: GetTrip_trip_catches_edges_node_post_productUnits_edges_node_product;
}

export interface GetTrip_trip_catches_edges_node_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetTrip_trip_catches_edges_node_post_productUnits_edges_node | null;
}

export interface GetTrip_trip_catches_edges_node_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetTrip_trip_catches_edges_node_post_productUnits_edges | null)[] | null;
}

export interface GetTrip_trip_catches_edges_node_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetTrip_trip_catches_edges_node_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetTrip_trip_catches_edges_node_post_video_screenshot;
}

export interface GetTrip_trip_catches_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetTrip_trip_catches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetTrip_trip_catches_edges_node_post_images_edges_node | null;
}

export interface GetTrip_trip_catches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetTrip_trip_catches_edges_node_post_images_edges | null)[] | null;
}

export interface GetTrip_trip_catches_edges_node_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetTrip_trip_catches_edges_node_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetTrip_trip_catches_edges_node_post_user {
  __typename: "User";
  _id: string;
  avatar: GetTrip_trip_catches_edges_node_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetTrip_trip_catches_edges_node_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: GetTrip_trip_catches_edges_node_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetTrip_trip_catches_edges_node_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetTrip_trip_catches_edges_node_post_displayProductUnits;
  displayEntity: GetTrip_trip_catches_edges_node_post_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetTrip_trip_catches_edges_node_post_productUnits;
  /**
   * Video in this post
   */
  video: GetTrip_trip_catches_edges_node_post_video | null;
  images: GetTrip_trip_catches_edges_node_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetTrip_trip_catches_edges_node_post_text;
  /**
   * The user that created this post
   */
  user: GetTrip_trip_catches_edges_node_post_user;
}

export interface GetTrip_trip_catches_edges_node {
  __typename: "Catch";
  _id: string;
  post: GetTrip_trip_catches_edges_node_post;
}

export interface GetTrip_trip_catches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetTrip_trip_catches_edges_node | null;
}

export interface GetTrip_trip_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetTrip_trip_catches_edges | null)[] | null;
}

export interface GetTrip_trip_publicCatches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetTrip_trip {
  __typename: "Trip";
  id: string;
  startedAt: any;
  endedAt: any;
  /**
   * Date record was added to the database
   */
  createdAt: any;
  post: GetTrip_trip_post;
  user: GetTrip_trip_user;
  catches: GetTrip_trip_catches;
  publicCatches: GetTrip_trip_publicCatches;
}

export interface GetTrip {
  /**
   * For fetching a single trip
   */
  trip: GetTrip_trip;
}

export interface GetTripVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTripMapImages
// ====================================================

export interface GetTripMapImages_trip_mobileImage {
  __typename: "CatchesMapImage";
  height: number;
  width: number;
  /**
   * The url of the image
   */
  url: string;
}

export interface GetTripMapImages_trip_desktopImage {
  __typename: "CatchesMapImage";
  height: number;
  width: number;
  /**
   * The url of the image
   */
  url: string;
}

export interface GetTripMapImages_trip {
  __typename: "Trip";
  id: string;
  /**
   * Returns null if user is not owner/Pro or if no catches are public.
   */
  mobileImage: GetTripMapImages_trip_mobileImage | null;
  /**
   * Returns null if user is not owner/Pro or if no catches are public.
   */
  desktopImage: GetTripMapImages_trip_desktopImage | null;
}

export interface GetTripMapImages {
  /**
   * For fetching a single trip
   */
  trip: GetTripMapImages_trip;
}

export interface GetTripMapImagesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProfile
// ====================================================

export interface GetProfile_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetProfile_user_coverImage {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetProfile_user_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetProfile_user {
  __typename: "User";
  _id: string;
  avatar: GetProfile_user_avatar | null;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  firstName: string | null;
  lastName: string | null;
  isPremium: boolean | null;
  biography: string | null;
  coverImage: GetProfile_user_coverImage | null;
  countryCode: string;
  catches: GetProfile_user_catches;
}

export interface GetProfile {
  /**
   * A user in the app
   */
  user: GetProfile_user;
}

export interface GetProfileVariables {
  nickname: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserIsFollowed
// ====================================================

export interface GetUserIsFollowed_user {
  __typename: "User";
  /**
   * Is this user followed by the current user?
   */
  followedByCurrentUser: boolean;
}

export interface GetUserIsFollowed {
  /**
   * A user in the app
   */
  user: GetUserIsFollowed_user;
}

export interface GetUserIsFollowedVariables {
  nickname: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsersCatches
// ====================================================

export interface GetUsersCatches_user_catches_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetUsersCatches_user_catches_edges_node_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetUsersCatches_user_catches_edges_node_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface GetUsersCatches_user_catches_edges_node_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: GetUsersCatches_user_catches_edges_node_post_catch_fishingWater | null;
  species: GetUsersCatches_user_catches_edges_node_post_catch_species | null;
  caughtAtGmt: any | null;
}

export interface GetUsersCatches_user_catches_edges_node_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetUsersCatches_user_catches_edges_node_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetUsersCatches_user_catches_edges_node_post_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUsersCatches_user_catches_edges_node_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetUsersCatches_user_catches_edges_node_post_displayEntity_displayIcon;
}

export interface GetUsersCatches_user_catches_edges_node_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUsersCatches_user_catches_edges_node_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetUsersCatches_user_catches_edges_node_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetUsersCatches_user_catches_edges_node_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetUsersCatches_user_catches_edges_node_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetUsersCatches_user_catches_edges_node_post_productUnits_edges_node_image | null;
  product: GetUsersCatches_user_catches_edges_node_post_productUnits_edges_node_product;
}

export interface GetUsersCatches_user_catches_edges_node_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUsersCatches_user_catches_edges_node_post_productUnits_edges_node | null;
}

export interface GetUsersCatches_user_catches_edges_node_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetUsersCatches_user_catches_edges_node_post_productUnits_edges | null)[] | null;
}

export interface GetUsersCatches_user_catches_edges_node_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUsersCatches_user_catches_edges_node_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetUsersCatches_user_catches_edges_node_post_video_screenshot;
}

export interface GetUsersCatches_user_catches_edges_node_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUsersCatches_user_catches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUsersCatches_user_catches_edges_node_post_images_edges_node | null;
}

export interface GetUsersCatches_user_catches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetUsersCatches_user_catches_edges_node_post_images_edges | null)[] | null;
}

export interface GetUsersCatches_user_catches_edges_node_post_text {
  __typename: "Text";
  text: string | null;
}

export interface GetUsersCatches_user_catches_edges_node_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetUsersCatches_user_catches_edges_node_post_user {
  __typename: "User";
  _id: string;
  avatar: GetUsersCatches_user_catches_edges_node_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetUsersCatches_user_catches_edges_node_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: GetUsersCatches_user_catches_edges_node_post_catch | null;
  /**
   * Comments on this post
   */
  comments: GetUsersCatches_user_catches_edges_node_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetUsersCatches_user_catches_edges_node_post_displayProductUnits;
  displayEntity: GetUsersCatches_user_catches_edges_node_post_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetUsersCatches_user_catches_edges_node_post_productUnits;
  /**
   * Video in this post
   */
  video: GetUsersCatches_user_catches_edges_node_post_video | null;
  images: GetUsersCatches_user_catches_edges_node_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: GetUsersCatches_user_catches_edges_node_post_text;
  /**
   * The user that created this post
   */
  user: GetUsersCatches_user_catches_edges_node_post_user;
}

export interface GetUsersCatches_user_catches_edges_node {
  __typename: "Catch";
  _id: string;
  post: GetUsersCatches_user_catches_edges_node_post;
}

export interface GetUsersCatches_user_catches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUsersCatches_user_catches_edges_node | null;
}

export interface GetUsersCatches_user_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetUsersCatches_user_catches_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetUsersCatches_user_catches_edges | null)[] | null;
}

export interface GetUsersCatches_user {
  __typename: "User";
  _id: string;
  catches: GetUsersCatches_user_catches;
}

export interface GetUsersCatches {
  /**
   * A user in the app
   */
  user: GetUsersCatches_user;
}

export interface GetUsersCatchesVariables {
  nickname: string;
  after?: string | null;
  first?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsersPosts
// ====================================================

export interface GetUsersPosts_user_posts_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating backwards, the cursor to continue.
   */
  startCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  endCursor: string | null;
}

export interface GetUsersPosts_user_posts_edges_node_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUsersPosts_user_posts_edges_node_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: GetUsersPosts_user_posts_edges_node_displayEntity_displayIcon;
}

export interface GetUsersPosts_user_posts_edges_node_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
}

export interface GetUsersPosts_user_posts_edges_node_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUsersPosts_user_posts_edges_node_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: GetUsersPosts_user_posts_edges_node_video_screenshot;
}

export interface GetUsersPosts_user_posts_edges_node_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetUsersPosts_user_posts_edges_node_likers {
  __typename: "PostsFeedDisplayEntityRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetUsersPosts_user_posts_edges_node_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetUsersPosts_user_posts_edges_node_user {
  __typename: "User";
  _id: string;
  avatar: GetUsersPosts_user_posts_edges_node_user_avatar | null;
  isPremium: boolean | null;
  firstName: string | null;
  lastName: string | null;
  /**
   * Date record was added to the database
   */
  createdAt: any;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetUsersPosts_user_posts_edges_node_trip_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetUsersPosts_user_posts_edges_node_trip {
  __typename: "Trip";
  id: string;
  /**
   * Date record was added to the database
   */
  createdAt: any;
  catches: GetUsersPosts_user_posts_edges_node_trip_catches;
  startedAt: any;
  endedAt: any;
}

export interface GetUsersPosts_user_posts_edges_node_catch_fishingMethod {
  __typename: "FishingMethod";
  _id: string;
  displayName: string;
}

export interface GetUsersPosts_user_posts_edges_node_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface GetUsersPosts_user_posts_edges_node_catch_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUsersPosts_user_posts_edges_node_catch_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetUsersPosts_user_posts_edges_node_catch_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetUsersPosts_user_posts_edges_node_catch_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetUsersPosts_user_posts_edges_node_catch_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetUsersPosts_user_posts_edges_node_catch_productUnits_edges_node_image | null;
  product: GetUsersPosts_user_posts_edges_node_catch_productUnits_edges_node_product;
}

export interface GetUsersPosts_user_posts_edges_node_catch_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUsersPosts_user_posts_edges_node_catch_productUnits_edges_node | null;
}

export interface GetUsersPosts_user_posts_edges_node_catch_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetUsersPosts_user_posts_edges_node_catch_productUnits_edges | null)[] | null;
}

export interface GetUsersPosts_user_posts_edges_node_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
  /**
   * DB ID
   */
  legacyId: number;
}

export interface GetUsersPosts_user_posts_edges_node_catch_trip_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetUsersPosts_user_posts_edges_node_catch_trip {
  __typename: "Trip";
  id: string;
  catches: GetUsersPosts_user_posts_edges_node_catch_trip_catches;
}

export interface GetUsersPosts_user_posts_edges_node_catch {
  __typename: "Catch";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  fishingMethod: GetUsersPosts_user_posts_edges_node_catch_fishingMethod | null;
  fishingWater: GetUsersPosts_user_posts_edges_node_catch_fishingWater | null;
  productUnits: GetUsersPosts_user_posts_edges_node_catch_productUnits;
  isPersonalBest: boolean;
  /**
   * User-specified weight of the catch in kg
   */
  weight: number | null;
  /**
   * User-specified length of the catch in m
   */
  length: number | null;
  catchAndRelease: boolean | null;
  caughtAtLocalTimeZone: any | null;
  caughtAtGmt: any | null;
  hasExactPosition: boolean;
  /**
   * Level of publicity the catch owner has given to the catch location
   */
  locationPrivacy: CatchLocationPrivacyTypes;
  species: GetUsersPosts_user_posts_edges_node_catch_species | null;
  /**
   * Trip this catch is part of
   */
  trip: GetUsersPosts_user_posts_edges_node_catch_trip | null;
}

export interface GetUsersPosts_user_posts_edges_node_text {
  __typename: "Text";
  text: string | null;
}

export interface GetUsersPosts_user_posts_edges_node_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUsersPosts_user_posts_edges_node_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface GetUsersPosts_user_posts_edges_node_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: GetUsersPosts_user_posts_edges_node_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface GetUsersPosts_user_posts_edges_node_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: GetUsersPosts_user_posts_edges_node_productUnits_edges_node_image | null;
  product: GetUsersPosts_user_posts_edges_node_productUnits_edges_node_product;
}

export interface GetUsersPosts_user_posts_edges_node_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUsersPosts_user_posts_edges_node_productUnits_edges_node | null;
}

export interface GetUsersPosts_user_posts_edges_node_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (GetUsersPosts_user_posts_edges_node_productUnits_edges | null)[] | null;
}

export interface GetUsersPosts_user_posts_edges_node_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface GetUsersPosts_user_posts_edges_node_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetUsersPosts_user_posts_edges_node_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUsersPosts_user_posts_edges_node_images_edges_node | null;
}

export interface GetUsersPosts_user_posts_edges_node_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (GetUsersPosts_user_posts_edges_node_images_edges | null)[] | null;
}

export interface GetUsersPosts_user_posts_edges_node {
  __typename: "Post";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Date the post was published
   */
  publishedAt: any | null;
  displayEntity: GetUsersPosts_user_posts_edges_node_displayEntity;
  /**
   * A fishing water associated with this post
   */
  fishingWater: GetUsersPosts_user_posts_edges_node_fishingWater | null;
  /**
   * Video in this post
   */
  video: GetUsersPosts_user_posts_edges_node_video | null;
  /**
   * Comments on this post
   */
  comments: GetUsersPosts_user_posts_edges_node_comments;
  /**
   * Is this post sharing another post
   */
  sharingPost: boolean;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Users and pages that likes this post
   */
  likers: GetUsersPosts_user_posts_edges_node_likers;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * The user that created this post
   */
  user: GetUsersPosts_user_posts_edges_node_user;
  trip: GetUsersPosts_user_posts_edges_node_trip | null;
  /**
   * Catch details on this post
   */
  catch: GetUsersPosts_user_posts_edges_node_catch | null;
  /**
   * Text content in this post
   */
  text: GetUsersPosts_user_posts_edges_node_text;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: GetUsersPosts_user_posts_edges_node_productUnits;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: GetUsersPosts_user_posts_edges_node_displayProductUnits;
  images: GetUsersPosts_user_posts_edges_node_images;
}

export interface GetUsersPosts_user_posts_edges {
  __typename: "PostEdge";
  /**
   * The item at the end of the edge.
   */
  node: GetUsersPosts_user_posts_edges_node | null;
}

export interface GetUsersPosts_user_posts {
  __typename: "PostRecordConnection";
  /**
   * Information to aid in pagination.
   */
  pageInfo: GetUsersPosts_user_posts_pageInfo;
  /**
   * A list of edges.
   */
  edges: (GetUsersPosts_user_posts_edges | null)[] | null;
}

export interface GetUsersPosts_user {
  __typename: "User";
  _id: string;
  posts: GetUsersPosts_user_posts;
}

export interface GetUsersPosts {
  /**
   * A user in the app
   */
  user: GetUsersPosts_user;
}

export interface GetUsersPostsVariables {
  nickname: string;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNickname
// ====================================================

export interface GetNickname_user {
  __typename: "User";
  _id: string;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface GetNickname {
  /**
   * A user in the app
   */
  user: GetNickname_user;
}

export interface GetNicknameVariables {
  externalId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWaypoint
// ====================================================

export interface GetWaypoint_waypoint_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface GetWaypoint_waypoint_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface GetWaypoint_waypoint_user {
  __typename: "User";
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
  _id: string;
  avatar: GetWaypoint_waypoint_user_avatar | null;
}

export interface GetWaypoint_waypoint {
  __typename: "Waypoint";
  _id: string;
  lat: number;
  lng: number;
  /**
   * User specified name
   */
  name: string;
  /**
   * User specified description
   */
  description: string | null;
  /**
   * Identifier for the symbol representing the waypoint
   */
  symbol: string;
  image: GetWaypoint_waypoint_image | null;
  /**
   * Owner of the waypoint
   */
  user: GetWaypoint_waypoint_user;
}

export interface GetWaypoint {
  /**
   * For fetching a single waypoint (Waypoint, a marker on the map)
   */
  waypoint: GetWaypoint_waypoint;
}

export interface GetWaypointVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BrandId
// ====================================================

export interface BrandId {
  __typename: "Brand";
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatchFieldsForPostsViewer
// ====================================================

export interface CatchFieldsForPostsViewer_post_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface CatchFieldsForPostsViewer_post_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface CatchFieldsForPostsViewer_post_catch {
  __typename: "Catch";
  _id: string;
  fishingWater: CatchFieldsForPostsViewer_post_catch_fishingWater | null;
  species: CatchFieldsForPostsViewer_post_catch_species | null;
  caughtAtGmt: any | null;
}

export interface CatchFieldsForPostsViewer_post_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface CatchFieldsForPostsViewer_post_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface CatchFieldsForPostsViewer_post_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface CatchFieldsForPostsViewer_post_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: CatchFieldsForPostsViewer_post_displayEntity_displayIcon;
}

export interface CatchFieldsForPostsViewer_post_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface CatchFieldsForPostsViewer_post_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface CatchFieldsForPostsViewer_post_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: CatchFieldsForPostsViewer_post_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface CatchFieldsForPostsViewer_post_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: CatchFieldsForPostsViewer_post_productUnits_edges_node_image | null;
  product: CatchFieldsForPostsViewer_post_productUnits_edges_node_product;
}

export interface CatchFieldsForPostsViewer_post_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: CatchFieldsForPostsViewer_post_productUnits_edges_node | null;
}

export interface CatchFieldsForPostsViewer_post_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (CatchFieldsForPostsViewer_post_productUnits_edges | null)[] | null;
}

export interface CatchFieldsForPostsViewer_post_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface CatchFieldsForPostsViewer_post_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: CatchFieldsForPostsViewer_post_video_screenshot;
}

export interface CatchFieldsForPostsViewer_post_images_edges_node {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface CatchFieldsForPostsViewer_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: CatchFieldsForPostsViewer_post_images_edges_node | null;
}

export interface CatchFieldsForPostsViewer_post_images {
  __typename: "ImageRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (CatchFieldsForPostsViewer_post_images_edges | null)[] | null;
}

export interface CatchFieldsForPostsViewer_post_text {
  __typename: "Text";
  text: string | null;
}

export interface CatchFieldsForPostsViewer_post_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface CatchFieldsForPostsViewer_post_user {
  __typename: "User";
  _id: string;
  avatar: CatchFieldsForPostsViewer_post_user_avatar | null;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface CatchFieldsForPostsViewer_post {
  __typename: "Post";
  _id: string;
  /**
   * Catch details on this post
   */
  catch: CatchFieldsForPostsViewer_post_catch | null;
  /**
   * Comments on this post
   */
  comments: CatchFieldsForPostsViewer_post_comments;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: CatchFieldsForPostsViewer_post_displayProductUnits;
  displayEntity: CatchFieldsForPostsViewer_post_displayEntity;
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: CatchFieldsForPostsViewer_post_productUnits;
  /**
   * Video in this post
   */
  video: CatchFieldsForPostsViewer_post_video | null;
  images: CatchFieldsForPostsViewer_post_images;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * Text content in this post
   */
  text: CatchFieldsForPostsViewer_post_text;
  /**
   * The user that created this post
   */
  user: CatchFieldsForPostsViewer_post_user;
}

export interface CatchFieldsForPostsViewer {
  __typename: "Catch";
  _id: string;
  post: CatchFieldsForPostsViewer_post;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatchId
// ====================================================

export interface CatchId {
  __typename: "Catch";
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatchLocation
// ====================================================

export interface CatchLocation {
  __typename: "Catch";
  latitude: number | null;
  longitude: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatchMetadata
// ====================================================

export interface CatchMetadata {
  __typename: "Catch";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatchProductUnits
// ====================================================

export interface CatchProductUnits_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface CatchProductUnits_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface CatchProductUnits_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: CatchProductUnits_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface CatchProductUnits_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: CatchProductUnits_productUnits_edges_node_image | null;
  product: CatchProductUnits_productUnits_edges_node_product;
}

export interface CatchProductUnits_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: CatchProductUnits_productUnits_edges_node | null;
}

export interface CatchProductUnits_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (CatchProductUnits_productUnits_edges | null)[] | null;
}

export interface CatchProductUnits {
  __typename: "Catch";
  productUnits: CatchProductUnits_productUnits;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatchFishingWaterName
// ====================================================

export interface CatchFishingWaterName_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface CatchFishingWaterName {
  __typename: "Catch";
  fishingWater: CatchFishingWaterName_fishingWater | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatchFishingMethodName
// ====================================================

export interface CatchFishingMethodName_fishingMethod {
  __typename: "FishingMethod";
  _id: string;
  displayName: string;
}

export interface CatchFishingMethodName {
  __typename: "Catch";
  fishingMethod: CatchFishingMethodName_fishingMethod | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatchSpeciesName
// ====================================================

export interface CatchSpeciesName_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface CatchSpeciesName {
  __typename: "Catch";
  species: CatchSpeciesName_species | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CatchWeatherFields
// ====================================================

export interface CatchWeatherFields_weatherAndMarineReading_weatherCondition {
  __typename: "WeatherCondition";
  localizedValue: string;
  /**
   * Use this identifier to retrieve the relevant weather symbol icon from World Weather Online
   */
  worldWeatherOnlineIdentifier: string;
}

export interface CatchWeatherFields_weatherAndMarineReading_windDirection {
  __typename: "WindDirection";
  /**
   * DB ID
   */
  id: number;
  localizedValue: string;
  shortLocalizedValue: string;
  degrees: number | null;
}

export interface CatchWeatherFields_weatherAndMarineReading {
  __typename: "WeatherAndMarineReading";
  /**
   * DB ID
   */
  id: number;
  /**
   * Air pressure [Pa]
   */
  airPressure: number | null;
  /**
   * Air humidity [Percent]
   */
  airHumidity: number | null;
  /**
   * Air temperature [K]
   */
  airTemperature: number | null;
  /**
   * Water temperature [K]
   */
  waterTemperature: number | null;
  weatherCondition: CatchWeatherFields_weatherAndMarineReading_weatherCondition | null;
  windDirection: CatchWeatherFields_weatherAndMarineReading_windDirection | null;
  /**
   * Wind speed [m/s]
   */
  windSpeed: number | null;
}

export interface CatchWeatherFields {
  __typename: "Catch";
  weatherAndMarineReading: CatchWeatherFields_weatherAndMarineReading | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CategoryId
// ====================================================

export interface CategoryId {
  __typename: "Category";
  /**
   * Category ID
   */
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CountryId
// ====================================================

export interface CountryId {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FishingMethodId
// ====================================================

export interface FishingMethodId {
  __typename: "FishingMethod";
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FishingWaterReviewId
// ====================================================

export interface FishingWaterReviewId {
  __typename: "FishingWaterReview";
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FishingWaterId
// ====================================================

export interface FishingWaterId {
  __typename: "FishingWater";
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FishingWaterLocationFields
// ====================================================

export interface FishingWaterLocationFields_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface FishingWaterLocationFields_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
}

export interface FishingWaterLocationFields {
  __typename: "FishingWater";
  latitude: number;
  longitude: number;
  country: FishingWaterLocationFields_country | null;
  region: FishingWaterLocationFields_region | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FishingWaterLocationFieldsExtended
// ====================================================

export interface FishingWaterLocationFieldsExtended_country_topFishingWaters_edges_node {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
}

export interface FishingWaterLocationFieldsExtended_country_topFishingWaters_edges {
  __typename: "FishingWaterEdge";
  /**
   * The item at the end of the edge.
   */
  node: FishingWaterLocationFieldsExtended_country_topFishingWaters_edges_node | null;
}

export interface FishingWaterLocationFieldsExtended_country_topFishingWaters {
  __typename: "FishingWaterRecordConnection";
  /**
   * A list of edges.
   */
  edges: (FishingWaterLocationFieldsExtended_country_topFishingWaters_edges | null)[] | null;
}

export interface FishingWaterLocationFieldsExtended_country_topSpecies_edges_node {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface FishingWaterLocationFieldsExtended_country_topSpecies_edges {
  __typename: "TopSpeciesSummaryEdge";
  /**
   * The item at the end of the edge.
   */
  node: FishingWaterLocationFieldsExtended_country_topSpecies_edges_node | null;
}

export interface FishingWaterLocationFieldsExtended_country_topSpecies {
  __typename: "TopSpeciesConnection";
  /**
   * A list of edges.
   */
  edges: (FishingWaterLocationFieldsExtended_country_topSpecies_edges | null)[] | null;
}

export interface FishingWaterLocationFieldsExtended_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
  /**
   * The top fishing waters for the country
   */
  topFishingWaters: FishingWaterLocationFieldsExtended_country_topFishingWaters;
  /**
   * The top species for the country
   */
  topSpecies: FishingWaterLocationFieldsExtended_country_topSpecies;
}

export interface FishingWaterLocationFieldsExtended_region_topFishingWaters_edges_node {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
}

export interface FishingWaterLocationFieldsExtended_region_topFishingWaters_edges {
  __typename: "FishingWaterEdge";
  /**
   * The item at the end of the edge.
   */
  node: FishingWaterLocationFieldsExtended_region_topFishingWaters_edges_node | null;
}

export interface FishingWaterLocationFieldsExtended_region_topFishingWaters {
  __typename: "FishingWaterRecordConnection";
  /**
   * A list of edges.
   */
  edges: (FishingWaterLocationFieldsExtended_region_topFishingWaters_edges | null)[] | null;
}

export interface FishingWaterLocationFieldsExtended_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  /**
   * URL for redirecting users to for information about fishing licenses in the region
   */
  licenseInfoUrl: string | null;
  /**
   * The top fishing waters for the region
   */
  topFishingWaters: FishingWaterLocationFieldsExtended_region_topFishingWaters;
}

export interface FishingWaterLocationFieldsExtended {
  __typename: "FishingWater";
  latitude: number;
  longitude: number;
  country: FishingWaterLocationFieldsExtended_country | null;
  region: FishingWaterLocationFieldsExtended_region | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FishingWaterWatersListItem
// ====================================================

export interface FishingWaterWatersListItem_catches_edges_node_post_images_edges_node {
  __typename: "Image";
  /**
   * Used to remove catch images using REST endpoint
   */
  id: number | null;
  width: number;
  height: number;
  url: string;
}

export interface FishingWaterWatersListItem_catches_edges_node_post_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: FishingWaterWatersListItem_catches_edges_node_post_images_edges_node | null;
}

export interface FishingWaterWatersListItem_catches_edges_node_post_images {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (FishingWaterWatersListItem_catches_edges_node_post_images_edges | null)[] | null;
}

export interface FishingWaterWatersListItem_catches_edges_node_post {
  __typename: "Post";
  _id: string;
  images: FishingWaterWatersListItem_catches_edges_node_post_images;
}

export interface FishingWaterWatersListItem_catches_edges_node {
  __typename: "Catch";
  _id: string;
  post: FishingWaterWatersListItem_catches_edges_node_post;
}

export interface FishingWaterWatersListItem_catches_edges {
  __typename: "CatchRecordEdge";
  /**
   * The item at the end of the edge.
   */
  node: FishingWaterWatersListItem_catches_edges_node | null;
}

export interface FishingWaterWatersListItem_catches {
  __typename: "CatchRecordConnection";
  /**
   * A list of edges.
   */
  edges: (FishingWaterWatersListItem_catches_edges | null)[] | null;
}

export interface FishingWaterWatersListItem_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  totalAverage: number;
}

export interface FishingWaterWatersListItem_topSpecies_edges_node {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
}

export interface FishingWaterWatersListItem_topSpecies_edges {
  __typename: "FishingWaterTopSpeciesEdge";
  /**
   * The item at the end of the edge.
   */
  node: FishingWaterWatersListItem_topSpecies_edges_node | null;
}

export interface FishingWaterWatersListItem_topSpecies {
  __typename: "FishingWaterTopSpeciesConnection";
  /**
   * A list of edges.
   */
  edges: (FishingWaterWatersListItem_topSpecies_edges | null)[] | null;
}

export interface FishingWaterWatersListItem {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  displayName: string;
  /**
   * The number of catches for this water
   */
  catchesCount: number;
  /**
   * If the current user follows this water
   */
  followedByCurrentUser: boolean;
  catches: FishingWaterWatersListItem_catches;
  reviews: FishingWaterWatersListItem_reviews | null;
  /**
   * The most caught species in this water
   */
  topSpecies: FishingWaterWatersListItem_topSpecies;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FishingWaterCarouselItem
// ====================================================

export interface FishingWaterCarouselItem_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  totalAverage: number;
}

export interface FishingWaterCarouselItem_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface FishingWaterCarouselItem_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
}

export interface FishingWaterCarouselItem {
  __typename: "FishingWater";
  _id: string;
  latitude: number;
  longitude: number;
  displayName: string;
  reviews: FishingWaterCarouselItem_reviews | null;
  country: FishingWaterCarouselItem_country | null;
  region: FishingWaterCarouselItem_region | null;
  /**
   * The number of catches for this water
   */
  catchesCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ImageFields
// ====================================================

export interface ImageFields {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageId
// ====================================================

export interface PageId {
  __typename: "Page";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PlaceId
// ====================================================

export interface PlaceId {
  __typename: "Place";
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PlaceCarouselItem
// ====================================================

export interface PlaceCarouselItem_country {
  __typename: "Country";
  /**
   * Country Code
   */
  id: string;
  countryName: string;
}

export interface PlaceCarouselItem_region {
  __typename: "Region";
  id: string | null;
  name: string | null;
  regionCode: string | null;
}

export interface PlaceCarouselItem_unsplashImage {
  __typename: "UnsplashImage";
  /**
   * DB ID
   */
  id: number;
  /**
   * A URL for an image. If you use this make sure to read
   * the usage guidelines first -
   * https:                 // help.unsplash.com/en/articles/2511245-unsplash-api-guidelines
   * You can also apply transformations to the image by altering the URL. See here
   * for more info - https: // unsplash.com/documentation#dynamically-resizable-images
   */
  imageUrl: string | null;
}

export interface PlaceCarouselItem {
  __typename: "Place";
  _id: string;
  name: string;
  slug: string | null;
  latitude: number;
  longitude: number;
  /**
   * The country this place belongs to
   */
  country: PlaceCarouselItem_country;
  /**
   * The region this place belongs to
   */
  region: PlaceCarouselItem_region;
  /**
   * An image for this place
   */
  unsplashImage: PlaceCarouselItem_unsplashImage | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PointOfInterestId
// ====================================================

export interface PointOfInterestId {
  __typename: "PointOfInterest";
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostId
// ====================================================

export interface PostId {
  __typename: "Post";
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostMetadata
// ====================================================

export interface PostMetadata {
  __typename: "Post";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Date the post was published
   */
  publishedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DisplayProductUnits
// ====================================================

export interface DisplayProductUnits_displayProductUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface DisplayProductUnits_displayProductUnits_edges_node_product_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  averageRating: number;
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface DisplayProductUnits_displayProductUnits_edges_node_product_image {
  __typename: "Image";
  /**
   * Used to remove catch images using REST endpoint
   */
  id: number | null;
  width: number;
  height: number;
  url: string;
}

export interface DisplayProductUnits_displayProductUnits_edges_node_product {
  __typename: "Product";
  brand: DisplayProductUnits_displayProductUnits_edges_node_product_brand;
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  externalId: string;
  displayName: string;
  reviews: DisplayProductUnits_displayProductUnits_edges_node_product_reviews | null;
  image: DisplayProductUnits_displayProductUnits_edges_node_product_image | null;
}

export interface DisplayProductUnits_displayProductUnits_edges_node {
  __typename: "ProductUnit";
  product: DisplayProductUnits_displayProductUnits_edges_node_product;
}

export interface DisplayProductUnits_displayProductUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: DisplayProductUnits_displayProductUnits_edges_node | null;
}

export interface DisplayProductUnits_displayProductUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
  /**
   * A list of edges.
   */
  edges: (DisplayProductUnits_displayProductUnits_edges | null)[] | null;
}

export interface DisplayProductUnits {
  __typename: "Post";
  /**
   * Units to display (i.e. in the feed).  Returns associated catch's units
   */
  displayProductUnits: DisplayProductUnits_displayProductUnits;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostProductUnits
// ====================================================

export interface PostProductUnits_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface PostProductUnits_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface PostProductUnits_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: PostProductUnits_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface PostProductUnits_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: PostProductUnits_productUnits_edges_node_image | null;
  product: PostProductUnits_productUnits_edges_node_product;
}

export interface PostProductUnits_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: PostProductUnits_productUnits_edges_node | null;
}

export interface PostProductUnits_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (PostProductUnits_productUnits_edges | null)[] | null;
}

export interface PostProductUnits {
  __typename: "Post";
  /**
   * Product units which the user wants to share/discuss in their post
   */
  productUnits: PostProductUnits_productUnits;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostDisplayEntities
// ====================================================

export interface PostDisplayEntities_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface PostDisplayEntities_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: PostDisplayEntities_displayEntity_displayIcon;
}

export interface PostDisplayEntities {
  __typename: "Post";
  displayEntity: PostDisplayEntities_displayEntity;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostfirstImageSquared384
// ====================================================

export interface PostfirstImageSquared384_images_edges_node {
  __typename: "Image";
  /**
   * Used to remove catch images using REST endpoint
   */
  id: number | null;
  width: number;
  height: number;
  url: string;
}

export interface PostfirstImageSquared384_images_edges {
  __typename: "ImageEdge";
  /**
   * The item at the end of the edge.
   */
  node: PostfirstImageSquared384_images_edges_node | null;
}

export interface PostfirstImageSquared384_images {
  __typename: "ImageRecordConnection";
  /**
   * A list of edges.
   */
  edges: (PostfirstImageSquared384_images_edges | null)[] | null;
}

export interface PostfirstImageSquared384 {
  __typename: "Post";
  images: PostfirstImageSquared384_images;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostFishingWaterName
// ====================================================

export interface PostFishingWaterName_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
}

export interface PostFishingWaterName {
  __typename: "Post";
  /**
   * A fishing water associated with this post
   */
  fishingWater: PostFishingWaterName_fishingWater | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostVideoFields
// ====================================================

export interface PostVideoFields_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface PostVideoFields_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: PostVideoFields_video_screenshot;
}

export interface PostVideoFields {
  __typename: "Post";
  /**
   * Video in this post
   */
  video: PostVideoFields_video | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PostCardFields
// ====================================================

export interface PostCardFields_displayEntity_displayIcon {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface PostCardFields_displayEntity {
  __typename: "Page" | "User" | "FishingWater" | "Species" | "FishingMethod" | "Group" | "CurrentUser";
  displayName: string;
  _id: string;
  /**
   * ID of display entity for deep links
   */
  legacyId: number;
  displayIcon: PostCardFields_displayEntity_displayIcon;
}

export interface PostCardFields_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
}

export interface PostCardFields_video_screenshot {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface PostCardFields_video {
  __typename: "Video";
  id: number;
  /**
   * Urls to streamable video
   */
  hlsUrl: string | null;
  /**
   * Url to browser supported video
   */
  webUrl: string | null;
  /**
   * Url to the original video
   */
  originalUrl: string;
  screenshot: PostCardFields_video_screenshot;
}

export interface PostCardFields_comments {
  __typename: "PostRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface PostCardFields_likers {
  __typename: "PostsFeedDisplayEntityRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface PostCardFields_user_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface PostCardFields_user {
  __typename: "User";
  _id: string;
  avatar: PostCardFields_user_avatar | null;
  isPremium: boolean | null;
  firstName: string | null;
  lastName: string | null;
  /**
   * Date record was added to the database
   */
  createdAt: any;
  /**
   * Unique identifying nickname for user
   */
  nickname: string;
}

export interface PostCardFields_trip_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface PostCardFields_trip {
  __typename: "Trip";
  id: string;
  /**
   * Date record was added to the database
   */
  createdAt: any;
  catches: PostCardFields_trip_catches;
  startedAt: any;
  endedAt: any;
}

export interface PostCardFields_catch_fishingMethod {
  __typename: "FishingMethod";
  _id: string;
  displayName: string;
}

export interface PostCardFields_catch_fishingWater {
  __typename: "FishingWater";
  _id: string;
  displayName: string;
  latitude: number;
  longitude: number;
}

export interface PostCardFields_catch_productUnits_edges_node_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface PostCardFields_catch_productUnits_edges_node_product_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface PostCardFields_catch_productUnits_edges_node_product {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  brand: PostCardFields_catch_productUnits_edges_node_product_brand;
  displayName: string;
}

export interface PostCardFields_catch_productUnits_edges_node {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
  /**
   * Unique string identifying the product
   */
  model: string;
  image: PostCardFields_catch_productUnits_edges_node_image | null;
  product: PostCardFields_catch_productUnits_edges_node_product;
}

export interface PostCardFields_catch_productUnits_edges {
  __typename: "ProductUnitEdge";
  /**
   * The item at the end of the edge.
   */
  node: PostCardFields_catch_productUnits_edges_node | null;
}

export interface PostCardFields_catch_productUnits {
  __typename: "ProductUnitRecordConnection";
  /**
   * A list of edges.
   */
  edges: (PostCardFields_catch_productUnits_edges | null)[] | null;
}

export interface PostCardFields_catch_species {
  __typename: "Species";
  _id: string;
  /**
   * Default name for this species
   */
  displayName: string;
  /**
   * DB ID
   */
  legacyId: number;
}

export interface PostCardFields_catch_trip_catches {
  __typename: "CatchRecordConnection";
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface PostCardFields_catch_trip {
  __typename: "Trip";
  id: string;
  catches: PostCardFields_catch_trip_catches;
}

export interface PostCardFields_catch {
  __typename: "Catch";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  fishingMethod: PostCardFields_catch_fishingMethod | null;
  fishingWater: PostCardFields_catch_fishingWater | null;
  productUnits: PostCardFields_catch_productUnits;
  isPersonalBest: boolean;
  /**
   * User-specified weight of the catch in kg
   */
  weight: number | null;
  /**
   * User-specified length of the catch in m
   */
  length: number | null;
  catchAndRelease: boolean | null;
  caughtAtLocalTimeZone: any | null;
  caughtAtGmt: any | null;
  hasExactPosition: boolean;
  /**
   * Level of publicity the catch owner has given to the catch location
   */
  locationPrivacy: CatchLocationPrivacyTypes;
  species: PostCardFields_catch_species | null;
  /**
   * Trip this catch is part of
   */
  trip: PostCardFields_catch_trip | null;
}

export interface PostCardFields_text {
  __typename: "Text";
  text: string | null;
}

export interface PostCardFields {
  __typename: "Post";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
  /**
   * Date record was added to the database
   */
  createdAt: any | null;
  /**
   * Date the post was published
   */
  publishedAt: any | null;
  displayEntity: PostCardFields_displayEntity;
  /**
   * A fishing water associated with this post
   */
  fishingWater: PostCardFields_fishingWater | null;
  /**
   * Video in this post
   */
  video: PostCardFields_video | null;
  /**
   * Comments on this post
   */
  comments: PostCardFields_comments;
  /**
   * Is this post sharing another post
   */
  sharingPost: boolean;
  /**
   * If the current user has liked this catch
   */
  likedByCurrentUser: boolean;
  /**
   * Users and pages that likes this post
   */
  likers: PostCardFields_likers;
  /**
   * Number of likes on this post. Note that this value does not account for the
   * requesting users blocking of other users. If you need the correct value use
   * likers.totalCount, but keep in mind that this will have a large performance impact.
   */
  likesCount: number;
  /**
   * The user that created this post
   */
  user: PostCardFields_user;
  trip: PostCardFields_trip | null;
  /**
   * Catch details on this post
   */
  catch: PostCardFields_catch | null;
  /**
   * Text content in this post
   */
  text: PostCardFields_text;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductId
// ====================================================

export interface ProductId {
  __typename: "Product";
  _id: string;
  /**
   * DB ID
   */
  legacyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductBrandName
// ====================================================

export interface ProductBrandName_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface ProductBrandName {
  __typename: "Product";
  brand: ProductBrandName_brand;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductListItem
// ====================================================

export interface ProductListItem_image {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface ProductListItem_brand {
  __typename: "Brand";
  id: number;
  name: string;
}

export interface ProductListItem_reviews {
  __typename: "ReviewInterfaceRecordConnection";
  /**
   * Average of column for records beyond pagination (limited by scopes, if applicable)
   */
  averageRating: number;
  /**
   * Total count of records beyond pagination (limited by scopes, if applicable).
   * Can optionally return count of non-NULL values if a `column` / `attribute` is given
   */
  totalCount: number;
}

export interface ProductListItem {
  __typename: "Product";
  _id: string;
  displayName: string;
  image: ProductListItem_image | null;
  brand: ProductListItem_brand;
  reviews: ProductListItem_reviews | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductUnitId
// ====================================================

export interface ProductUnitId {
  __typename: "ProductUnit";
  /**
   * DB ID
   */
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductUnitUsedByCurrentUser
// ====================================================

export interface ProductUnitUsedByCurrentUser {
  __typename: "ProductUnit";
  /**
   * Is this unit in the current user's tacklebox?
   */
  usedByCurrentUser: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RegionId
// ====================================================

export interface RegionId {
  __typename: "Region";
  id: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SpeciesId
// ====================================================

export interface SpeciesId {
  __typename: "Species";
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TagId
// ====================================================

export interface TagId {
  __typename: "Tag";
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserId
// ====================================================

export interface UserId {
  __typename: "User";
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CurrentUserId
// ====================================================

export interface CurrentUserId {
  __typename: "CurrentUser";
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserAvatar
// ====================================================

export interface UserAvatar_avatar {
  __typename: "Image";
  height: number;
  width: number;
  url: string;
}

export interface UserAvatar {
  __typename: "User";
  avatar: UserAvatar_avatar | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CurrentUserAvatar
// ====================================================

export interface CurrentUserAvatar_avatar {
  __typename: "Image";
  width: number;
  height: number;
  url: string;
}

export interface CurrentUserAvatar {
  __typename: "CurrentUser";
  avatar: CurrentUserAvatar_avatar | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VideoId
// ====================================================

export interface VideoId {
  __typename: "Video";
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WeatherAndMarineReadingId
// ====================================================

export interface WeatherAndMarineReadingId {
  __typename: "WeatherAndMarineReading";
  /**
   * DB ID
   */
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WindDirectionId
// ====================================================

export interface WindDirectionId {
  __typename: "WindDirection";
  /**
   * DB ID
   */
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * How the catch owner has shared the location of the catch.
 */
export enum CatchLocationPrivacyTypes {
  COMPETITIVE = "COMPETITIVE",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

/**
 * Determines if one or more metadata for the same group can apply at the same time
 */
export enum FishingWaterMetadataGroupChoiceTypes {
  multiple = "multiple",
  single = "single",
}

export enum FishingWaterMetadataVariants {
  danger = "danger",
  default = "default",
  warning = "warning",
}

export enum FollowRecordsTypeEnum {
  FISHING_METHOD = "FISHING_METHOD",
  FISHING_WATER = "FISHING_WATER",
  PAGE = "PAGE",
  SPECIES = "SPECIES",
  USER = "USER",
  WAYPOINT = "WAYPOINT",
}

export enum LikePostActionEnum {
  LIKE = "LIKE",
  UNLIKE = "UNLIKE",
}

export enum MonthEnum {
  APRIL = "APRIL",
  AUGUST = "AUGUST",
  DECEMBER = "DECEMBER",
  FEBRUARY = "FEBRUARY",
  JANUARY = "JANUARY",
  JULY = "JULY",
  JUNE = "JUNE",
  MARCH = "MARCH",
  MAY = "MAY",
  NOVEMBER = "NOVEMBER",
  OCTOBER = "OCTOBER",
  SEPTEMBER = "SEPTEMBER",
}

export enum MoonIlluminationTypes {
  FIRST_QUARTER = "FIRST_QUARTER",
  FULL_MOON = "FULL_MOON",
  LAST_QUARTER = "LAST_QUARTER",
  NEW_MOON = "NEW_MOON",
  WANING_CRESCENT = "WANING_CRESCENT",
  WANING_GIBBOUS = "WANING_GIBBOUS",
  WAXING_CRESCENT = "WAXING_CRESCENT",
  WAXING_GIBBOUS = "WAXING_GIBBOUS",
}

export enum PagePopularityOrdering {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum ReviewPropertyAnswerTypes {
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL",
  POSITIVE = "POSITIVE",
}

export enum SunPositionTypes {
  DAWN = "DAWN",
  DAY_END = "DAY_END",
  DAY_START = "DAY_START",
  DUSK = "DUSK",
  MIDDAY = "MIDDAY",
  MIDNIGHT = "MIDNIGHT",
  NIGHT_END = "NIGHT_END",
  NIGHT_START = "NIGHT_START",
}

export enum UnfollowRecordsTypeEnum {
  FISHING_METHOD = "FISHING_METHOD",
  FISHING_WATER = "FISHING_WATER",
  PAGE = "PAGE",
  SPECIES = "SPECIES",
  USER = "USER",
  WAYPOINT = "WAYPOINT",
}

export enum WaterSalinity {
  freshwater = "freshwater",
  mixed = "mixed",
  saltwater = "saltwater",
}

export enum WaterTypes {
  canal = "canal",
  estuary = "estuary",
  lake = "lake",
  ocean = "ocean",
  stream = "stream",
  swamp = "swamp",
  unknown = "unknown",
}

export interface ActiveStorageImageUpload {
  signedId: string;
}

/**
 * Autogenerated input type of AssociateProductUnitsToMeMutation
 */
export interface AssociateProductUnitsToMeMutationInput {
  clientMutationId?: string | null;
  productUnitIds?: number[] | null;
  externalIds?: string[] | null;
  searchId?: number | null;
}

/**
 * Autogenerated input type of AttributeTrialPromotionMutation
 */
export interface AttributeTrialPromotionMutationInput {
  clientMutationId?: string | null;
  link: string;
}

export interface BoundingBoxInputObject {
  southWest: PositionInputObject;
  northEast: PositionInputObject;
}

export interface BoundingCircleInputObject {
  center: PositionInputObject;
  delta: number;
}

export interface CallToActionAttributes {
  text: string;
  url: string;
  backgroundColor: string;
}

export interface CatchFilters {
  pinnedCatchesFirst?: boolean | null;
  havingProductUnits?: boolean | null;
  havingWeight?: boolean | null;
  havingLength?: boolean | null;
  orderByRecent?: boolean | null;
  orderByWeight?: boolean | null;
  orderByLength?: boolean | null;
  orderByCaughtAt?: boolean | null;
  orderByLikes?: boolean | null;
  speciesId?: string | null;
  speciesIds?: string[] | null;
  fishingMethodId?: string | null;
  withPhotos?: boolean | null;
  withoutSpecies?: boolean | null;
  withPublicPosition?: boolean | null;
  lastNDays?: number | null;
  caughtBetweenDates?: DateRangeInputObject | null;
  caughtInMonths?: MonthEnum[] | null;
  caughtInYears?: number[] | null;
  excludingCatch?: string | null;
  excludingTrip?: string | null;
  shopFriendly?: boolean | null;
  isPinned?: boolean | null;
  isPersonalBest?: boolean | null;
  speciesWaterSalinity?: WaterSalinity[] | null;
  userExternalId?: string | null;
  fishingWaterId?: string | null;
}

export interface ConsentAttributes {
  accepted: boolean;
  agreementUid: string;
}

/**
 * Autogenerated input type of CreateDirectUploadURLMutation
 */
export interface CreateDirectUploadURLMutationInput {
  clientMutationId?: string | null;
  attributes: ImageUploadAttributes;
}

/**
 * Autogenerated input type of CreatePostMutation
 */
export interface CreatePostMutationInput {
  clientMutationId?: string | null;
  parentExternalId?: string | null;
  attributes: PostWithPageAttributes;
}

/**
 * Autogenerated input type of CreatePromotedCallToActionMutation
 */
export interface CreatePromotedCallToActionMutationInput {
  clientMutationId?: string | null;
  pageId: string;
  attributes: PromotedCallToActionAttributes;
}

/**
 * Autogenerated input type of CreateReviewMutation
 */
export interface CreateReviewMutationInput {
  clientMutationId?: string | null;
  productExternalId: string;
  attributes: ReviewAttributes;
}

/**
 * Autogenerated input type of CreateUserSuggestedChangeMutation
 */
export interface CreateUserSuggestedChangeMutationInput {
  clientMutationId?: string | null;
  attributes: UserSuggestedChangeAttributes;
}

export interface DateRangeInputObject {
  startDate: any;
  endDate: any;
}

/**
 * Autogenerated input type of DeletePromotedCallToActionMutation
 */
export interface DeletePromotedCallToActionMutationInput {
  clientMutationId?: string | null;
  id: string;
}

/**
 * Autogenerated input type of DisassociateProductUnitsFromMeMutation
 */
export interface DisassociateProductUnitsFromMeMutationInput {
  clientMutationId?: string | null;
  externalIds: string[];
}

/**
 * Autogenerated input type of FollowRecordsMutation
 */
export interface FollowRecordsMutationInput {
  clientMutationId?: string | null;
  type: FollowRecordsTypeEnum;
  ids: string[];
}

export interface ImageUploadAttributes {
  contentType: string;
  filename: string;
  byteSize: number;
  checksum: string;
  width: number;
  height: number;
}

export interface LikePostAttributes {
  postExternalId: string;
  likeAction: LikePostActionEnum;
}

/**
 * Autogenerated input type of LikePostMutation
 */
export interface LikePostMutationInput {
  clientMutationId?: string | null;
  attributes: LikePostAttributes;
}

export interface PageAttributes {
  published?: boolean | null;
  description?: string | null;
  title?: string | null;
  membersTitle?: string | null;
  logo?: ActiveStorageImageUpload | null;
  coverImages?: ActiveStorageImageUpload[] | null;
}

export interface PageFilters {
  published?: boolean | null;
  publishedBrands?: boolean | null;
  publishedStates?: boolean | null;
  orderByPopularity?: PagePopularityOrdering | null;
  containingName?: string | null;
  brands?: boolean | null;
  brandsOrderedByName?: boolean | null;
  orderByLatestPost?: boolean | null;
  orderRandomly?: boolean | null;
}

export interface PositionInputObject {
  latitude: number;
  longitude: number;
}

export interface PostLocation {
  fishingWaterId?: number | null;
  fishingWaterExternalId?: string | null;
  coordinates?: PositionInputObject | null;
}

export interface PostWithPageAttributes {
  textString?: string | null;
  images?: ActiveStorageImageUpload[] | null;
  video?: VideoUpload | null;
  location?: PostLocation | null;
  productUnitIds?: number[] | null;
  productUnitExternalIds?: string[] | null;
  sharedPostExternalId?: string | null;
  publishedAt?: any | null;
  callToAction?: CallToActionAttributes | null;
  pageIds?: string[] | null;
  byPageId?: string | null;
}

export interface ProductFilters {
  orderByName?: boolean | null;
  orderByPopularity?: boolean | null;
  brandIds?: number[] | null;
  brandExternalIds?: string[] | null;
  externalIds?: string[] | null;
  webAdvertIds?: number[] | null;
  categoryIds?: number[] | null;
  categoryExternalIds?: string[] | null;
  inShop?: boolean | null;
  nameContains?: string | null;
  nameContainsOrWebAdvertId?: string | null;
  allStatuses?: boolean | null;
}

export interface PromotedCallToActionAttributes {
  headline: string;
  subHeadline?: string | null;
  buttonText: string;
  targetUrl: string;
  image: ActiveStorageImageUpload;
}

export interface PropertyAnswerAttributes {
  questionExternalId: string;
  answer: ReviewPropertyAnswerTypes;
}

export interface ReviewAttributes {
  title?: string | null;
  textString?: string | null;
  rating: number;
  images?: ActiveStorageImageUpload[] | null;
  video?: VideoUpload | null;
  propertyAnswers?: PropertyAnswerAttributes[] | null;
}

/**
 * Autogenerated input type of UnfollowRecordsMutation
 */
export interface UnfollowRecordsMutationInput {
  clientMutationId?: string | null;
  type: UnfollowRecordsTypeEnum;
  ids: string[];
}

/**
 * Attributes for updating a brand and page. For a family of *_title and *_description
 *             arguments please refer to PageMutation
 */
export interface UpdateBrandAttributes {
  brandImage?: ActiveStorageImageUpload | null;
  address?: string | null;
  url?: string | null;
  name?: string | null;
  aboutDescription?: string | null;
  aboutTitle?: string | null;
  productsTitle?: string | null;
  productsDescription?: string | null;
  membersTitle?: string | null;
}

/**
 * Autogenerated input type of UpdateBrandMutation
 */
export interface UpdateBrandMutationInput {
  clientMutationId?: string | null;
  externalId: string;
  attributes: UpdateBrandAttributes;
}

/**
 * Autogenerated input type of UpdatePageMutation
 */
export interface UpdatePageMutationInput {
  clientMutationId?: string | null;
  id: string;
  attributes: PageAttributes;
}

export interface UpdatePromotedCallToActionAttributes {
  headline?: string | null;
  subHeadline?: string | null;
  buttonText?: string | null;
  targetUrl?: string | null;
  image?: ActiveStorageImageUpload | null;
}

/**
 * Autogenerated input type of UpdatePromotedCallToActionMutation
 */
export interface UpdatePromotedCallToActionMutationInput {
  clientMutationId?: string | null;
  promotedCallToActionId: string;
  attributes: UpdatePromotedCallToActionAttributes;
}

/**
 * Autogenerated input type of UpsertConsentMutation
 */
export interface UpsertConsentMutationInput {
  clientMutationId?: string | null;
  attributes: ConsentAttributes;
}

export interface UserSuggestedChangeAttributes {
  fishingWaterExternalId?: string | null;
  fishingWaterMetadataExternalIds?: string[] | null;
  newWaterName?: string | null;
  position?: PositionInputObject | null;
  url?: string | null;
  additionalInformation?: string | null;
  email?: string | null;
  attachments?: string[] | null;
}

export interface VideoUpload {
  videoHash: string;
  videoFileName: string;
  screenshotFileName: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
