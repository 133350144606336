import React from 'react';

import { IClickableProps, getVariantClass } from '../variants';
import { twMerge } from 'tailwind-merge';

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    IClickableProps {}

export const Button = ({
  className = '',
  size = 'default',
  variant = 'buttonPrimary',
  ...rest
}: IButtonProps) => (
  <button
    type="button"
    className={twMerge(`${className} ${getVariantClass(variant, size)} text-base`, className)}
    {...rest}
  />
);
