import ContentLoader from 'react-content-loader';

import { RATING_ICON_WIDTH } from '../constants/constants';

interface IProps {
  iconSize?: number;
}

export const RatingLoader = ({ iconSize = RATING_ICON_WIDTH }: IProps) => {
  return (
    <ContentLoader
      uniqueKey="rating-loader-key"
      className=""
      style={{
        width: `${iconSize * 5}px`,
        height: `${iconSize}px`,
      }}
    >
      <rect x="0" y="0" rx="5" ry="5" width={iconSize * 5} height={iconSize} />
    </ContentLoader>
  );
};
