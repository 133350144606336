export enum AppDownloadUrlSourceType {
  GooglePlayButton = 'google-play-button-click',
  AppStoreButton = 'app-store-button-click',
  QrCode = 'qrcode-scan',
  AppPromoModal = 'app-promo-modal-click',
  PostsViewer = 'postsviewer-click',
  MapDrawer = 'mapdrawer-click',
  MapInfoOverlay = 'mapinfo-overlay-click',
  StaticAppPromoModal = 'static-app-promo-modal-click',
  StickyBottomBanner = 'sticky-bottom-banner-click',
}

interface IProps {
  appDownloadSourceType: AppDownloadUrlSourceType;
  appDownloadSource?: string;
  currentPageId: string;
  osName?: string;
}

const APP_DOWNLOAD_BASE_URL = 'https://fishbrain.go.link';
const ADJUST_CAMPAIGN_MYKISS = 'fishbrain-website';
const ADJUST_CHANNEL_IOS = 'ba7ih5n';
const ADJUST_CHANNEL_ANDROID = 'vigh1kw';

const enum ADJUST_PARAMS {
  Channel = 'adj_t',
  Campaign = 'adj_campaign',
  AdGroup = 'adj_adgroup',
  Creative = 'adj_creative',
}

/**
 * Generates the app download URL based on the provided props.
 * @param {AppDownloadUrlProps} props - The props for generating the app download URL.
 * @returns {string} The generated app download URL.
 */
export const getAppDownloadUrl = ({
  appDownloadSourceType,
  appDownloadSource,
  currentPageId,
  osName,
}: IProps): string => {
  const params = new URLSearchParams();
  const creative = appDownloadSource
    ? `${appDownloadSourceType}-${appDownloadSource}`
    : appDownloadSourceType;

  if (osName === 'Android') {
    params.append(ADJUST_PARAMS.Channel, ADJUST_CHANNEL_ANDROID);
  } else if (osName === 'iOS') {
    params.append(ADJUST_PARAMS.Channel, ADJUST_CHANNEL_IOS);
  } else {
    params.append(ADJUST_PARAMS.Channel, `${ADJUST_CHANNEL_IOS}_${ADJUST_CHANNEL_ANDROID}`);
  }
  params.append(ADJUST_PARAMS.Campaign, ADJUST_CAMPAIGN_MYKISS);
  params.append(ADJUST_PARAMS.AdGroup, currentPageId);
  params.append(ADJUST_PARAMS.Creative, creative);

  return `${APP_DOWNLOAD_BASE_URL}?${params.toString()}`;
};
