// i.e. available on server and browser
export const UNIVERSAL_ENV_VARS = {
  BUGSNAG_API_KEY: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || '',
  COMMIT_SHA: process.env.NEXT_PUBLIC_COMMIT_SHA,
  MYKISS_ENV: process.env.NEXT_PUBLIC_MYKISS_ENV,
  IS_TESTING_DOMAIN: process.env.NEXT_PUBLIC_IS_TESTING_DOMAIN,
  DATABASE_ENV: process.env.NEXT_PUBLIC_DATABASE_ENV,
  MAPBOX_TOKEN: process.env.NEXT_PUBLIC_MAPBOX_TOKEN || '',
  RECAPTCHA_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_KEY!,
  AMPLITUDE_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_KEY!,
  CLIENT_DATADOG_TOKEN: process.env.NEXT_PUBLIC_CLIENT_DATADOG_TOKEN!,
  STRIPE_KEY: process.env.NEXT_PUBLIC_STRIPE_KEY!,
  IMGPROXY_KEY: process.env.NEXT_PUBLIC_IMGPROXY_KEY!,
  IMGPROXY_SALT: process.env.NEXT_PUBLIC_IMGPROXY_SALT!,
};

// i.e. only available from Next.js server (e.g. API endpoints)
export const SERVER_ENV_VARS = {
  REVALIDATE_ISR_TOKEN: process.env.REVALIDATE_ISR_TOKEN,
  FISHRULES_API_KEY: process.env.FISHRULES_API_KEY!,
  FISHRULES_CLIENT_ID: process.env.FISHRULES_CLIENT_ID!,
  HELPSHIFT_API_KEY: process.env.HELPSHIFT_API_KEY!,
  REVENUECAT_API_KEY: process.env.REVENUECAT_API_KEY,
  CONTENTFUL_SPACE_ID: process.env.CONTENTFUL_SPACE_ID,
  CONTENTFUL_CMA_TOKEN: process.env.CONTENTFUL_CMA_TOKEN,
};

// These will only be set properly when running behind Cloudfront (i.e. *.fishbrain.com). You can
// statically set them in .env.development.local though. They are only available on the server.
export const CLOUDFRONT_ENV_VARS = {
  CF_HEADER_IS_MOBILE: process.env.CF_HEADER_IS_MOBILE,
  CF_HEADER_LATITUDE: process.env.CF_HEADER_LATITUDE,
  CF_HEADER_LONGITUDE: process.env.CF_HEADER_LONGITUDE,
  CF_HEADER_REGION: process.env.CF_HEADER_REGION,
  CF_HEADER_REGION_NAME: process.env.CF_HEADER_REGION_NAME,
  CF_HEADER_COUNTRY: process.env.CF_HEADER_COUNTRY,
};
