/* eslint-disable no-magic-numbers */
import React from 'react';

import { useClientHasRendered } from '../../helpers/hooks';
import { StarHalfIcon } from '../Icons/StarHalfIcon';
import { StarIcon } from '../Icons/StarIcon';
import { StarOutlineIcon } from '../Icons/StarOutlineIcon';
import { Text, ITextProps } from '../Text/Text';

import { RatingLabelLoader } from './components/RatingLabelLoader';
import { RatingLoader } from './components/RatingLoader';
import { RATING_ICON_WIDTH } from './constants/constants';

export enum RatingVariant {
  allStars = 0,
  singleStar,
  /* i.e. single star on mobile; all stars on other viewports */
  variable,
}

interface IProps {
  variant?: RatingVariant;
  rating: number;
  numberOfReviews?: number;
  className?: string;
  hideLabel?: boolean;
  isLarge?: boolean;
  iconSize?: number;
  fullLabel?: boolean;
  labelClassname?: string;
  /* Overrides the value set by `isLarge` */
  textSize?: ITextProps['variant'];
  reviewPointColor?: string;
  reviewCountColor?: string;
}

function getIcon(starNumber: number, rating: number, iconSize: number) {
  if (rating < starNumber) {
    return rating < starNumber - 0.5 ? (
      <StarOutlineIcon height={iconSize} width={iconSize} />
    ) : (
      <StarHalfIcon height={iconSize} width={iconSize} />
    );
  }
  return <StarIcon height={iconSize} width={iconSize} />;
}

export const Rating = ({
  className = '',
  numberOfReviews,
  rating,
  variant = RatingVariant.variable,
  hideLabel = false,
  fullLabel = false,
  isLarge = false,
  iconSize = RATING_ICON_WIDTH,
  textSize,
  reviewPointColor = 'text-trench',
  reviewCountColor = 'text-abyss',
  labelClassname = '',
}: IProps) => {
  const clientHasRendered = useClientHasRendered();

  return (
    <div className={`${className} flex flex-row items-center`} data-testid="rating">
      {clientHasRendered ? (
        <div
          className={`flex flex-row items-center text-base text-clownfish ${
            isLarge ? '-translate-y-px' : ''
          }`}
        >
          <div className="flex">
            {variant === RatingVariant.singleStar ? (
              <StarIcon height={iconSize} width={iconSize} />
            ) : (
              getIcon(1, rating, iconSize)
            )}
          </div>

          <div
            className={`flex flex-row items-center ${
              variant === RatingVariant.allStars ? '' : 'hidden'
            } ${variant === RatingVariant.variable ? 'sm:flex' : ''}`}
          >
            {getIcon(2, rating, iconSize)}
            {getIcon(3, rating, iconSize)}
            {getIcon(4, rating, iconSize)}
            {getIcon(5, rating, iconSize)}
          </div>
        </div>
      ) : (
        <RatingLoader iconSize={iconSize} />
      )}

      {!hideLabel && (
        <div className={labelClassname}>
          {clientHasRendered ? (
            <>
              <Text
                variant={textSize || (isLarge ? 'lg' : 'sm')}
                as="span"
                className={`ml-1 ${reviewPointColor}`}
                data-testid="ratingNumber"
              >
                {rating.toFixed(1)}
              </Text>

              {numberOfReviews && (
                <Text
                  variant={textSize ?? (isLarge ? 'lg' : 'sm')}
                  as="span"
                  className={`ml-1 ${reviewCountColor}`}
                >
                  {fullLabel
                    ? `(${numberOfReviews} ${numberOfReviews > 1 ? 'reviews' : 'review'})`
                    : `(${numberOfReviews})`}
                </Text>
              )}
            </>
          ) : (
            <RatingLabelLoader isLarge={isLarge} numberOfReviews={numberOfReviews} />
          )}
        </div>
      )}
    </div>
  );
};
